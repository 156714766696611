import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// lib
import { metric } from 'lib/metrics'
import windowProxy from 'lib/window'

// redux action creators
import { setMessage } from 'actions'

// components
import DownloadButton from 'components/FileActionButtons/DownloadButton'

const withStudioDownloadButtonAction = (WrappedComponent) => {
  class StudioDownloadButtonAction extends React.Component {
    UNSAFE_componentWillMount () {
      windowProxy.setOnDownloadStart(this.handleDownloadStart)
      windowProxy.setOnDownloadComplete(this.handleDownloadComplete)
    }

    handleDownloadStart = (e) => {
      this.props.setMessage({
        body: 'You can continue using the app while you wait for the download to complete.',
        header: 'Download in progress.',
        type: 'SUCCESS'
      })
    }

    handleDownloadComplete = (e) => {
      if (e.success) {
        this.props.setMessage({
          body: 'You can now import your file into iMovie.',
          header: 'Download is complete!',
          type: 'SUCCESS'
        })
      } else {
        this.props.setMessage({
          body: 'We were unable to download your file.',
          header: `We're sorry.`,
          type: 'FAILURE'
        })
      }
    }

    handleClick = () => {
      const { file } = this.props
      windowProxy.postMessage(`downloadFile=${file.url}`)
      this.logDownload('native')
    }

    logDownload = () => {
      const context = 'native'
      const { file, isShowcase } = this.props
      const { fileId, url } = file
      metric('fileDownload', { fileId, url, isShowcase, context })
    }

    render () {
      const { activeAction } = this.props
      const active = activeAction === 'download'
      const disabled = !!(activeAction && !active)

      return (
        <WrappedComponent
          {...this.props}
          // label='Download'
          active={active}
          disabled={disabled}
          onClick={this.handleClick}
        />
      )
    }
  }

  return StudioDownloadButtonAction
}

const mapStateToProps = () => ({})

const withRedux = connect(mapStateToProps, { setMessage })

export default compose(
  withRedux,
  withStudioDownloadButtonAction
)(DownloadButton)
