// libs
import React from 'react'
import PropTypes from 'prop-types'
import { compose, useQuery } from 'react-apollo'

// containers
import withEffects from './withEffects'
import withQuery from './withQuery'
import withRouter from './withRouter'
import withRedux from './withRedux'
import withQueryResults from './withQueryResults'
import withMetric from './withMetric'
import withActiveFileAction from 'containers/FileActionButtons/withActiveFileAction'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'

// presentational components
import FileDetail from 'components/FileDetail'

export default compose(
  withEffects,
  withRouter,
  withRedux,
  withQuery,
  withApolloErrors,
  withQueryResults,
  withMetric,
  withActiveFileAction
)(FileDetail)
