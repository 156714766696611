import React from 'react'
import classes from 'components/FileMedia/classes.scss'

const FileMeta = ({children}) => {
  return (
    <div className={classes.mediaMetaWrapper}>
      <div className={classes.mediaMeta}> {/* FIXME: mediaMeta is not defined */}
        {children}
      </div>
    </div>
  )
}

export default FileMeta
