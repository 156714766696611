import { compose } from 'redux'

// composable higher-order components
import withApolloQuery from './withApolloQuery'
import withApolloData from './withApolloData'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withRedirectLogic from './withRedirectLogic'

// presentational component
import AccountUsers from 'components/AccountUsers'

// Fetches all users for an account and redirects to the first user.
// TODO: add a presentational component that links to each user on the account?
export default compose(
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedirectLogic
)(AccountUsers)
