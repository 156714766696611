// external deps
import React from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'

// presentational components
import UserHome from 'components/UserHome'
import { Loading } from 'components/shared'

// GraphQL query
import query from './query.graphql'

// actions
import { setQuery, setUser } from 'actions'

// containers
import withRefetchQuery from 'containers/withRefetchQuery'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'

// helpers
import withMetric from 'containers/withMetric'
import { parseQuery } from 'lib/location'
import { parseUserId } from 'lib/urls/helpers'

/**
HOC to bind a GraphQL query and handlers to a wrapped component. `options`
takes a function that converts the component's received props into variables for
the GraphQL query.
*/
const withApolloQuery = graphql(query, {
  options: (props) => {
    const { userId, camperId, studioIds, activityIds, orderBy, page } = props

    return {
      variables: { userId, camperId, studioIds, activityIds, orderBy, page }
    }
  }
})

/**
HOC to handle raw Apollo data and state props and pass them on to a
wrapped (presentational) component
*/
const withApolloData = (WrappedComponent) => {
  return (props) => {
    const { data, setUser } = props
    const { viewer } = data

    // TODO: better way to differentiate between initial load and subsequent
    // filter updates?
    if (!viewer) { return <Loading /> }
    const isAdmin = viewer.user_type === 'AdminUser'

    // save user in Redux
    setUser(viewer.user)

    return <WrappedComponent
      {...props}
      account={viewer}
      user={viewer.user || null}
      accountUsers={viewer.account_users || []}
      isAdmin={isAdmin}
    />
  }
}

/**
Helper to transform raw Redux state into props relevant to the component
*/
const mapStateToApolloProps = (state) => {
  // nested destructuring
  const {
    studios: studioIds,
    activities: activityIds,
    sort: orderBy,
    page
  } = state.query

  return { studioIds, activityIds, page, orderBy }
}

/**
HOC to pass relevant Redux state as props to a wrapped component
*/
const withReduxForApollo = connect(mapStateToApolloProps, { setUser })

const withReduxForRouter = connect(() => {}, { setQuery })

/**
HOC which parses raw router props and passes relevant params on to wrapped
component

Example URL:
http://web:3001/campers/1/files?page=3&sort[created_at]=DESC&studio[]=film&studio[]=music&activity[]=midi
*/
const withRouterData = (WrappedComponent) => {
  return ({match, location, setQuery}) => {
    // see also AccountUserFileDetailContainer
    const { userId, camperId } = parseUserId(match.params)

    // save navigation params from URL into redux store
    const query = parseQuery(location)
    setQuery(query)

    return <WrappedComponent
      userId={userId}
      camperId={camperId}
    />
  }
}

export default compose(
  withReduxForRouter,
  withRouterData,
  withReduxForApollo,
  withApolloQuery,
  withRefetchQuery,
  withApolloErrors,
  withApolloData,
  withMetric('accountHomeView')
)(UserHome)
