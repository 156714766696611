exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---mediaTags---1itvY {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  margin-bottom: 20px;\n  max-width: 520px; }\n  .classes---mediaTags---1itvY .classes---tag---2jHj8 {\n    display: inline-block;\n    padding-right: 4px;\n    color: #939598;\n    font-weight: 300; }\n", ""]);

// exports
exports.locals = {
	"mediaTags": "classes---mediaTags---1itvY",
	"tag": "classes---tag---2jHj8"
};