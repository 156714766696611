import { createStore, applyMiddleware } from 'redux'
import reducer from '../reducers'
import { createLogger } from 'redux-logger'
import Cookies from 'cookies-js'
import uuidv4 from 'uuid/v4'

// Uncomment when debugging in development environment
const COOKIE_OVERRIDES = {
  // appMode: 'kiosk', // preseason, kiosk, studio
  // campSessionType: 'summer', // preseason, summer, winter, etc
  // campStudioId: 'music', // music, fashion_preseason, etc
  // campLocationId: '1',
  // campWeekNumber: 9,
  // emailSubmitted: false,
  // UNO_NATIVE_ACCESS_TOKEN: 'uno-native-user-token'
}

const getCookie = (name) => (COOKIE_OVERRIDES[name] || Cookies.get(name))
const accessToken = getCookie('UNO_NATIVE_ACCESS_TOKEN')
const campStudioId = getCookie('campStudioId')
const campLocationId = getCookie('campLocationId')
const campSessionType = getCookie('campSessionType')
const campWeekNumber = getCookie('campWeekNumber')
const appMode = getCookie('appMode')
const emailSubmitted = getCookie('emailSubmitted')
const isUnoNative = !!(accessToken || getCookie('isUnoNative'))

// Unique identifier which is reset each time the app is restarted
const SESSION_ID = uuidv4()

// Unique identifier which persists across restarts
const CLIENT_ID = Cookies.get('clientId') || setClientId()

function setClientId () {
  const now = new Date()
  const expireDate = new Date(now.setFullYear(now.getFullYear() + 1))
  return Cookies.set('clientId', uuidv4(), { expires: expireDate }).get('clientId')
}

const DEFAULT_QUERY = {
  studios: null,
  activities: null,
  moderation: null,
  creators: null,
  page: 1,
  sort: { created_at: 'DESC' }
}

const defaultSession = (appMode) => {
  if (appMode === 'preseason') {
    return 'preseason'
  }
  return 'summer'
}

// Initialize redux store and logger for debugging
export const INITIAL_STATE = {
  query: DEFAULT_QUERY,
  appConfig: {
    sessionId: SESSION_ID,
    clientId: CLIENT_ID,
    accessToken: accessToken
  },
  campLocations: [],
  appSettings: {
    week: parseInt(campWeekNumber) || 1,
    sessionType: campSessionType || defaultSession(appMode),
    isUnoNative: isUnoNative,
    appMode: appMode,
    isPreseason: appMode === 'preseason',
    isKiosk: appMode === 'kiosk',
    campStudioId: campStudioId,
    campLocationId: campLocationId,
    adminCampLocation: {},
    showEmailPrompt: !emailSubmitted
  },
  fileFilters: {
    filtersVisible: false,
    selectedFilter: null,
    studios: {
      choices: [],
      selected: []
    },
    activities: {
      choices: [],
      selected: []
    },
    moderation: {
      choices: [],
      selected: []
    },
    creators: {
      choices: [],
      selected: []
    }
  },
  userData: {
    viewer: null,
    user: null,
    refetchQuery: () => {},
    userFile: null,
    lastStudioPageId: null
  }
}

let store
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger()
  store = createStore(reducer, INITIAL_STATE, applyMiddleware(logger))
} else {
  store = createStore(reducer, INITIAL_STATE)
}

export {
  DEFAULT_QUERY,
  store
}
