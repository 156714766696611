import React from 'react'
import classes from './classes.scss'
import { IconBrand } from 'components/shared/icons'

export default function Loading () {
  return (
    <section className={classes.loaderWrap}>
      <div className={classes.brand}><IconBrand /></div>
      <div className={classes.loader}>Loading...</div>
    </section>
  )
}
