import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

const StudioHeader = ({head, headSet}) => {
  const inset = headSet ? (<h5 className='StudioHeader__inset'>{headSet}</h5>) : null
  const headerClass = headSet ? 'StudioHeader StudioHeader--withInset' : 'StudioHeader'

  return (
    <div className={classes.headerWrapper}>
      <h2 className={headerClass}>{head}</h2>
      {inset}
    </div>
  )
}

StudioHeader.propTypes = {
  head: PropTypes.string.isRequired,
  headSet: PropTypes.string
}

export default StudioHeader
