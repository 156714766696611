import React from 'react'
import PropTypes from 'prop-types'

// components
import LegacyLayout from 'components/LegacyLayout'
import StudioMast from 'components/StudioMast'
import SkillMediaViewer from 'components/SkillMediaViewer'
import LinkToRoute from 'components/LinkToRoute'

const StudioTour = props => {
  const { studio } = props

  const skill = studio.studioTour[0]

  return (
    <LegacyLayout>
      <StudioMast
        head='Studio Tour'
        headSet={studio.title}
        studioId={studio.id}
        linkTo={LinkToRoute}
        route={`/studios/${studio.id}`}
      />
      <div className='SkillGroupDetail__videoWrapper'>
        <SkillMediaViewer skill={skill} autoplay />
      </div>
    </LegacyLayout>
  )
}

StudioTour.propTypes = {
  studio: PropTypes.object.isRequired
}

export default StudioTour
