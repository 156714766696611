import React from 'react'

// material ui
import Container from '@material-ui/core/Container'

// components
import KidThemeProvider from 'components/KidThemeProvider'
import StudiosList from 'components/StudiosList'

// local components
import StudioSetupHeader from './StudioSetupHeader'
import StudioSetupHelp from './StudioSetupHelp'
import StudioSetupButton from './StudioSetupButton'

const KioskStudioSetup = props => {
  const {
    // data
    studios,
    campLocationName,
    // state
    selectedStudioId,
    showHelp,
    // actions
    onStudioChange,
    onSubmit
  } = props

  return (
    <KidThemeProvider>
      <Container maxWidth='md'>
        <StudioSetupHeader
          header='Welcome'
          campLocationName={campLocationName}
          subheader='Please choose an activity.'
        />
        <StudiosList
          studios={studios}
          onChange={onStudioChange}
          selectedStudioId={selectedStudioId}
        />
        <StudioSetupHelp
          showHelp={showHelp}
          helpMessage='Choose a studio to get started!'
        />
        <StudioSetupButton onSubmit={onSubmit} />
      </Container>
    </KidThemeProvider>
  )
}

export default KioskStudioSetup
