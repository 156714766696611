import React from 'react'
import AdminModalConfirm from './AdminModalConfirm'

const AdminReportedSuccessConfirm = props => (
  <AdminModalConfirm
    {...props}
    headerText='Thank you. This file has been reported.'
    paragraphText='If you have not already, please contact your site director.'
    emphasisText='This file will no longer be viewable in the camper timeline.'
    buttonText='Reload Queue'
    afterwardText='If the file was incorrectly reported, your site director can notify HQ to correct the mistake.'
  />
)

export default AdminReportedSuccessConfirm
