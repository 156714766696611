import React from 'react'
import classNames from 'classnames'
import classes from './classes.scss'

const FileTitle = ({file, expanded}) => {
  const fileTitleClass = classNames(classes.fileTitle, {
    [`${classes.fileTitleExpanded}`]: expanded
  })

  return (
    <h2 className={fileTitleClass}>
      {file.title}
    </h2>
  )
}

export default FileTitle
