import PropTypes from 'prop-types'

// components
import AccountUserHeader from 'components/AccountUserHeader'

// containers
import withFileSubheader from 'containers/withFileSubheader'

/**
 * Displays a user profile header for a given file, in the context of a logged-in
 * account (parent or admin)
 */
const AccountUserFileHeader = withFileSubheader(AccountUserHeader)

AccountUserFileHeader.propTypes = {
  user: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired
}

export default AccountUserFileHeader
