import React from 'react'
import classNames from 'classnames'
import classes from './classes.scss'

import {
  MODERATION_STATUS_PENDING,
  MODERATION_STATUS_ESCALATED
} from 'lib/moderation/status'

/**
Displays the moderation status for a file on the detail page.
*/
const FileDetailModerationBadge = (props) => {
  const { text, status } = props
  const className = classNames(
    classes.status,
    {
      [`${classes.pending}`]: status === MODERATION_STATUS_PENDING,
      [`${classes.escalated}`]: status === MODERATION_STATUS_ESCALATED
    }
  )

  return (
    <div className={className}>
      {text}
    </div>
  )
}

export default FileDetailModerationBadge
