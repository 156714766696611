import React from 'react'
import PropTypes from 'prop-types'

// containers
import ModalContainer from 'containers/ModalContainer'
import OutreachEmailPromptContainer from 'containers/EmailPromptContainer/OutreachEmailPromptContainer'
import OutreachAddressPromptContainer from 'containers/OutreachAddressPromptContainer'

// components
import LegacyLayout from 'components/LegacyLayout'
import SkillPreviewList from 'components/SkillPreviewList'
import SkillMediaWrapper from 'components/SkillGroupDetail/SkillMediaWrapper'
import OutreachWrapper from 'components/OutreachWrapper'
import OutreachInfo from 'components/OutreachInfo'
import SkillGroupDetailMast from 'components/SkillGroupDetailMast'
import UserProfile from 'components/UserProfile'

// styles
import classNames from 'classnames'
import classes from './classes.scss'

const OutreachSkillGroupDetail = props => {
  const {
    mancalaUrl,
    account,
    showEmailPrompt,
    displayAddressModal,
    onRequestKit,
    onCloseRequestKit,
    skill,
    skillGroup,
    skills,
    changeSelectedSkill,
    setPreviewListNode,
    setSelectedSkillNode,
    kitId
  } = props

  // display email prompt if user is not logged in and hasn't already seen it
  // For test, do not show email prompt if we are collecting information for free kits
  const displayEmailModal = !account && showEmailPrompt && !onRequestKit
  const displayModal = displayEmailModal || displayAddressModal

  // don't autoplay if modal is hiding video
  const videoAutoplay = !displayModal

  const handleOutsideModalClick = displayAddressModal ? onCloseRequestKit : null

  return (
    <LegacyLayout>
      <OutreachWrapper mancalaUrl={mancalaUrl} account={account}>
        <section
          className='OutreachSkillGroupDetail'
          className={classes.OutreachSkillGroupDetail__section}
        >
          {displayModal && (
            <ModalContainer onClickOutside={handleOutsideModalClick}>
              {!!displayEmailModal && (
                <OutreachEmailPromptContainer
                  title={`${skillGroup.headSet} ${skillGroup.title}`}
                  kitId={kitId}
                />
              )}
              {!!displayAddressModal && (
                <OutreachAddressPromptContainer
                  onCloseRequestKit={onCloseRequestKit}
                  kitId={kitId}
                />
              )}
            </ModalContainer>
          )}
          <OutreachInfo kitId={kitId} />
          {!!onRequestKit && (
            <h3>
              {`All ${
                skillGroup.title
              } kits have been given out for ${new Date().getFullYear()}`}
            </h3>
          )}
          {account && (
            <UserProfile account={account} user={null} linkBackTo={null} />
          )}
          <SkillGroupDetailMast
            head={skillGroup.title}
            headSet={skillGroup.headSet}
          />
          <SkillMediaWrapper
            autoplay={videoAutoplay}
            skill={skill}
            changeSelectedSkill={changeSelectedSkill}
            skills={skills}
          />
          <SkillPreviewList
            selectedSkill={skill}
            changeSelectedSkill={changeSelectedSkill}
            setPreviewListNode={setPreviewListNode}
            setSelectedSkillNode={setSelectedSkillNode}
            skillGroup={skillGroup}
          />
        </section>
      </OutreachWrapper>
    </LegacyLayout>
  )
}

OutreachSkillGroupDetail.propTypes = {
  skill: PropTypes.object,
  account: PropTypes.object,
  mancalaUrl: PropTypes.string.isRequired,
  showEmailPrompt: PropTypes.bool.isRequired,
  skillGroup: PropTypes.object.isRequired,
  skills: PropTypes.array.isRequired,
  changeSelectedSkill: PropTypes.func.isRequired,
  kitId: PropTypes.string.isRequired
}

export default OutreachSkillGroupDetail
