import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { setMessage } from 'actions'

// presentational component
import Message from 'components/shared/Message'

const withLogic = (WrappedComponent) => {
  class WithMessageLogic extends React.Component {
    // clear the saved message on close
    handleClose = () => {
      this.props.setMessage(null)
    }

    // scroll to top when displaying a new message
    UNSAFE_componentDidUpdate (prevProps, _prevState) {
      if (this.props.type && !prevProps.type) {
        window.scrollTo(0, 0)
      }
    }

    render () {
      // if no message is currently set, don't render the component
      const { type } = this.props
      if (!type) { return null }

      return (
        <WrappedComponent
          {...this.props}
          onClose={this.handleClose}
        />
      )
    }
  }

  WithMessageLogic.propTypes = {
    setMessage: PropTypes.func.isRequired,
    type: PropTypes.string
  }

  return WithMessageLogic
}

const mapStateToProps = (state) => {
  const { header, body, type } = state.appSettings.message || {}
  return { header, body, type }
}

const withRedux = connect(mapStateToProps, { setMessage })

export default compose(
  withRedux,
  withLogic
)(Message)
