exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---collaborators---39NDy {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  padding-bottom: 10px;\n  display: inline-block;\n  font-size: 18px;\n  line-height: 1.5;\n  text-transform: uppercase;\n  font-weight: 500;\n  max-width: 520px; }\n  @media screen and (min-width: 768px) {\n    .classes---collaborators---39NDy {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1280px) {\n    .classes---collaborators---39NDy {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1600px) {\n    .classes---collaborators---39NDy {\n      font-size: 24px;\n      line-height: 1.5; } }\n  .classes---collaborators---39NDy .classes---collaborator---3-iub {\n    display: inline-block;\n    padding-right: 5px; }\n", ""]);

// exports
exports.locals = {
	"collaborators": "classes---collaborators---39NDy",
	"collaborator": "classes---collaborator---3-iub"
};