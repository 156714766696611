import { flatten } from 'lodash/array'

// lib
import { activitiesWithUploads } from 'lib/studios.js'
import { findActivity } from 'lib/studios'

const activitiesFilter = (selectedActivityIds, selectedStudios) => {
  const choices = flatten(selectedStudios.map(studio => activitiesWithUploads(studio)))
  const selected = selectedActivityIds.map(id => findActivity(id))

  return { choices, selected }
}

export default activitiesFilter
