import React from 'react'
import PropTypes from 'prop-types'

// material ui
import Box from '@material-ui/core/Box'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

const PreseasonToggle = props => {
  const { showVideo, onVideoEnd, onEmailClose } = props

  return (
    <Box align='center' my={[2, 4]}>
      <ButtonGroup variant='contained' color='primary'>
        <Button color='primary' onClick={onEmailClose}>
          See camp
        </Button>
        <Button color='secondary' onClick={onVideoEnd}>
          Keep me in the loop
        </Button>
      </ButtonGroup>
    </Box>
  )
}

PreseasonToggle.propTypes = {
  onVideoEnd: PropTypes.func.isRequired,
  onEmailClose: PropTypes.func.isRequired,
  showVideo: PropTypes.bool.isRequired
}

export default PreseasonToggle
