import React from 'react'
import PropTypes from 'prop-types'

// components
import AdminShowcaseDownloadButtonContainer from 'containers/FileActionButtons/AdminShowcaseDownloadButtonContainer'
import AccountFileDownloadButtonContainer from 'containers/FileActionButtons/AccountFileDownloadButtonContainer'

// moderation
import { isApproved } from 'lib/moderation/status'

const AdminDownloadButton = (props) => {
  const { file } = props

  if (file.submitted && !isApproved(file)) {
    return <AdminShowcaseDownloadButtonContainer {...props} />
  }

  return <AccountFileDownloadButtonContainer {...props} />
}

AdminDownloadButton.propTypes = {
  file: PropTypes.object.isRequired
}

export default AdminDownloadButton
