import React from 'react'
import AdminModalConfirm from './AdminModalConfirm'

const AdminDeletedSuccessConfirm = (props) => (
  <AdminModalConfirm
    {...props}
    headerText='This file has been deleted.'
    paragraphText='It is still available in the Pool for any other collaborators.'
    emphasisText='When you return to the camper timeline, this file will no longer be viewable.'
    buttonText='Reload timeline'
    afterwardText='If the file was incorrectly deleted, your site director can notify HQ to correct the mistake.'
  />
)

export default AdminDeletedSuccessConfirm
