import React from 'react'
import { compose } from 'redux'

// moderation
import {
  MODERATION_STATUS_APPROVED
} from 'lib/moderation/status'

// mutations
import withModerationStatusMutation from 'mutations/withModerationStatusMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import AdminApproveConfirm from 'components/FileActionConfirm/AdminApproveConfirm'

/**
NOTE: This HOC handles both approving and unapproving files because the logic is
so similar. Should it be split into two HOCs?
*/
const withAdminApproveAction = (WrappedComponent) => {
  const AdminApproveAction = (props) => {
    const {
      file,
      activeAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submitModerationStatus
    } = props

    const handleClickApprove = () => {
      const input = {
        moderation_status: MODERATION_STATUS_APPROVED
      }

      submitModerationStatus(file.id, input)
        .then(onSubmitSuccess)
        .catch(error => onSubmitFailure(error, 'Error updating moderation status'))
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'approve'}
        onClickApprove={handleClickApprove}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminApproveAction
}

export default compose(
  withModerationStatusMutation,
  withActionResult,
  withAdminApproveAction
)(AdminApproveConfirm)
