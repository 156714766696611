exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---OutreachSkillGroupDetail__submit---1bH2O {\n  text-transform: none;\n  font-style: italic; }\n\n.classes---OutreachSkillGroupDetail__submit---1bH2O {\n  width: 60%;\n  margin: 30px; }\n  @media (max-width: 568px) {\n    .classes---OutreachSkillGroupDetail__submit---1bH2O {\n      font-size: 11px; } }\n\n.classes---OutreachSkillGroupDetail__section---smQmA {\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"OutreachSkillGroupDetail__submit": "classes---OutreachSkillGroupDetail__submit---1bH2O",
	"OutreachSkillGroupDetail__section": "classes---OutreachSkillGroupDetail__section---smQmA"
};