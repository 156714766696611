import React from 'react'
import Cookies from 'cookies-js'
import { connect } from 'react-redux'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router'
import Honeybadger from 'honeybadger-js'

// presentational components
import { Loading, NotFound, ServerError, WrongAccountError } from 'components/shared'

// redux
import { setViewer } from 'actions'

// lib
import { parseQuery } from 'lib/location'
import windowProxy from 'lib/window'
import {
  detectError,
  USER_NOT_UNDER_ACCOUNT_ERROR,
  INAPPROPRIATE_CONTENT_ERROR,
  NOT_AUTHENTICATED_ERROR
} from 'lib/errors/codes'

/**
Redirects browser to Mancala to start OAuth flow:
* Sets the 'oauthReturnPath' cookie so our /authorize route can send an authenticated
user to the original path they requested.
* Sets the window location to the Mancala OAuth URL (either staff or non-staff)
*/
const redirectToOAuth = (redirectUrl) => {
  Cookies.set('oauthReturnPath', windowProxy.getLocation('pathname'))
  windowProxy.setLocation({href: redirectUrl})
}

/**
Returns a function that handles the authentication redirect logic for any component
that needs authentication.
*/
export const withErrorHandler = (WrappedComponent) => {
  let authenticating = false

  return (props) => {
    const {
      mancalaUrl, // from Redux via connect
      authorizationRedirectUrl, // from Redux via connect
      staffAuthorizationRedirectUrl, // from Redux via connect
      setViewer, // from redux actions
      location, // from react-router via withRouter
      match, // from react-router via withRouter
      data // from ApolloClient
    } = props

    const { loading, viewer, error } = data

    Honeybadger.setContext({
      routerProps: { location, match },
      apolloProps: { data: { variables: data.variables, viewer: data.viewer } }
    })

    if (loading && !viewer) { return <Loading /> }

    if (!error) {
      authenticating = false // we've received a successful Relay response

      // store the logged-in account in Redux state
      setViewer(viewer)

      // render the component
      return <WrappedComponent {...props} />
    } else {
      // we got an error from GraphQL.
      if (detectError(error, NOT_AUTHENTICATED_ERROR)) {
        // We got a "Not Authenticated" error from GraphQL
        if (match && match.params && match.params.kitId) {
          authenticating = false

          // remove any stored account from Redux state
          setViewer(null)

          // render the component
          return <WrappedComponent {...props} />
        } else if (authenticating) {
          // we are in the middle of OAuth-ing, but haven't returned yet. Keep waiting!
          // (thanks, RelayDefaultNetworkLayer retries!)
          return <Loading />
        } else {
          // we are not in the middle of OAuth-ing, so we should redirect to Mancala login
          authenticating = true
          const query = parseQuery(location)
          const redirectUrl = query.scope === 'staff' ? staffAuthorizationRedirectUrl : authorizationRedirectUrl
          redirectToOAuth(redirectUrl)
          return <Loading />
        }
      } else if (detectError(error, INAPPROPRIATE_CONTENT_ERROR)) {
        return <NotFound account={null} />
      } else if (detectError(error, USER_NOT_UNDER_ACCOUNT_ERROR)) {
        const metadata = detectError(error, USER_NOT_UNDER_ACCOUNT_ERROR)
        const { accountEmail, accountId, userId } = metadata

        return <WrongAccountError
          accountEmail={accountEmail}
          accountId={accountId}
          userId={userId}
          mancalaUrl={mancalaUrl}
        />
      } else {
        // all other errors
        return <ServerError error={error} />
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    mancalaUrl: state.appConfig.mancalaUrl,
    authorizationRedirectUrl: state.appConfig.authorizationRedirectUrl,
    staffAuthorizationRedirectUrl: state.appConfig.staffAuthorizationRedirectUrl
  }
}

const withRedux = connect(mapStateToProps, { setViewer })

export default compose(
  withRedux,
  withRouter,
  withErrorHandler
)
