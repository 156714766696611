import { connect } from 'react-redux'

// fetch any Redux state needed by Apollo query
const mapStateToApolloProps = ({ appSettings: { sessionType } }) => {
  return { sessionType }
}

const withReduxForApollo = connect(mapStateToApolloProps)

export default withReduxForApollo
