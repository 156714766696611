import React from 'react'
import PropTypes from 'prop-types'

// components
import { Confirmation, ConfirmationButton } from 'components/Confirmation'

import { TransitionGroup, MaxHeightTransition } from 'components/shared'

const AdminDeescalateConfirm = props => {
  const { visible, onClickDeescalate, onClickCancel } = props

  return (
    <TransitionGroup>
      {visible && (
        <MaxHeightTransition>
          <Confirmation
            visible
            header='Un-reporting content as "escalated" will change it back to "pending" review status'
            subheader={null}
          >
            <ConfirmationButton
              text='Un-report as escalated'
              action={onClickDeescalate}
            />
            <ConfirmationButton text='Cancel' action={onClickCancel} />
          </Confirmation>
        </MaxHeightTransition>
      )}
    </TransitionGroup>
  )
}

AdminDeescalateConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickDeescalate: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminDeescalateConfirm
