exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---studioList---2Jjeq {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  text-align: center;\n  margin: 0 auto 2rem;\n  border: 2px solid transparent; }\n  .classes---studioList---2Jjeq:after {\n    content: \"\";\n    display: table;\n    clear: both; }\n\n.classes---studioWrapper---2IBRa {\n  display: inline-block;\n  width: 10rem;\n  height: 10rem;\n  padding: 0rem; }\n\n.classes---studio---1sQBo {\n  width: 9rem;\n  height: 9rem;\n  padding: 0rem;\n  border-radius: 9rem;\n  box-sizing: initial;\n  border: 2px solid transparent; }\n  .classes---studio---1sQBo.classes---selected---r8Cqb {\n    border: 2px solid #000000; }\n", ""]);

// exports
exports.locals = {
	"studioList": "classes---studioList---2Jjeq",
	"studioWrapper": "classes---studioWrapper---2IBRa",
	"studio": "classes---studio---1sQBo",
	"selected": "classes---selected---r8Cqb"
};