exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---staticHeader---2MiCP {\n  padding: 0 10px 20px;\n  margin-top: 20px; }\n\n.classes---responsibilitiesIntro---XhRuR {\n  padding: 1rem;\n  text-align: center; }\n\n.classes---responsibilities---TB8ZM {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap; }\n  @media (min-width: 1024px) {\n    .classes---responsibilities---TB8ZM {\n      flex-wrap: nowrap; } }\n\n.classes---responsibility---hTdDI {\n  text-align: center;\n  border: 1px solid #e6e6e6;\n  padding: 1rem;\n  margin: 1rem;\n  width: 100%; }\n  @media (min-width: 1024px) {\n    .classes---responsibility---hTdDI {\n      width: auto;\n      min-height: 10rem; } }\n\n.classes---responsibilityDetail---44m0N {\n  color: #939598; }\n\n.classes---studioChick---qQxaH {\n  zoom: 60%;\n  position: relative;\n  float: right;\n  margin-top: -3rem; }\n", ""]);

// exports
exports.locals = {
	"staticHeader": "classes---staticHeader---2MiCP",
	"responsibilitiesIntro": "classes---responsibilitiesIntro---XhRuR",
	"responsibilities": "classes---responsibilities---TB8ZM",
	"responsibility": "classes---responsibility---hTdDI",
	"responsibilityDetail": "classes---responsibilityDetail---44m0N",
	"studioChick": "classes---studioChick---qQxaH"
};