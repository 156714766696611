exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---title---3j2DT {\n  position: absolute;\n  bottom: 0;\n  margin: 0;\n  padding: 6px;\n  text-align: center;\n  width: 100%;\n  background: rgba(0, 0, 0, 0.8);\n  color: #ffffff; }\n  .classes---title---3j2DT.classes---pending---Fs4Uw {\n    background: rgba(147, 149, 152, 0.9);\n    font-weight: 400; }\n  .classes---title---3j2DT.classes---escalated---hmNyP {\n    background: rgba(190, 30, 45, 0.9);\n    font-weight: 400; }\n", ""]);

// exports
exports.locals = {
	"title": "classes---title---3j2DT",
	"pending": "classes---pending---Fs4Uw",
	"escalated": "classes---escalated---hmNyP"
};