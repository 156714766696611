import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SkillGroupCategory from 'components/SkillGroupCategory'

const withSkillGroupCategoryLogic = (WrappedComponent) => {
  const WithSkillGroupCategoryLogic = (props) => {
    const {
      skillGroupCategory,
      studioId
    } = props

    const skillGroups = skillGroupCategory.skillGroups
    const skillGroupTitle = skillGroupCategory.title

    return (
      <WrappedComponent
        title={skillGroupTitle}
        skillGroups={skillGroups}
        studioId={studioId}
      />
    )
  }

  WithSkillGroupCategoryLogic.propTypes = {
    skillGroupCategory: PropTypes.object.isRequired,
    week: PropTypes.number.isRequired,
    studioId: PropTypes.string.isRequired
  }

  return WithSkillGroupCategoryLogic
}

const mapStateToProps = (state) => {
  return {
    week: state.appSettings.week
  }
}

const withReduxData = connect(mapStateToProps)

const SkillGroupCategoryContainer = compose(
  withReduxData,
  withSkillGroupCategoryLogic
)(SkillGroupCategory)

export default SkillGroupCategoryContainer
