import React from 'react'
import PropTypes from 'prop-types'

// styles
import classes from './classes.scss'
import classNames from 'classnames'

// containers
import FilePreviewModerationBadgeContainer from 'containers/FileModerationBadgeContainer/Preview'

// components
import AdminFileDetailLink from 'components/AdminFileDetailLink'
import FileShowcaseStatus from 'components/FileShowcaseStatus'
import FileTitle from 'components/FileTitle'
import FileTags from 'components/FileTags'
import FileCollaborators from 'components/FileCollaborators'

// media components
import FilePreviewWrapper from 'components/FilePreviewWrapper'
import FilePreviewContent from 'components/FilePreviewContent'

// action buttons
import FileActions from 'components/FileActions'
import AdminDequeueButtonContainer from 'containers/FileActionButtons/AdminDequeueButtonContainer'
import AdminDownloadButton from 'components/FileActionButtons/AdminDownloadButton'

// action confirm
import AdminDequeueConfirmContainer from 'containers/FileActionConfirm/AdminDequeueConfirmContainer'
import AdminShowcaseDownloadConfirmContainer from 'containers/FileActionConfirm/AdminShowcaseDownloadConfirmContainer'

// moderation
import {isUnreviewed} from 'lib/moderation/status'

/**
Preview for a media file being viewed by an admin account.
*/
const AdminFilePreview = (props) => {
  const {
    file,
    setMessage,
    isShowcase,
    activeAction,
    setActiveAction
  } = props

  const { submitted, enqueued, moderation_status: moderationStatus } = file
  const exapandedMediaWrapperClass = classNames(classes.expandedMediaMetaWrapper, 'expanded-media-meta-wrapper')

  // TODO: Remove this when we are able to fix mutation to indicate removal if dequeued
  if (isShowcase && enqueued === false) {
    return null
  }

  return (
    <div className={classes.expandedFilePreview}>
      <div className={classes.filePreviewWrapper}>
        <AdminFileDetailLink file={file} isShowcase={isShowcase}>
          <FilePreviewWrapper file={file} selected={false}>
            <FilePreviewContent file={file} />
            {!isUnreviewed(file) &&
              <FilePreviewModerationBadgeContainer status={moderationStatus} />
            }
          </FilePreviewWrapper>
        </AdminFileDetailLink>
      </div>
      <div className={exapandedMediaWrapperClass}>
        <div>
          <FileActions setMessage={setMessage} file={file}>
            <AdminDownloadButton
              file={file}
              isShowcase={submitted}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            >
              <AdminShowcaseDownloadConfirmContainer
                file={file}
                isDropList
                activeAction={activeAction}
                setActiveAction={setActiveAction}
              />
            </AdminDownloadButton>

            {isShowcase && (
              <AdminDequeueButtonContainer
                file={file}
                activeAction={activeAction}
                setActiveAction={setActiveAction}
              >
                <AdminDequeueConfirmContainer
                  file={file}
                  isDropList
                  activeAction={activeAction}
                  setActiveAction={setActiveAction}
                />
              </AdminDequeueButtonContainer>
            )}
          </FileActions>
          <FileTitle file={file} expanded />
          <FileCollaborators file={file} linkToDetail />
          <FileTags file={file} />
        </div>
        {submitted && <FileShowcaseStatus file={file} setMessage={setMessage} />}
      </div>
    </div>
  )
}

AdminFilePreview.propTypes = {
  file: PropTypes.object.isRequired, // from Apollo
  setMessage: PropTypes.func.isRequired, // from Redux actions
  isShowcase: PropTypes.bool.isRequired, // from Redux state
  activeAction: PropTypes.string,
  setActiveAction: PropTypes.func.isRequired
}

export default AdminFilePreview
