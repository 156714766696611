import withMetric from 'containers/withMetric'

const metricData = props => {
  const {
    account: {
      user: {
        user_file: {
          id: userFileId,
          file: { id: fileId }
        }
      }
    }
  } = props

  return {
    userFileId,
    fileId
  }
}

const withAccountFileViewMetric = withMetric('accountFileView', metricData)

export default withAccountFileViewMetric
