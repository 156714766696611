import React from 'react'
import classes from './classes.scss'
import classNames from 'classnames'

const Select = ({title, value, onChange, children}) => {
  const selectClass = classNames('mui-select', classes.select)

  return (
    <div className={selectClass}>
      <select
        onChange={onChange}
        value={value || ''}
      >
        <option />
        { children }
      </select>
    </div>
  )
}

export default Select
