import { connect } from 'react-redux'

const mapStateToProps = ({appSettings, appConfig, userData}) => {
  return {
    showEmailPrompt: appSettings.showEmailPrompt,
    mancalaUrl: appConfig.mancalaUrl,
    lastStudioPageId: userData.lastStudioPageId
  }
}

/**
HOC to pass relevant Redux state as props to a wrapped component
*/
const withRedux = connect(mapStateToProps)

export default withRedux
