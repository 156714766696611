import { withStyles } from '@material-ui/core/styles'
import StyledText from 'components/atoms/StyledText'

const styleFunction = theme => ({
  root: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'table',
    whiteSpace: 'nowrap',
    width: '90%',
    '&::before, &::after': {
      borderTop: '1px solid',
      content: "''",
      display: 'table-cell',
      position: 'relative',
      top: '0.5em',
      width: '45%'
    },
    '&::before': {
      right: '1.5%'
    },
    '&::after': {
      left: '1.5%'
    }
  }
})

export default withStyles(styleFunction)(StyledText)
