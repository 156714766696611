// files that either don't need moderation, or were created before automated moderation
const MODERATION_STATUS_UNREVIEWED = 'unreviewed'

// sequential steps in moderation pipeline
const MODERATION_STATUS_REQUIRED = 'required'
const MODERATION_STATUS_PENDING = 'pending'
const MODERATION_STATUS_ESCALATED = 'escalated'
const MODERATION_STATUS_APPROVED = 'approved'
const MODERATION_STATUS_FLAGGED = 'flagged'
const MODERATION_STATUS_ERROR = 'error'

const isUnreviewed = (file) => file.moderation_status === MODERATION_STATUS_UNREVIEWED
const isRequired = (file) => file.moderation_status === MODERATION_STATUS_REQUIRED
const isPending = (file) => file.moderation_status === MODERATION_STATUS_PENDING
const isEscalated = (file) => file.moderation_status === MODERATION_STATUS_ESCALATED
const isApproved = (file) => file.moderation_status === MODERATION_STATUS_APPROVED
const isError = (file) => file.moderation_status === MODERATION_STATUS_ERROR

/**
Returns true if the file is viewable by a non-admin user.
*/
const isViewable = (file) => isApproved(file) || isUnreviewed(file)

export {
  // moderation statuses
  MODERATION_STATUS_UNREVIEWED,
  MODERATION_STATUS_REQUIRED,
  MODERATION_STATUS_PENDING,
  MODERATION_STATUS_ESCALATED,
  MODERATION_STATUS_APPROVED,
  MODERATION_STATUS_FLAGGED,
  MODERATION_STATUS_ERROR,
  // logic
  isUnreviewed,
  isRequired,
  isPending,
  isEscalated,
  isApproved,
  isError,
  isViewable
}
