exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---fileTitle---1hLr2 {\n  clear: both;\n  max-width: 520px; }\n  @media (min-width: 1024px) {\n    .classes---fileTitle---1hLr2.classes---fileTitleExpanded---1IwHk {\n      clear: none; } }\n", ""]);

// exports
exports.locals = {
	"fileTitle": "classes---fileTitle---1hLr2",
	"fileTitleExpanded": "classes---fileTitleExpanded---1IwHk"
};