import React from 'react'

/*
Provides element hierarchy expected by legacy Kid World layout:
src/uno/themes/kid_world/layout_templates/template_single.scss
*/
const LegacyLayout = props => (
  <div className='template--single'>
    <div className='main'>{props.children}</div>
  </div>
)

export default LegacyLayout
