import React from 'react'

// material ui
import Container from '@material-ui/core/Container'

// components
import KidThemeProvider from 'components/KidThemeProvider'
import StudiosList from 'components/StudiosList'
import PreseasonVideo from 'components/PreseasonVideo'

// local components
import StudioSetupHeader from './StudioSetupHeader'
import StudioSetupHelp from './StudioSetupHelp'
import StudioSetupButton from './StudioSetupButton'
import PreseasonToggle from './PreseasonToggle'

// containers
import PreseasonEmailPromptContainer from 'containers/EmailPromptContainer/PreseasonEmailPromptContainer'

const PreseasonStudioSetup = props => {
  const {
    // data
    studios,
    campLocation,
    campLocationName,
    // state
    selectedStudioId,
    showHelp,
    showVideo,
    // actions
    onStudioChange,
    onSubmit,
    onVideoEnd,
    onEmailClose
  } = props

  return (
    <KidThemeProvider>
      <Container maxWidth='md'>
        <StudioSetupHeader
          header='Camp Out Early'
          campLocationName={campLocationName}
          subheader='Try an activity today.'
        />
        <StudiosList
          studios={studios}
          onChange={onStudioChange}
          selectedStudioId={selectedStudioId}
        />
        <StudioSetupHelp
          showHelp={showHelp}
          helpMessage='Choose a studio to get started!'
        />
        <StudioSetupButton onSubmit={onSubmit} />

        {showVideo ? (
          <PreseasonVideo campLocation={campLocation} onEnd={onVideoEnd} />
        ) : (
          <PreseasonEmailPromptContainer
            campLocation={campLocation}
            onSuccess={onEmailClose}
          />
        )}

        <PreseasonToggle
          showVideo={showVideo}
          onEmailClose={onEmailClose}
          onVideoEnd={onVideoEnd}
        />
      </Container>
    </KidThemeProvider>
  )
}

export default PreseasonStudioSetup
