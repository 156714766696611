import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const StudioShowcaseSubmitLengthConfirm = (props) => {
  const {
    visible,
    campStudioId,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            header='Your file is too long to submit to Showcase.'
            subheader='2 minutes and 30 seconds is the max!'
          >
            {campStudioId === 'film' &&
              <ConfirmationButton
                type='positive'
                href='/studios/film/skillgroups/film_showcase'
                text='Get Showcase Ready'
              />
            }
            <ConfirmationButton
              action={onClickCancel}
              text='Cancel'
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

StudioShowcaseSubmitLengthConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  campStudioId: PropTypes.string.isRequired, // from redux state
  onClickCancel: PropTypes.func.isRequired // from redux actions?
}

export default StudioShowcaseSubmitLengthConfirm
