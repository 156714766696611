import React from 'react'
import classes from './classes.scss'
import { IconLock } from 'components/shared/icons'

const Message = () => (
  <p>
    <span className={classes.key}><IconLock /></span>
    <em>Outside of camp, only Steve &amp; Kate&rsquo;s team and you can login to view your campers&rsquo; projects.</em>
    <br />
    <em>At camp, your camper may view and share projects with other campers.</em>
    <br />
    <em>Steve &amp; Kate&rsquo;s reviews projects.</em>
  </p>
)

const PreseasonMessage = () => (
  <p>
    <span className={classes.key}><IconLock /></span>
    <em>This project is only viewable from the link in your email. For your protection, this link will expire in 6 months.</em>
  </p>
)

const PrivacyPolicy = ({isPreseasonFile}) => {
  return (
    <section className={classes.privacyPolicy}>
      {isPreseasonFile ? (
        <PreseasonMessage />
      ) : (
        <Message />
      )}
      <p>Learn more about our <a href='/responsibilities'>posting guidelines</a>, <a href='http://steveandkatescamp.com/privacy-policy/'>privacy policy</a>, and <a href='http://steveandkatescamp.com/dmca-policy/'>sharing projects.</a></p>
    </section>
  )
}

export default PrivacyPolicy
