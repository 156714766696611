exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---image---3u-k2 {\n  width: 100%;\n  height: auto; }\n  .classes---image---3u-k2.classes---scratchImage---1eb3S {\n    border: 2px solid #e6e6e6; }\n", ""]);

// exports
exports.locals = {
	"image": "classes---image---3u-k2",
	"scratchImage": "classes---scratchImage---1eb3S"
};