import React from 'react'

// containers
import BackButtonContainer from 'containers/BackButtonContainer'
import AccountFileDetailUnderReviewContainer from 'containers/AccountFileUnderReviewContainer/Detail'
import FileDetailModerationBadgeContainer from 'containers/FileModerationBadgeContainer/Detail'

// components
import LegacyLayout from 'components/LegacyLayout'
import AccountFileDetailWrapper from 'components/AccountFileDetailWrapper'
import UserProfile from 'components/UserProfile'
import LinkToHistory from 'components/LinkToHistory'
import FileMeta from 'components/FileMeta'
import FileTitle from 'components/FileTitle'
import FileTags from 'components/FileTags'
import AccountUserFileHeader from 'components/AccountUserFileHeader'
import FileCollaborators from 'components/FileCollaborators'
import PrivacyPolicy from 'components/shared/PrivacyPolicy'

// media components
import FileMediaWrapper from 'components/FileMedia/FileMediaWrapper'
import FileViewerWrapper from 'components/FileMedia/FileViewerWrapper'
import FileViewer from 'components/FileViewer'

// action buttons
import FileActions from 'components/FileActions'
import AccountFileDownloadButtonContainer from 'containers/FileActionButtons/AccountFileDownloadButtonContainer'

// moderation
import { isViewable, MODERATION_STATUS_PENDING } from 'lib/moderation/status'

/**
Full-size detail view for a media file being viewed by a non-admin account.
*/
const AccountFileDetail = props => {
  const { account, user, file, isPreseasonFile } = props

  return (
    <LegacyLayout>
      <AccountFileDetailWrapper {...props}>
        <UserProfile account={account} user={user}>
          {!isPreseasonFile && (
            <BackButtonContainer
              linkTo={LinkToHistory}
              text='Back'
              displayMode='userProfile'
            />
          )}
        </UserProfile>
        <AccountUserFileHeader user={user} file={file} />
        <FileMediaWrapper>
          <FileViewerWrapper file={file}>
            {isViewable(file) ? (
              <FileViewer file={file} />
            ) : (
              <div>
                <AccountFileDetailUnderReviewContainer />
                <FileDetailModerationBadgeContainer
                  status={MODERATION_STATUS_PENDING}
                />
              </div>
            )}
          </FileViewerWrapper>
        </FileMediaWrapper>

        <FileMeta>
          <FileActions file={file}>
            {isViewable(file) && (
              <AccountFileDownloadButtonContainer
                isShowcase={file.submitted}
                file={file}
              />
            )}
          </FileActions>
          <FileTitle file={file} />
          <FileCollaborators file={file} />
          <FileTags file={file} />
        </FileMeta>
        <PrivacyPolicy isPreseasonFile={isPreseasonFile} />
      </AccountFileDetailWrapper>
    </LegacyLayout>
  )
}

export default AccountFileDetail
