import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// helpers
import { filesUrl } from 'lib/urls'

// presentational components
import AdminFileFilterSelect from 'components/AdminFileFilterSelect'

const withAdminFileFilterSelectAction = (WrappedComponent) => {
  const AdminFileFilterSelectAction = (props) => {
    const {
      location, // via withRouter
      history, // via withRouter
      query // via withRedux
    } = props

    const { showcase } = query
    const selectedValue = showcase ? 'showcase' : 'uploads'

    const options = [
      {
        value: 'uploads',
        name: 'Pool Uploads'
      },
      {
        value: 'showcase',
        name: 'Showcase Submissions'
      }
    ]

    const handleChange = (value) => {
      const urlOpts = {
        set: {
          attribute: 'showcase',
          value: value === 'showcase' ? 'true' : null
        }
      }
      const url = filesUrl(query, location, urlOpts)

      history.push(url)
    }

    return (
      <WrappedComponent
        options={options}
        onChange={handleChange}
        selectedValue={selectedValue}
      />
    )
  }

  AdminFileFilterSelectAction.propTypes = {
    location: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired
  }

  return AdminFileFilterSelectAction
}

const mapStateToProps = ({query}) => ({query})
const withRedux = connect(mapStateToProps)

export default compose(
  withRedux,
  withRouter,
  withAdminFileFilterSelectAction
)(AdminFileFilterSelect)
