import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

// https://material-ui.com/api/button/#css
const styleFunction = theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[0]
  },
  text: props => {
    if (props.color === 'error') {
      return {
        color: theme.palette.error.main
      }
    }
  },
  contained: props => {
    if (props.color === 'error') {
      return {
        color: theme.palette.error.contrastText,
        background: theme.palette.error.main,
        '&:hover': {
          background: theme.palette.error.dark
        }
      }
    }
  },
  outlined: props => {
    if (props.color === 'error') {
      return {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.light
      }
    }
  },
  label: {
    textTransform: 'none'
  }
})

export default withStyles(styleFunction)(Button)
