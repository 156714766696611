exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---studioPageButton---1N1pL {\n  padding-top: 0;\n  padding-bottom: 14px; }\n  .classes---studioPageButton---1N1pL svg {\n    height: 30px;\n    width: 30px;\n    position: relative;\n    top: 10px;\n    left: -10px;\n    fill: #000000; }\n\n.classes---studioPageButton---1N1pL {\n  color: #ffffff;\n  background: #000000; }\n  .classes---studioPageButton---1N1pL svg {\n    fill: #ffffff; }\n  .classes---studioPageButton---1N1pL:hover, .classes---studioPageButton---1N1pL:focus, .classes---studioPageButton---1N1pL:active {\n    background: #333333; }\n\n.classes---studioPageButton---1N1pL {\n  font-size: 18px;\n  line-height: 1.5;\n  width: 100%;\n  max-width: 768px;\n  display: block;\n  margin: 10px auto; }\n  @media screen and (min-width: 768px) {\n    .classes---studioPageButton---1N1pL {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1280px) {\n    .classes---studioPageButton---1N1pL {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1600px) {\n    .classes---studioPageButton---1N1pL {\n      font-size: 24px;\n      line-height: 1.5; } }\n  .classes---studioPageButton---1N1pL svg {\n    width: 40px;\n    height: 40px; }\n  .classes---studioPageButton---1N1pL:not(.classes---IconFlag---1Fpg6) {\n    padding: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center; }\n    .classes---studioPageButton---1N1pL:not(.classes---IconFlag---1Fpg6) svg {\n      width: 72px;\n      height: 72px;\n      top: 0; }\n", ""]);

// exports
exports.locals = {
	"studioPageButton": "classes---studioPageButton---1N1pL",
	"IconFlag": "classes---IconFlag---1Fpg6"
};