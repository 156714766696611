import React from 'react'
import PropTypes from 'prop-types'

// components
import FilesLinkContainer from 'containers/FilesLinkContainer'
import { IconX as IconRemove } from 'components/shared/icons'

const FilterCheckbox = (props) => {
  const { attribute, value, isSelected, hideCheckbox } = props
  const inputId = `${attribute}-${value.id}${hideCheckbox ? '' : '-selection'}`
  // const inputId = `${attribute}-${value.id}`
  const urlOpts = {
    toggle: {
      isSelected, // bool
      attribute, // 'studios' or 'activities'
      value: value.id // e.g. 'live_action'
    }
  }

  // FIXME: replace this temporary hack with CSS if we want to keep it
  const checkboxStyle = hideCheckbox ? {fill: 'transparent'} : null

  return (
    <li>
      <FilesLinkContainer opts={urlOpts}>
        <input
          id={inputId}
          type='checkbox'
          value={value}
          checked={isSelected}
          readOnly
        />
        <label htmlFor={inputId}>
          {value.name}
          <IconRemove style={checkboxStyle} />
        </label>
      </FilesLinkContainer>
    </li>
  )
}

FilterCheckbox.propTypes = {
  attribute: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default FilterCheckbox
