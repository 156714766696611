import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

// components
import BackButton from 'components/BackButton'

const mapStateToProps = ({appConfig: {mancalaUrl}}) => ({mancalaUrl})

const withRedux = connect(mapStateToProps)

export default compose(
  withRedux,
  withRouter
)(BackButton)
