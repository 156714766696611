import React from 'react'
import PropTypes from 'prop-types'

// components
import { Confirmation, ConfirmationButton } from 'components/Confirmation'

import { TransitionGroup, MaxHeightTransition } from 'components/shared'

const AdminEscalateConfirm = props => {
  const { visible, onClickEscalate, onClickCancel } = props

  return (
    <TransitionGroup>
      {visible && (
        <MaxHeightTransition>
          <Confirmation
            visible
            header='Files reported as "escalated" will be reported to your site director for further review.'
            subheader='After reporting, contact your site director for next steps.'
          >
            <ConfirmationButton
              type='warning'
              action={onClickEscalate}
              text='Report as Escalated'
            />
            <ConfirmationButton action={onClickCancel} text='Cancel' />
          </Confirmation>
        </MaxHeightTransition>
      )}
    </TransitionGroup>
  )
}

AdminEscalateConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickReport: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminEscalateConfirm
