import React from 'react'
import PropTypes from 'prop-types'
import 'styles/Media/media.scss'

const withVideoJs = (WrappedComponent) => {
  /**
  Wraps VideoJS and adds behavior shared by all of our video players
  (camper files, skills, preseason, etc.)
  */
  class VideoJsPlayer extends React.Component {
    // save a reference to the DOM node that will contain the VideoJS instance
    getVideoNode = (node) => {
      this.videoNode = node
    }

    /**
    On VideoJS error, try switching to the fallback (non-transcoded) video source
    */
    handleVideoError = (event, err) => {
      const { url, transcodedUrl } = this.props

      if (this.player.currentSrc() === transcodedUrl && url) {
        this.player.src({ src: url, type: 'video/mp4' })
      }
    }

    /**
    Returns an options object for initializing VideoJS
    */
    getVideoJsOptions () {
      const {
        // video source
        url,
        transcodedUrl,
        poster,
        // player options
        autoplay,
        loop
      } = this.props

      const sources = [
        { src: url, type: 'video/mp4' }
      ]

      if (transcodedUrl) {
        sources.unshift({ src: transcodedUrl, type: 'application/x-mpegURL' })
      }

      return {
        sources,
        poster,
        autoplay,
        loop,
        controls: true,
        fluid: true,
        nativeControlsForTouch: true
      }
    }

    componentDidMount () {
      const {
        onPlay,
        onPause,
        onEnd,
        onLoadedMetadata,
        poster
      } = this.props

      // initialize VideoJS
      this.player = window.videojs(this.videoNode, this.getVideoJsOptions())

      // iOS needs this for displaying poster image, even though it's already in videoJsOptions
      this.player.poster(poster)

      // set up video error handler
      this.player.on('error', this.handleVideoError)

      // set up optional event handlers
      if (onPlay) { this.player.on('play', onPlay) }
      if (onPause) { this.player.on('pause', onPause) }
      if (onEnd) { this.player.on('ended', onEnd) }
      if (onLoadedMetadata) {
        // passes the video player instance to the provided callback
        this.player.on('loadedmetadata', () => onLoadedMetadata(this.player))
      }
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if (!this.player) {
        return
      }

      const { url, transcodedUrl, poster } = this.props

      // reset video source if it changes
      if (nextProps.transcodedUrl !== transcodedUrl) {
        this.player.src({ src: nextProps.transcodedUrl, type: 'application/x-mpegURL' })
      } else if (nextProps.url !== url) {
        this.player.src({ src: nextProps.url, type: 'video/mp4' })
      }

      // reset poster if it changes
      if (nextProps.poster !== poster) {
        this.player.poster(nextProps.poster)
      }
    }

    // FIXME: this is recommended, but causes Uncaught TypeError: Cannot read property 'replaceChild' of null
    // https://docs.videojs.com/tutorial-react.html
    // componentWillUnmount () {
    //   if (this.player) { this.player.dispose() }
    // }

    render () {
      return (
        <WrappedComponent
          getVideoNode={this.getVideoNode}
        />
      )
    }
  }

  VideoJsPlayer.propTypes = {
    // video source
    url: PropTypes.string.isRequired,
    transcodedUrl: PropTypes.string,
    poster: PropTypes.string.isRequired,
    // player options
    autoplay: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
    // optional event handling
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnd: PropTypes.func,
    onLoadedMetadata: PropTypes.func
  }

  return VideoJsPlayer
}

export default withVideoJs
