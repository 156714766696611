import { compose } from 'redux'

// composable higher-order components
import withRouterData from './withRouterData'
import withApolloQuery from './withApolloQuery'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withApolloData from './withApolloData'
import withRedirect from './withRedirect'
import withRedux from './withRedux'
import withMetric from './withMetric'
import withResourceData from './withResourceData'
import withAddressModalLogic from './withAddressModalLogic'

// Presentational components
import SkillGroupDetail from 'components/SkillGroupDetail'
import OutreachSkillGroupDetail from 'components/OutreachSkillGroupDetail'

export default compose(
  withRouterData,
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedirect,
  withMetric,
  withRedux,
  withResourceData
)(SkillGroupDetail)

export const OutreachSkillGroupDetailContainer = compose(
  withRouterData,
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedirect,
  withMetric, // TODO: this should change to indicate atHomeView
  withRedux,
  withResourceData,
  withAddressModalLogic
)(OutreachSkillGroupDetail)
