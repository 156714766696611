import React from 'react'
import PropTypes from 'prop-types'

import Input from 'muicss/lib/react/input'

import classes from './classes.scss'
import classNames from 'classnames'

const AdminTranscodeResourceVideos = (props) => {
  const {
    // from Redux
    nodeEnv,
    // from form state
    sourcePrefix,
    // from GraphQL mutation
    result,
    error,
    // from container logic
    onChange,
    onSubmit
  } = props

  return (
    <div>
      <h5>Enter S3 Path Prefix</h5>
      <Input
        className={classes.input}
        placeholder='e.g. "fashion" or "fashion/projects/fancy_hat"'
        value={sourcePrefix || ''}
        onChange={onChange}
      />

      <a onClick={onSubmit} className='kid-btn kid-btn--solid'>
        Transcode to {nodeEnv}
      </a>

      {result && (
        <p>Job enqueued: {result.data.name}</p>
      )}

      {error && (
        <span>{error.message}</span>
      )}
    </div>
  )
}

AdminTranscodeResourceVideos.propTypes = {
  nodeEnv: PropTypes.string.isRequired,
  sourcePrefix: PropTypes.string,
  result: PropTypes.array.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default AdminTranscodeResourceVideos
