import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import SkillGroupTitle from 'components/SkillGroupTitle'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { getIcon } from 'components/shared/icons'

export default class SkillGroupPreview extends React.Component {
  static propTypes = {
    skillGroup: PropTypes.object.isRequired,
    studioId: PropTypes.string.isRequired
  }

  render () {
    const { studioId, skillGroup } = this.props
    const category = classNames('mui-btn', 'category--fab')
    const animatedItem = classNames(classes.item, classes.animated)
    const isAnimated = skillGroup.icon && skillGroup.image
    const skillClass = skillGroup.isAddOn ? classNames(classes.item, classes.addOn) : classNames(classes.item)

    if (isAnimated) {
      return (
        <li className={animatedItem}>
          <Link className={category} to={`/studios/${studioId}/skillgroups/${skillGroup.id}`}>
            {getIcon(skillGroup.icon)}
            <img src={skillGroup.image} width='120' height='120' />
            <SkillGroupTitle text={skillGroup.title} />
          </Link>
        </li>
      )
    }
    return (
      <li className={skillClass}>
        <Link className={category} to={`/studios/${studioId}/skillgroups/${skillGroup.id}`}>
          {getIcon(skillGroup.icon)}
          {skillGroup.image ? <img src={skillGroup.image} width='120' height='120' /> : null}
          <SkillGroupTitle text={skillGroup.title} />
        </Link>
      </li>
    )
  }
}
