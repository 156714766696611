import React from 'react'
import PropTypes from 'prop-types'
import StudioHeader from 'components/StudioHeader'
import classes from './classes.scss'
import classNames from 'classnames'
import BackButtonContainer from 'containers/BackButtonContainer'

const StudioMast = ({ head, headSet, studioId, linkTo, backText, route }) => {
  const backClass = classNames('kid-btn', classes.back)

  return (
    <header className={classes.mast}>
      <BackButtonContainer linkTo={linkTo} route={route} text={backText} className={backClass} />
      <StudioHeader head={head} headSet={headSet} />
    </header>
  )
}

StudioMast.propTypes = {
  head: PropTypes.string.isRequired,
  headSet: PropTypes.string,
  studioId: PropTypes.string.isRequired
}

export default StudioMast
