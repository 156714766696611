import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

// components
import { IconRightCarat, IconLeftCarat } from 'components/shared/icons'
import FilesLinkContainer from 'containers/FilesLinkContainer'

const FilePagination = (props) => {
  const { pageInfo: { hasNextPage, hasPreviousPage } } = props

  const previousPageOptions = {
    set: {
      attribute: 'page',
      modify: 'decrement'
    }
  }

  const nextPageOptions = {
    set: {
      attribute: 'page',
      modify: 'increment'
    }
  }

  const handleDisabledClick = e => e.preventDefault()

  return (
    <div className={classes.pagination}>
      {hasPreviousPage ? (
        <FilesLinkContainer className={classes.paginate} opts={previousPageOptions}>
          <IconLeftCarat />
        </FilesLinkContainer>
      ) : (
        <a className={classes.paginateDisabled} href='#' onClick={handleDisabledClick}>
          <IconLeftCarat />
        </a>
      )}

      {hasNextPage ? (
        <FilesLinkContainer className={classes.paginate} opts={nextPageOptions}>
          <IconRightCarat />
        </FilesLinkContainer>
      ) : (
        <a className={classes.paginateDisabled} href='#' onClick={handleDisabledClick}>
          <IconRightCarat />
        </a>
      )}
    </div>
  )
}

FilePagination.propTypes = {
  pageInfo: PropTypes.object.isRequired
}

export default FilePagination
