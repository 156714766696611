import React from 'react'
import { marketingSiteUrl } from 'lib/urls'
import classNames from 'classnames'
import classes from './classes.scss'

const OutreachFooter = ({ mancalaUrl }) => {
  const ctaClass = classNames('mui-btn', 'mui-btn--large', 'mui-btn--primary', classes.OutreachFooter__submit)

  return (
    <footer className={classes.OutreachFooter}>
      <h2 className={classes.OutreachFooter__saludo}>Ready for Summer?</h2>
      <a href={marketingSiteUrl()} className={ctaClass} >{'Tell me more about camp'} »</a>
    </footer>
  )
}

export default OutreachFooter
