import React from 'react'
import { mancalaAdminOpsUrl } from 'lib/urls'

const LinkToOps = ({ mancalaUrl, children }) => {
  return (
    <a href={mancalaAdminOpsUrl(mancalaUrl)} className='kid-btn'>
      {children}
    </a>
  )
}

export default LinkToOps
