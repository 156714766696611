import React from 'react'
import { Link } from 'react-router-dom'
import classes from './classes.scss'
import { userFilePath } from 'lib/urls'

export default function UserFileDetailLink ({ user, userFile, children }) {
  const path = userFilePath(user, userFile)

  return (
    <Link className={classes.detailLink} to={path}>
      {children}
    </Link>
  )
}
