import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const StudioLocationsList = ({locations, required, selectedLocation, onChange, label}) => {
  const selectWrapperClass = classNames('mui-select')
  const selectedLocationId = (selectedLocation && selectedLocation.id) || undefined

  return (
    <div className={selectWrapperClass}>
      {label && <label>{label}</label>}
      <select
        className='c-location-select__select'
        onChange={(e) => onChange(e.target.value)}
        value={selectedLocationId}
        required={required}
      >
        <option key='select' value=''>
          Select a Location
        </option>
        {locations.map(location => (
          <option key={location.id} value={location.id}>
            {location.name}
          </option>
        ))}
      </select>
    </div>
  )
}

StudioLocationsList.propTypes = {
  label: PropTypes.string,
  locations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object
}

export default StudioLocationsList
