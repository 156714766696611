import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

// components
import SubheaderFlourish from 'components/SubheaderFlourish'

/**
 * Displays a user profile header for a user. `children` is expected to include
 * the appropriate profile image for each context.
 */
const UserHeader = ({user, subheader, children}) => {
  return (
    <div className={classes.pageHeader}>
      <section>
        <h1>
          {children}
          {`${user.first_name}’s`}
        </h1>
      </section>
      <SubheaderFlourish subheader={subheader} />
    </div>
  )
}

UserHeader.propTypes = {
  user: PropTypes.object.isRequired,
  subheader: PropTypes.string.isRequired
}

export default UserHeader
