import React from 'react'

// presentational components
import AudioPlayer from 'components/AudioPlayer'

const withAudioPlayerLogic = (WrappedComponent) => {
  class AudioPlayerLogic extends React.Component {
    // save a reference to the DOM node that will contain the audio player
    getAudioNode = (node) => {
      this.audioNode = node
    }

    componentDidMount () {
      this.audioNode.setAttribute('controlsList', 'nodownload')
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          getAudioNode={this.getAudioNode}
        />
      )
    }
  }

  return AudioPlayerLogic
}

export default withAudioPlayerLogic(AudioPlayer)
