import React from 'react'
import PropTypes from 'prop-types'
import url from 'url'
import path from 'path'

// media types
import {
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_ADAPTIVE_VIDEO,
  MEDIA_TYPE_IMAGE
} from 'lib/media/types'

// players for different media types
import ImageViewer from 'components/ImageViewer'
import SkillVideoPlayerContainer from 'containers/VideoPlayerContainer/SkillVideoPlayerContainer'

/**
Returns a media type for a given URL, in order to choose the right component for display.
TODO: add mediaType to AirTable to avoid this lookup?
*/
const mediaTypeFromUrl = (urlString) => {
  let uri

  try {
    uri = url.parse(urlString)
  } catch (e) { return null }

  const fileExtension = path.extname(uri.pathname)

  switch (fileExtension) {
    case '.png':
    case '.jpg':
    case '.gif':
      return MEDIA_TYPE_IMAGE
    case '.m4v':
    case '.mp4':
    case '.mov':
      return MEDIA_TYPE_VIDEO
    case '.m3u8':
      return MEDIA_TYPE_ADAPTIVE_VIDEO
    default:
      return null
  }
}

/**
Displays fill-size `Skill` media of any supported type, e.g. images, videos.
Not for use with camper `File` media content (see FileViewer).
*/
const SkillMediaViewer = (props) => {
  const { skill, onPlay, autoplay } = props
  const mediaType = mediaTypeFromUrl(skill.url)
  const { thumbnailLarge } = skill

  switch (mediaType) {
    case MEDIA_TYPE_IMAGE:
      return (
        <ImageViewer
          source={thumbnailLarge}
        />
      )
    case MEDIA_TYPE_VIDEO:
    case MEDIA_TYPE_ADAPTIVE_VIDEO:
      return (
        <SkillVideoPlayerContainer
          skill={skill}
          onPlay={onPlay}
          autoplay={autoplay}
        />
      )
    default:
      return null
  }
}

SkillMediaViewer.propTypes = {
  skill: PropTypes.object.isRequired
}

export default SkillMediaViewer
