exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---OutreachEmail__auxLabel---2RMW9 {\n  font-weight: 500;\n  margin-bottom: 0;\n  font-size: 14px;\n  line-height: 1.25; }\n  @media screen and (min-width: 768px) {\n    .classes---OutreachEmail__auxLabel---2RMW9 {\n      font-size: 16px;\n      line-height: 1.125; } }\n  @media screen and (min-width: 1280px) {\n    .classes---OutreachEmail__auxLabel---2RMW9 {\n      font-size: 16px;\n      line-height: 1.125; } }\n  @media screen and (min-width: 1600px) {\n    .classes---OutreachEmail__auxLabel---2RMW9 {\n      font-size: 18px;\n      line-height: 1.125; } }\n", ""]);

// exports
exports.locals = {
	"OutreachEmail__auxLabel": "classes---OutreachEmail__auxLabel---2RMW9"
};