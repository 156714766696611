import React from 'react'
import PropTypes from 'prop-types'

// media types
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_STRATEGO_APP,
  MEDIA_TYPE_SCRATCHJR_APP
} from 'lib/media/types'

// players for different media types
import AudioPlayerContainer from 'containers/AudioPlayerContainer'
import ImageViewer from 'components/ImageViewer'
import StrategoAppViewer from 'components/StrategoAppViewer'
import FileVideoPlayerContainer from 'containers/VideoPlayerContainer/FileVideoPlayerContainer'

/**
For full-size display of any camper `File` media.
Not intended for use with `Skill` media content.
*/
const FileViewer = (props) => {
  const { file } = props
  const { url, thumbnails, media_type: mediaType } = file
  const { large: thumbnailLarge, small: thumbnailSmall } = thumbnails

  switch (mediaType) {
    case MEDIA_TYPE_AUDIO:
      return (
        <AudioPlayerContainer
          source={url}
          thumbnailLarge={thumbnailLarge}
        />
      )
    case MEDIA_TYPE_IMAGE:
    case MEDIA_TYPE_SCRATCHJR_APP:
      return (
        <ImageViewer
          source={thumbnailLarge}
          mediaType={mediaType}
        />
      )
    case MEDIA_TYPE_STRATEGO_APP:
      return (
        <StrategoAppViewer
          url={url}
          thumbnailSmall={thumbnailSmall}
          thumbnailLarge={thumbnailLarge}
        />
      )
    case MEDIA_TYPE_VIDEO:
      return (
        <FileVideoPlayerContainer
          file={file}
        />
      )
    default:
      // unknown file type, just display the thumbnail
      return (
        <ImageViewer
          source={thumbnailLarge}
        />
      )
  }
}

FileViewer.propTypes = {
  file: PropTypes.object.isRequired
}

export default FileViewer
