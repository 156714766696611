import React from 'react'
import PropTypes from 'prop-types'

// components
import AccountFileDetail from 'components/AccountFileDetail'
import AdminFileDetail from 'components/AdminFileDetail'
import { Loading } from 'components/shared'

/**
Renders the appropriate file detail component for an admin or regular account
*/
const FileDetail = props => {
  const { isAdmin, account } = props

  if (!account) {
    return <Loading />
  }

  if (isAdmin) {
    return <AdminFileDetail {...props} />
  } else {
    return <AccountFileDetail {...props} />
  }
}

FileDetail.propTypes = {
  isAdmin: PropTypes.bool.isRequired
}

export default FileDetail
