exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---OutreachFooter__submit---Wkc0b {\n  text-transform: none;\n  font-style: italic; }\n\n.classes---OutreachFooter---5f9S- {\n  margin-bottom: 1em;\n  margin: 20px auto 100px auto;\n  padding-top: 20px;\n  width: 100%;\n  text-align: center; }\n\n.classes---OutreachFooter__saludo---3mQgn {\n  font-weight: 900;\n  text-transform: uppercase; }\n\n.classes---OutreachFooter__submit---Wkc0b {\n  width: 60%; }\n  @media (max-width: 568px) {\n    .classes---OutreachFooter__submit---Wkc0b {\n      font-size: 11px; } }\n", ""]);

// exports
exports.locals = {
	"OutreachFooter__submit": "classes---OutreachFooter__submit---Wkc0b",
	"OutreachFooter": "classes---OutreachFooter---5f9S-",
	"OutreachFooter__saludo": "classes---OutreachFooter__saludo---3mQgn"
};