import {
  SET_QUERY,
  SELECT_FILTER
} from 'actions/actionTypes'

import studiosFilter from './studiosFilter'
import activitiesFilter from './activitiesFilter'
import moderationFilter from './moderationFilter'
import creatorsFilter from './creatorsFilter'

const setFiltersFromQuery = (state, action) => {
  const { query } = action

  const studios = studiosFilter(query.studios || [])
  const activities = activitiesFilter(query.activities || [], studios.selected)
  const moderation = moderationFilter(query.moderation || [])
  const creators = creatorsFilter(query.creators || [])

  return Object.assign({}, state, { studios, activities, moderation, creators })
}

const selectFilter = (state, action) => {
  const { attribute } = action

  // toggle visibility of all filters
  const filtersVisible = state.filtersVisible ? !(state.selectedFilter === attribute) : true

  // save the last selected filter
  const selectedFilter = attribute
  return Object.assign({}, state, { filtersVisible, selectedFilter })
}

const fileFilters = (state = {}, action) => {
  switch (action.type) {
    case SET_QUERY:
      return setFiltersFromQuery(state, action)
    case SELECT_FILTER:
      return selectFilter(state, action)
    default:
      return state
  }
}

export default fileFilters
