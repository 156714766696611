import React from 'react'
import PropTypes from 'prop-types'

// containers
import BackButtonContainer from 'containers/BackButtonContainer'

// components
import LegacyLayout from 'components/LegacyLayout'
import UserProfile from 'components/UserProfile'
import FilePagination from 'components/FilePagination'
import AccountUserHeader from 'components/AccountUserHeader'
import FileFilters from 'components/FileFilters'
import PrivacyPolicy from 'components/shared/PrivacyPolicy'
import FilesEmpty from 'components/FilesEmpty'
import LinkToAccount from 'components/LinkToAccount'
import LinkToRoute from 'components/LinkToRoute'
import LinkToOps from 'components/LinkToOps'
import AccountFilePreview from 'components/AccountFilePreview'

// styles
import classes from './classes.scss'

// Render the appropriate back button for this user
const BackButton = ({ isAdmin, account, accountUsers }) => {
  if (isAdmin) {
    return (
      <BackButtonContainer
        linkTo={LinkToOps}
        text='Ops'
        displayMode='userProfile'
      />
    )
  }

  if (accountUsers.edges.length > 1) {
    return (
      <BackButtonContainer
        linkTo={LinkToRoute}
        route='/campers'
        text='Campers'
        displayMode='userProfile'
      />
    )
  }

  return (
    <BackButtonContainer
      linkTo={LinkToAccount}
      account={account}
      text='Account'
      displayMode='userProfile'
    />
  )
}

const UserHome = ({ account, user, accountUsers, isAdmin }) => {
  if (!user) {
    return null
  }

  const { edges: userFiles, pageInfo } = user.user_files

  return (
    <LegacyLayout>
      <UserProfile account={account} user={user}>
        <BackButton
          isAdmin={isAdmin}
          account={account}
          accountUsers={accountUsers}
        />
      </UserProfile>
      <AccountUserHeader user={user} subheader='Projects' />
      <FileFilters />
      {userFiles.length === 0 ? (
        <FilesEmpty
          message={`${user.first_name} hasn’t added any projects yet.`}
        />
      ) : (
        <div className={classes.userFileList}>
          {userFiles.map(({ node: userFile }) => (
            <AccountFilePreview
              key={userFile.id}
              user={user}
              userFile={userFile}
              file={userFile.file}
            />
          ))}
          <FilePagination pageInfo={pageInfo} />
        </div>
      )}
      <PrivacyPolicy />
    </LegacyLayout>
  )
}

UserHome.propTypes = {
  account: PropTypes.object.isRequired, // from Apollo
  user: PropTypes.object, // from Apollo
  accountUsers: PropTypes.object, // from Apollo
  isAdmin: PropTypes.bool.isRequired
}

export default UserHome
