import React from 'react'
import { compose } from 'redux'
import { metric } from 'lib/metrics'

// moderation
import { MODERATION_STATUS_APPROVED } from 'lib/moderation/status'

// mutations
import withModerationStatusMutation from 'mutations/withModerationStatusMutation'
import withShowcaseFlagsMutation from 'mutations/withShowcaseFlagsMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import AdminShowcaseDownloadConfirm from 'components/FileActionConfirm/AdminShowcaseDownloadConfirm'

const withAdminShowcaseDownloadAction = (WrappedComponent) => {
  const AdminShowcaseDownloadAction = (props) => {
    const {
      file,
      isShowcase,
      onClickCancel, // from withActionResult
      onSubmitFailure, // from withActionResult
      submitModerationStatus
    } = props
    const { fileId, url } = file

    const handleClickApprove = (e) => {
      approveFile()
      logDownload()
    }

    const approveFile = () => {
      const input = {
        moderation_status: MODERATION_STATUS_APPROVED
      }

      submitModerationStatus(file.id, input)
        .catch(error => onSubmitFailure(error, 'Error approving file'))
    }

    const logDownload = () => {
      const context = 'showcase'
      metric('fileDownload', { fileId, url, isShowcase, context })
    }

    return (
      <WrappedComponent
        {...props}
        onClickApprove={handleClickApprove}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminShowcaseDownloadAction
}

export default compose(
  withModerationStatusMutation,
  withShowcaseFlagsMutation,
  withActionResult,
  withAdminShowcaseDownloadAction
)(AdminShowcaseDownloadConfirm)
