import React from 'react'
import { ServerError } from 'components/shared'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch (error, info) {
    this.setState({ error: error })
  }

  render () {
    if (this.state.error) {
      return <ServerError error={this.state.error} />
    }
    return this.props.children
  }
}
