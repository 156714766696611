import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'

// helpers
import { filesUrl } from 'lib/urls'

const mapStateToProps = (state) => {
  const { query } = state
  return { query }
}

const withRedux = connect(mapStateToProps)

const withUrl = (WrappedComponent) => {
  return (props) => {
    const { location, query, opts, children, className } = props
    const url = filesUrl(query, location, opts)

    return (
      <WrappedComponent className={className} to={url}>
        {children}
      </WrappedComponent>
    )
  }
}

export default compose(
  withRedux,
  withRouter,
  withUrl
)(Link)
