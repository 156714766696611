import React from 'react'
import classNames from 'classnames'
import classes from './classes.scss'

// components
import { IconUpPointer } from 'components/shared/icons'

/**
Displays a clickable toggle to show/hide a filter type
(e.g. studios or moderation)
*/
const FileFilterToggle = (props) => {
  const {
    isOpen,
    displayName,
    displayLabel,
    onClick
  } = props

  const filterStateClass = classNames(classes.filtersWrapper, {
    [`${classes.closed}`]: !isOpen
  })

  return (
    <li className={filterStateClass} onClick={onClick}>
      {displayLabel && (
        <span className={classes.filterLabel}>Filter by:</span>
      )}
      {displayName} <IconUpPointer />
    </li>
  )
}

export default FileFilterToggle
