import { compose } from 'redux'
import { connect } from 'react-redux'

// redux actions
import { setMessage } from 'actions'

// containers
import withActiveFileAction from 'containers/FileActionButtons/withActiveFileAction'

// components
import AdminFilePreview from 'components/AdminFilePreview'

const mapStateToProps = (state) => {
  return {
    isShowcase: !!state.query.showcase
  }
}

const withRedux = connect(mapStateToProps, { setMessage })

export default compose(
  withRedux,
  withActiveFileAction
)(AdminFilePreview)
