import { compose } from 'redux'

// must be first imported stylesheet
import 'components/App/App.scss'

// composable higher-order components
import withWebClientConfig from './withWebClientConfig'
import withReduxForApollo from './withReduxForApollo'
import withApolloQuery from './withApolloQuery'
import withApolloData from './withApolloData'
import withAppInitialization from './withAppInitialization'

// presentational component
import App from 'components/App'

export default compose(
  withWebClientConfig,
  withReduxForApollo,
  withApolloQuery,
  withApolloData,
  withAppInitialization
)(App)
