exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---audioWrapper---3ANvV {\n  background: #fafafa; }\n  .classes---audioWrapper---3ANvV audio {\n    width: 100%; }\n\n.classes---audioImage---1-uKr {\n  width: 100%;\n  height: auto; }\n", ""]);

// exports
exports.locals = {
	"audioWrapper": "classes---audioWrapper---3ANvV",
	"audioImage": "classes---audioImage---1-uKr"
};