import React from 'react'

// helpers
import { parseQuery } from 'lib/location'

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
const withRouterData = (WrappedComponent) => {
  return ({match, location, setQuery}) => {
    const { locationId } = match.params

    // save navigation params from URL into redux store
    const query = parseQuery(location)

    // TODO: move this logic into filesUrl?
    if (query.showcase) {
      query.sort = query.sort || { submitted_at: 'DESC' }
    }

    setQuery(query)

    return <WrappedComponent
      locationId={locationId}
    />
  }
}

export default withRouterData
