import React from 'react'
import { metric } from 'lib/metrics'
import getSkillRedirectPath from './getSkillRedirectPath'

// If there is no skillId in the route, redirect to the first skill
const withRedirect = (WrappedComponent) => {
  return (props) => {
    const { skillGroup, skillGroupId, kitId, studioId, skillId, history } = props

    // route changed but we haven't fetched the new group yet (e.g. related add-ons)
    if (skillGroup.id !== skillGroupId) {
      return null
    }

    if (!skillId) {
      const redirectPath = getSkillRedirectPath({kitId, studioId, skillGroup})
      metric('skillGroupView', { skillGroup: skillGroup.id })
      history.replace(redirectPath)
      return null
    }

    return <WrappedComponent {...props} />
  }
}

export default withRedirect
