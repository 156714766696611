import { connect } from 'react-redux'

// redux actions
import { setAdminCampLocation } from 'actions'

const mapStateToProps = (state) => {
  const { campLocations: locations, appSettings: { adminCampLocation } } = state
  return { locations, adminCampLocation }
}

const withRedux = connect(mapStateToProps, { setAdminCampLocation })

export default withRedux
