exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---userFileList---2ANpw {\n  text-align: center; }\n  @media (min-width: 632px) {\n    .classes---userFileList---2ANpw {\n      text-align: left;\n      margin: 0 auto;\n      max-width: 654px; } }\n  @media (min-width: 1024px) {\n    .classes---userFileList---2ANpw {\n      max-width: 1024px; } }\n  @media (min-width: 1920px) {\n    .classes---userFileList---2ANpw {\n      max-width: 1920px; } }\n", ""]);

// exports
exports.locals = {
	"userFileList": "classes---userFileList---2ANpw"
};