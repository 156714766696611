import React from 'react'
import classes from './classes.scss'

/**
Component which stores the selected UserFile into the Redux store.
*/
const StudioFileDetailLink = ({ userFile, children, selectUserFile }) => {
  const handleSelectUserFile = (e) => {
    e.preventDefault()
    selectUserFile(userFile)
  }

  return (
    <a href='#' onClick={handleSelectUserFile} className={classes.detailLink}>
      {children}
    </a>
  )
}

export default StudioFileDetailLink
