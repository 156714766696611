import React, { useEffect } from 'react'
import { metric } from 'lib/metrics'

/**
Higher-order component which logs a metric if the relevant data changes.
To use, insert into a `compose` chain in a container component.
@param {string} metricName - name of the metric to log, e.g. 'skillView'
@param {function} metricData - a function that transforms props into data that
should be logged. This will be run on props each time a change is received, and
data will be logged only if it has changed.
*/
const withMetric = (metricName, metricData = () => {}) => {
  return WrappedComponent => {
    return props => {
      // log metric only if metric data has changed
      useEffect(() => {
        async function logMetric() {
          await metric(metricName, metricData(props))
        }
        logMetric()
      }, [JSON.stringify(metricData(props))])

      return <WrappedComponent {...props} />
    }
  }
}

export default withMetric
