import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import StyledText from 'components/atoms/StyledText'
import FlourishHeader from 'components/atoms/FlourishHeader'

const StudioSetupHeader = props => {
  const { header, subheader, campLocationName, children } = props

  return (
    <Box my={[2, 4, 6]}>
      <StyledText variant='h1' align='center' fontWeight={900}>
        {header}
      </StyledText>
      <FlourishHeader
        variant='h2'
        textTransform='uppercase'
        fontWeight={700}
        align='center'
      >
        I’m at
      </FlourishHeader>
      {children || (
        <StyledText variant='h3' align='center'>
          {campLocationName}
        </StyledText>
      )}
      <StyledText mt={2} variant='h2' align='center' fontWeight={700}>
        {subheader}
      </StyledText>
    </Box>
  )
}

StudioSetupHeader.propTypes = {
  campLocationName: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired
}

export default StudioSetupHeader
