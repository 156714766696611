// libs
import React from 'react'

import { parseUserId, parseUserFileId } from 'lib/urls/helpers'
import { parseQuery } from 'lib/location'

const withRouter = WrappedComponent => {
  return props => {
    const { match, location } = props
    // see also UserHomeContainer
    const { userId, camperId } = parseUserId(match.params)
    const fileId = parseUserFileId(match.params)
    const query = parseQuery(location)
    const isPreseasonFile = !!query.preseason

    return (
      <WrappedComponent
        userId={userId}
        camperId={camperId}
        fileId={fileId}
        isPreseasonFile={isPreseasonFile}
        {...props}
      />
    )
  }
}

export default withRouter
