import React from 'react'

// presentational component
import { Loading } from 'components/shared'

/**
HOC to handle raw Apollo data and state props and pass them on to a
wrapped (presentational) component
*/
const withApolloData = (WrappedComponent) => {
  return ({locationId, data}) => {
    const { viewer } = data

    // TODO: better way to differentiate between initial load and subsequent updates?
    if (!viewer) { return <Loading /> }

    return <WrappedComponent
      locationId={locationId}
      account={viewer}
    />
  }
}

export default withApolloData
