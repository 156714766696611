import React from 'react'
import { mancalaAccountLoginUrl, marketingSiteUrl } from 'lib/urls'
import { Link } from 'react-router-dom'
import classes from './classes.scss'
import classNames from 'classnames'

// classes
const navClass = classNames('pure-drawer', classes.AccountHeader__nav)
const AccountHeader = ({ mancalaUrl, account }) => {
  const loginText = account && account.mancala_id ? 'account' : 'sign up/login'

  return (
    <div className='pure-container' data-effect='pure-effect-fade'>
      <input
        type='checkbox'
        id='pure-toggle-right'
        className='pure-toggle'
        data-toggle='right'
      />
      <label
        className='pure-toggle-label'
        htmlFor='pure-toggle-right'
        data-toggle-label='right'
      >
        <span className='pure-toggle-icon' />
      </label>
      <nav className={navClass} data-position='right'>
        <a href={marketingSiteUrl('philosophy')} className={classes.AccountHeader__navElement} >read our philosophy</a>
        <a href={marketingSiteUrl()} className={classes.AccountHeader__navElement} >learn more</a>
        <a href={mancalaAccountLoginUrl(mancalaUrl)} className={classes.AccountHeader__navElement} >{loginText}</a>
      </nav>
      <div className='pure-pusher-container'>
        <div className='pure-pusher'>
          <header className={classes.AccountHeader}>
            <Link to='' className={classes.AccountHeader__brandLink} >
              <img className={classes.AccountHeader__brand} src='/assets/uno/images/skc-logo.png' />
            </Link>
            <img className={classes.AccountHeader__poster} src='/assets/uno/images/pool-header-at-home.jpg' />
          </header>
        </div>
      </div>
      <label
        className='pure-overlay'
        htmlFor='pure-toggle-right'
        data-overlay='right'
      />
    </div>
  )
}
export default AccountHeader
