// external deps
import PropTypes from 'prop-types'
import React from 'react'
import Cookies from 'cookies-js'
import { compose } from 'react-apollo'
import { connect } from 'react-redux'

// logic
import { handleErrorResponse } from 'lib/xhr'
import { parseQuery } from 'lib/location'

// presentational components
import Authorize from 'components/Authorize'

const withAuthRequest = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      // from router:
      authCode: PropTypes.string.isRequired,
      history: PropTypes.object.isRequired
    }

    state = {
      error: null
    }

    _handleAuthResponse = (response) => {
      const { history } = this.props
      if (response.ok) {
        const oauthReturnPath = Cookies.get('oauthReturnPath')
        Cookies.expire('oauthReturnPath')
        history.replace(oauthReturnPath || '/')
      } else {
        handleErrorResponse.call(this, response)
      }
    }

    // Make XHR request to Uno to exchange the auth code for a token
    componentDidMount () {
      const { authCode } = this.props
      const url = `/api/authorize?code=${authCode}`

      fetch(url, { credentials: 'include', redirect: 'error' })
        .then(this._handleAuthResponse)
    }

    render () {
      const { mancalaUrl } = this.props
      const { error } = this.state

      return (
        <WrappedComponent
          error={error}
          mancalaUrl={mancalaUrl}
        />
      )
    }
  }
}

const mapStateToProps = ({appConfig: {mancalaUrl}}) => ({mancalaUrl})

const withReduxData = connect(mapStateToProps)

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
const withRouterData = (WrappedComponent) => {
  return ({location, history}) => {
    const query = parseQuery(location)
    const authCode = query.code

    return <WrappedComponent
      authCode={authCode}
      history={history}
    />
  }
}

export default compose(
  withRouterData,
  withReduxData,
  withAuthRequest
)(Authorize)
