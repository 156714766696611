import React from 'react'
import moment from 'moment'
import { formatSeconds } from 'lib/formatTime'

const FileShowcaseStatus = ({file, setMessage}) => {
  const runningTime = file.metadata ? formatSeconds(file.metadata.duration) : null
  const runningTimeText = runningTime ? `Running Time: ${runningTime}` : ''
  const submittedTime = moment(file.submitted_at).fromNow()

  return (
    <span className='showcase-file-status'>
      <span>{file.starred ? 'Recommended' : null}</span>
      <div>{runningTimeText}</div>
      <div className='showcase-file-status__time-submitted'>Submitted to Showcase {submittedTime}</div>
    </span>
  )
}

export default FileShowcaseStatus
