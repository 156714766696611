import React from 'react'

import {
  MEDIA_TYPE_STRATEGO_APP
} from 'lib/media/types'

// components
import ActionButton from 'components/FileActionButtons/ActionButton'
import {
  IconDownload,
  IconPlay
} from 'components/shared/icons'

const DownloadButton = (props) => {
  const { file } = props
  const { url, mediaType } = file

  // don't render download icon for wurrly or blank URLs
  if (!url) { return null }
  if (url && url.includes('wurrly')) { return null }

  const ActionIcon = mediaType === MEDIA_TYPE_STRATEGO_APP
    ? IconPlay
    : IconDownload

  return (
    <ActionButton
      {...props}
      target='_blank'
      href={url}
      icon={ActionIcon}
      label='Download'
    />
  )
}

export default DownloadButton
