import { UUID_REGEX } from 'lib/utils'

/**
Returns the user UUID or Mancala camper ID for the User specified in params.
@param {object} params - an object including a `camperId` property
@returns {object} includes mutually exclusive userId or camperId properties
*/
const parseUserId = (params) => {
  if (!params.camperId) {
    return { userId: null, camperId: null }
  }
  const isUUID = params.camperId.match(UUID_REGEX)
  const userId = isUUID ? params.camperId : null
  const camperId = isUUID ? null : parseInt(params.camperId)
  return { userId, camperId }
}

/**
Returns the UUID of the UserFile specified in params.

Also detects and parses UUIDs from legacy Relay file IDs, e.g.
  Relay ID: VXNlckZpbGU6ODI4NzNmMDItN2VjYS0xMWU3LWEzOTYtMTM0NGIxMWJlOTYy
  Decoded Relay ID: UserFile:82873f02-7eca-11e7-a396-1344b11be962
  Final UserFile UUID: 82873f02-7eca-11e7-a396-1344b11be962
@param {object} params - an object including a `fileId` property. Note that this
  is actually a UserFile ID!
@returns {string} the UUID of the UserFile.
*/
const parseUserFileId = (params) => {
  const { fileId: userFileId } = params
  const isUUID = userFileId.match(UUID_REGEX)

  if (isUUID) { return userFileId }

  try {
    const buffer = Buffer.from(userFileId, 'base64')
    const string = buffer.toString('ascii')
    const uuid = string.split(':')[1]

    if (uuid.match(UUID_REGEX)) {
      return uuid
    }
    throw new Error('File ID not valid')
  } catch (err) {
    throw new Error('File ID not valid')
  }
}

export {
  parseUserId,
  parseUserFileId
}
