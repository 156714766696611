import { createMuiTheme } from '@material-ui/core/styles'

// CHOICES:
const palette = {
  black: '#000',
  white: '#fff',
  popPurple: '#652d90',
  stopmeFuschia: '#be02c2',
  bandstandOrange: '#f15a24',
  orangeCrush: '#f7931e'
}

// https://github.com/steveandkate/mancala/blob/master/app/assets/stylesheets/uno/themes/kid_world/typography.scss
const kidTheme = theme =>
  createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      // color intentions:
      secondary: {
        main: palette.white,
        contrastText: palette.black
      }
    },
    typography: {
      ...theme.typography,
      h1: {
        ...theme.typography.h1,
        textTransform: 'uppercase',
        fontWeight: 500
      },
      h2: {
        ...theme.typography.h2,
        fontWeight: 500
      },
      h3: {
        ...theme.typography.h3,
        textTransform: 'uppercase',
        fontWeight: 700
      },
      h4: {
        ...theme.typography.h4,
        textTransform: 'uppercase',
        fontWeight: 500
      },
      h5: {
        ...theme.typography.h5,
        textTransform: 'uppercase',
        fontWeight: 300
      },
      h6: {
        ...theme.typography.h6,
        textTransform: 'uppercase',
        fontWeight: 300
      }
    },
    shape: {
      borderRadius: 6
    }
  })

export default kidTheme
