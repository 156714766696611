import React from 'react'
import { Link } from 'react-router-dom'
import classes from './classes.scss'
import { userFilePath } from 'lib/urls'

/**
Returns a link to the primary UserFile for the given File. If the UserFile's
primary User has a Mancala camper ID, that will be used in the path. Otherwise,
the user's UUID is used.
@param {object} file - A File object, including its user_files and their users.
@returns a URL to the given file in the pool.
*/
const buildLinkFromFile = (file, isShowcase) => {
  // find a UserFile, preferably the primary one
  const edges = file.user_files.edges
  const edge = edges.find(edge => edge.node.primary === true) || edges[0]
  if (edge && edge.node && edge.node.user) {
    const url = userFilePath(edge.node.user, edge.node)
    return isShowcase ? `${url}?showcase=true` : url
  } else {
    return '/notfound'
  }
}

export default function DetailLink ({ file, children, isShowcase }) {
  return (
    <Link className={classes.detailLink} to={buildLinkFromFile(file, isShowcase)}>
      {children}
    </Link>
  )
}
