import React from 'react'

/**
Higher-order component to allow differences in back button behavior for preseason
*/
const withBackLink = (WrappedComponent) => {
  return (props) => {
    const { isPreseason, studioId } = props
    const backLinkRoute = isPreseason ? '/studios/setup' : `/studios/${studioId}`
    const backLinkText = isPreseason ? 'Studios' : 'Back'

    return <WrappedComponent
      {...props}
      backLinkRoute={backLinkRoute}
      backLinkText={backLinkText}
    />
  }
}

export default withBackLink
