exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---appPreview---2xZY4 {\n  position: relative; }\n\n.classes---appIcon---3Db3_ {\n  max-width: 216px;\n  width: auto;\n  height: auto;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto; }\n\n.classes---appBackground---3R1IK {\n  width: 100%;\n  height: auto;\n  min-height: 280px;\n  min-width: 280px; }\n", ""]);

// exports
exports.locals = {
	"appPreview": "classes---appPreview---2xZY4",
	"appIcon": "classes---appIcon---3Db3_",
	"appBackground": "classes---appBackground---3R1IK"
};