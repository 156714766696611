import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import classNames from 'classnames'

// components
import MediaThumbnailTitle from 'components/MediaThumbnailTitle'

class SkillPreview extends React.Component {
  static propTypes = {
    selectedSkill: PropTypes.bool,
    skill: PropTypes.object.isRequired,
    skillNumber: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    setSelectedSkillNode: PropTypes.func.isRequired
  }

  UNSAFE_componentDidUpdate () {
    const {selectedSkill, setSelectedSkillNode} = this.props
    if (selectedSkill) {
      setSelectedSkillNode(this.skillNode)
    }
  }

  render () {
    const { skillNumber, selectedSkill, skill, onClick } = this.props
    let skillClass = classNames(classes.skill, {
      [`${classes.selected}`]: selectedSkill
    })

    return (
      <li ref={node => (this.skillNode = node)} className={skillClass} onClick={(e) => onClick(skill.id)}>
        <img src={skill.thumbnailSmall} />
        {skillNumber &&
          <h6 className={classes.skillCount}>{skillNumber}</h6>
        }
        {skill.title &&
          <MediaThumbnailTitle text={skill.title} />
        }
      </li>
    )
  }
}

export default SkillPreview
