import React from 'react'
import GoogleAnalytics from 'react-ga'

class Analytics extends React.Component {
  componentDidMount () {
    const page = this.props.location.pathname
    this.trackPage(page)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentPage = this.props.location.pathname
    const nextPage = nextProps.location.pathname

    if (currentPage !== nextPage) {
      this.trackPage(nextPage)
    }
  }

  trackPage (path) {
    GoogleAnalytics.set({ page: path })
    GoogleAnalytics.pageview(path)
  }

  render () {
    return null
  }
}

export default Analytics
