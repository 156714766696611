import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, MaxHeightTransition } from 'components/shared'

import { isApproved, isEscalated } from 'lib/moderation/status'

// TODO: move these shared styles?
import classes from 'components/Confirmation/classes.scss'

const AdminUnapproveButton = ({ onClick }) => (
  <li onClick={onClick} className={classes.dropOption}>
    Un-mark as approved
  </li>
)

const AdminApproveButton = ({ onClick }) => (
  <li onClick={onClick} className={classes.dropOption}>
    Approve as reviewed
  </li>
)

const AdminEscalateButton = ({ onClick }) => (
  <li onClick={onClick} className={classes.dropOption}>
    Report as escalated
  </li>
)

const AdminDeescalateButton = ({ onClick }) => (
  <li onClick={onClick} className={classes.dropOption}>
    Un-Report as escalated
  </li>
)

const AdminReportButton = ({ onClick }) => (
  <li onClick={onClick} className={classes.dropOption}>
    Report as inappropriate
  </li>
)

const AdminMoreActionsConfirm = props => {
  const {
    visible,
    file,
    onClickApprove,
    onClickUnapprove,
    onClickReport,
    onClickEscalate,
    onClickDeescalate
  } = props

  return (
    <TransitionGroup>
      {visible && (
        <MaxHeightTransition>
          <ul className={classes.dropList}>
            {isApproved(file) ? (
              <AdminUnapproveButton onClick={onClickUnapprove} />
            ) : (
              <AdminApproveButton onClick={onClickApprove} />
            )}
            {isEscalated(file) ? (
              <AdminDeescalateButton onClick={onClickDeescalate} />
            ) : (
              <AdminEscalateButton onClick={onClickEscalate} />
            )}
            <AdminReportButton onClick={onClickReport} />
          </ul>
        </MaxHeightTransition>
      )}
    </TransitionGroup>
  )
}

AdminMoreActionsConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickApprove: PropTypes.func.isRequired,
  onClickReport: PropTypes.func.isRequired
}

export default AdminMoreActionsConfirm
