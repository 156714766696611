import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

// containers
import { withRouter } from 'react-router-dom'

// presentational components
import AdminReportedSuccessConfirm from 'components/FileActionConfirm/AdminReportedSuccessConfirm'

/**
Displays a modal dialog after a file has been reported (reported as inappropriate).
The only available action is reloading the timeline.
*/
const withAdminReportedAction = WrappedComponent => {
  const AdminReportedAction = props => {
    const {
      history, // from withRouter
      activeAction, // from withActiveFileAction
      refetchQuery // from redux state
    } = props

    // go back and refresh page
    const handleClickContinue = () => {
      refetchQuery()
      history.goBack()
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'reported'}
        onClickContinue={handleClickContinue}
      />
    )
  }

  AdminReportedAction.propTypes = {
    user: PropTypes.object.isRequired
  }

  return AdminReportedAction
}

const mapStateToProps = ({ userData: { refetchQuery } }) => ({ refetchQuery })
const withRedux = connect(mapStateToProps, {})

export default compose(
  withRouter,
  withRedux,
  withAdminReportedAction
)(AdminReportedSuccessConfirm)
