import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

// composable higher-order components
import withApolloQuery from './withApolloQuery'
import withApolloData from './withApolloData'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withRedux from './withRedux'
import withRedirectLogic from './withRedirectLogic'

// presentational component
import Home from 'components/Home'

export default compose(
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedux,
  withRouter,
  withRedirectLogic
)(Home)
