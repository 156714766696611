import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import classNames from 'classnames'

const StrategoAppViewer = (props) => {
  const { url, thumbnailSmall: appIcon, thumbnailLarge: appBackground } = props
  const appPreviewClass = classNames('stratego-app', classes.appPreview)

  return (
    <a target='_blank' className={appPreviewClass} href={url}>
      <img className={classes.appBackground} src={appBackground} />
      <img className={classes.appIcon} src={appIcon} />
      <div className='bigPlayButton' />
    </a>
  )
}

StrategoAppViewer.propTypes = {
  url: PropTypes.string.isRequired,
  thumbnailSmall: PropTypes.string,
  thumbnailLarge: PropTypes.string
}

export default StrategoAppViewer
