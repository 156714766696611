import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classes from './classes.scss'
import kahali from './img/studioCroc@2x.png'
import classNames from 'classnames'
import StudioHeader from 'components/StudioHeader'
import 'styles/Media/media.scss'
import { IconRightArrow as IconNext } from 'components/shared/icons'
import BackButtonContainer from 'containers/BackButtonContainer'
import LinkToRoute from 'components/LinkToRoute'

const StudioWeeklyFeature = (props) => {
  const {
    weeklyFeature,
    onWeeklyFeatureClick,
    studio,
    studioTourImage,
    leadText,
    showBackToStudio
  } = props

  const sectionClass = classNames('StudioFeatureMast', classes.studioFeature)
  const viewClass = classNames('kid-btn', classes.viewFeature)

  return (
    <section className={sectionClass}>
      <header className={classes.featureMast} >
        {showBackToStudio && (
          <BackButtonContainer linkTo={LinkToRoute} route='/studios/setup' text='Studios' displayMode='featureMast' />
        )}
        <h4 className={classes.lead}>{leadText}</h4>
        <StudioHeader head={weeklyFeature.title} />
        <Link
          to={`/studios/${studio.id}/skillgroups/${weeklyFeature.id}`}
          onClick={() => onWeeklyFeatureClick(weeklyFeature.id)}
          className={viewClass}
        >
          <IconNext />
          {"See this week's project"}
        </Link>
      </header>
      <Link to={`/studios/${studio.id}/tour`} className={classes.featureTourPoster} >
        <img src={studioTourImage} />
        <div className={classes.studioCrocWrapper}>
          <img src={kahali} className={classes.studioCroc} />
          <p className={classes.studioCrocMessage}>
            {"Welcome. Let's tour"}
            <b className={classes.studioCrocTitle}>{studio.title}</b>
            {'mate.'}
          </p>
        </div>
        <div className='bigPlayButton' />
      </Link>
    </section>
  )
}

StudioWeeklyFeature.propTypes = {
  weeklyFeature: PropTypes.object.isRequired,
  onWeeklyFeatureClick: PropTypes.func.isRequired,
  studio: PropTypes.object.isRequired,
  studioTourImage: PropTypes.string.isRequired,
  leadText: PropTypes.string.isRequired,
  showBackToStudio: PropTypes.bool.isRequired
}

export default StudioWeeklyFeature
