import query from './query.graphql'
import { graphql } from 'react-apollo'

const withQuery = graphql(query, {
  options: props => {
    const { userId, camperId, fileId } = props
    return { variables: { userId, camperId, fileId } }
  }
})

export default withQuery
