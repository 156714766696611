exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---skill---2Q2bz {\n  padding: 0 20px 16px;\n  margin: 0 26px;\n  display: inline-block;\n  overflow: hidden;\n  position: relative;\n  padding: 0;\n  width: 120px;\n  cursor: pointer;\n  margin: 0 10px;\n  border: 2px solid transparent;\n  overflow: visible; }\n  @media (min-width: 632px) {\n    .classes---skill---2Q2bz {\n      width: 240px;\n      height: 138px;\n      margin: 0 20px; } }\n  .classes---skill---2Q2bz img {\n    width: 100%;\n    vertical-align: top; }\n  @media (min-width: 632px) {\n    .classes---skill---2Q2bz:first-child {\n      margin-right: 30px; } }\n  .classes---skill---2Q2bz:first-child + li {\n    box-sizing: content-box; }\n    .classes---skill---2Q2bz:first-child + li:before {\n      content: \" \";\n      border-right: 2px solid #e6e6e6;\n      position: absolute;\n      height: 100%;\n      left: -10px; }\n      @media (min-width: 632px) {\n        .classes---skill---2Q2bz:first-child + li:before {\n          left: -26px; } }\n  .classes---skill---2Q2bz.classes---selected---30gSn {\n    border: 2px solid #000000; }\n\n.classes---skillCount---36FaW {\n  max-height: 100%;\n  max-width: 100%;\n  position: absolute;\n  font-size: 24px;\n  font-weight: 900;\n  text-align: center;\n  z-index: 1;\n  top: 2px;\n  bottom: auto;\n  left: 0;\n  right: 0;\n  margin: auto;\n  text-shadow: 0px 1px #e6e6e6;\n  width: 35px;\n  height: 35px;\n  border-radius: 17.5px;\n  line-height: 35px;\n  background: rgba(255, 255, 255, 0.6); }\n  @media (min-width: 632px) {\n    .classes---skillCount---36FaW {\n      top: 12px;\n      font-size: 64px;\n      width: 80px;\n      height: 80px;\n      border-radius: 40px;\n      line-height: 80px; } }\n", ""]);

// exports
exports.locals = {
	"skill": "classes---skill---2Q2bz",
	"selected": "classes---selected---30gSn",
	"skillCount": "classes---skillCount---36FaW"
};