exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---pagination---3pnwI {\n  text-align: center; }\n  .classes---pagination---3pnwI .classes---paginateDisabled---1s7LM,\n  .classes---pagination---3pnwI .classes---paginate---Njn4Q {\n    display: inline-block;\n    padding: 20px 48px; }\n  .classes---pagination---3pnwI .classes---paginate---Njn4Q:focus,\n  .classes---pagination---3pnwI .classes---paginate---Njn4Q:hover {\n    fill: #4d4d4d; }\n  .classes---pagination---3pnwI .classes---paginateDisabled---1s7LM {\n    fill: #cccccc; }\n", ""]);

// exports
exports.locals = {
	"pagination": "classes---pagination---3pnwI",
	"paginateDisabled": "classes---paginateDisabled---1s7LM",
	"paginate": "classes---paginate---Njn4Q"
};