import React from 'react'
import PropTypes from 'prop-types'
import { findStudio } from 'lib/studios'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

// presentational components
import StudioUserHeader from 'components/StudioUserHeader'

/**
If a file has been selected, the header shouldn't include the user name
(it's already at the top of the page).
*/
const withUserProjectsSubheader = (WrappedComponent) => {
  const UserProjectsSubheader = (props) => {
    const { user, studioData, isKiosk } = props
    const studio = findStudio(studioData.id)
    const subheader = isKiosk ? 'All Projects' : `${studio.name} Projects`

    return <WrappedComponent
      {...props}
      user={user}
      subheader={subheader}
    />
  }

  UserProjectsSubheader.propTypes = {
    user: PropTypes.object.isRequired,
    studioData: PropTypes.object.isRequired,
    isKiosk: PropTypes.bool
  }

  return UserProjectsSubheader
}

const mapStateToProps = (state) => {
  const { userFile } = state.userData
  const { isKiosk } = state.appSettings
  return { userFile, isKiosk }
}

const withRedux = connect(mapStateToProps)

export default compose(
  withRouter,
  withRedux,
  withUserProjectsSubheader
)(StudioUserHeader)
