import React from 'react'

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
const withRouterData = (WrappedComponent) => {
  return ({match, location}) => {
    const { studioId, pageId } = match.params

    return <WrappedComponent
      studioId={studioId}
      pageId={pageId}
    />
  }
}

export default withRouterData
