import moment from 'moment'

const pad = (num) => {
  return ('0' + num).slice(-2)
}

export const formatSeconds = (seconds) => {
  if (!seconds || isNaN(seconds)) {
    return '0:00'
  }
  const duration = moment.duration(seconds, 'seconds')
  const min = duration.minutes()
  const sec = duration.seconds()

  return min + ':' + pad(sec)
}
