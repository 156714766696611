import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { flatten } from 'lodash/array'

// presentational components
import FileFilterSelection from 'components/FileFilters/FileFilterSelection'

const withSelectedValues = (WrappedComponent) => {
  const SelectedValues = (props) => {
    const { studios, activities, moderation, creators } = props
    const selected = flatten([
      studios.selected.map(studio => ({ value: studio, attribute: 'studios' })),
      activities.selected.map(activity => ({ value: activity, attribute: 'activities' })),
      moderation.selected.map(moderation => ({ value: moderation, attribute: 'moderation' })),
      creators.selected.map(creator => ({ value: creator, attribute: 'creators' }))
    ])

    return (
      <WrappedComponent
        selected={selected}
      />
    )
  }

  SelectedValues.propTypes = {
    studios: PropTypes.object.isRequired,
    activities: PropTypes.object.isRequired,
    moderation: PropTypes.object.isRequired,
    creators: PropTypes.object.isRequired
  }

  return SelectedValues
}

const mapStateToProps = (state) => (state.fileFilters)
const withRedux = connect(mapStateToProps)

export default compose(
  withRedux,
  withSelectedValues
)(FileFilterSelection)
