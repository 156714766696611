/**
Find a location by ID
@param {String} locations - the list of locations (from redux after api call to locations)
@param {String} id - the ID of the location
@return {object} The location matching the given ID
*/
function findLocation (locations, id) {
  if (!locations || !id) {
    return null
  }
  return locations.find(location => location.id === id)
}

/**
Find a location name by ID
@param {String} locations - the list of locations (from redux after api call to locations)
@param {String} id - the ID of the location
@return {String} The name matching the location with the given ID
*/

function findLocationName (locations, id) {
  const location = findLocation(locations, id)
  return location ? location.name : null
}

export {
  findLocation,
  findLocationName
}
