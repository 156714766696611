// lib
import {
  MODERATION_STATUS_UNREVIEWED,
  MODERATION_STATUS_PENDING,
  MODERATION_STATUS_ESCALATED,
  MODERATION_STATUS_APPROVED
} from 'lib/moderation/status'

const choices = [
  {id: MODERATION_STATUS_PENDING, name: 'Pending'},
  {id: MODERATION_STATUS_ESCALATED, name: 'Escalated'},
  {id: MODERATION_STATUS_APPROVED, name: 'Approved'},
  {id: MODERATION_STATUS_UNREVIEWED, name: 'Not Reviewed'}
]

const moderationFilter = (selectedModerationStatuses) => {
  const selected = selectedModerationStatuses.map(id => {
    return choices.find((status) => id === status.id)
  })

  return { choices, selected }
}

export default moderationFilter
