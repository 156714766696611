import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { handleErrorResponse } from 'lib/xhr'
import windowProxy from 'lib/window'
import { parseQuery } from 'lib/location'

// presentational component
import Logout from 'components/Logout'

/**
HOC to add handling of the api server logout XHR request
*/
const withLogoutXhrRequest = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      redirect: PropTypes.string
    }

    state = {
      error: null,
      success: false
    }

    _handleLogoutResponse = (response) => {
      if (response.ok) {
        const { redirect } = this.props

        if (redirect) {
          windowProxy.setLocation({href: redirect})
        } else {
          this.setState({success: true})
        }
      } else {
        handleErrorResponse.call(this, response)
      }
    }

    componentDidMount () {
      // Make XHR request to Uno to delete the session
      fetch('/api/logout', { credentials: 'include', redirect: 'error' })
        .then(this._handleLogoutResponse)
    }

    render () {
      const { error, success } = this.state
      return (
        <WrappedComponent
          error={error}
          success={success}
        />
      )
    }
  }
}

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
const withRouterData = (WrappedComponent) => {
  return ({location}) => {
    const query = parseQuery(location)
    const { redirect } = query

    return <WrappedComponent
      redirect={redirect}
    />
  }
}

export default compose(
  withRouterData,
  withLogoutXhrRequest
)(Logout)
