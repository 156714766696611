import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'cookies-js'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { setShowEmailPrompt } from 'actions'
import windowProxy from 'lib/window'

// shared email form logic
import withEmailFormLogic from './withEmailFormLogic'

// presentational component
import OutreachEmailPrompt from 'components/OutreachEmailPrompt'

const COOKIES_EXPIRE = 60 * 60 * 24 * 365 // set expiration time to 1 year

const withOutreachEmailFormLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      title: PropTypes.string.isRequired,
      kitId: PropTypes.string.isRequired,
      authorizationRedirectUrl: PropTypes.string.isRequired,
      // redux actions
      setShowEmailPrompt: PropTypes.func.isRequired
    }

    state = { updates: true }

    /**
    @private
    Set a cookie so the parent won't be prompted again
    */
    handleSuccess = () => {
      Cookies.set('emailSubmitted', true, { expires: COOKIES_EXPIRE })
    }

    /**
    Outreach-specific email form submission handling:
    * Opt in/out based on checkbox state
    * On successful response, set a cookie so the parent won't be prompted again
    * Close the modal dialog
    */
    handleSubmit = (e) => {
      e.preventDefault()

      const {
        kitId, // from provided props
        email, // from withEmailFormLogic state
        postEmail, // from withEmailFormLogic actions
        setShowEmailPrompt // from withEmailFormLogic redux actions
      } = this.props

      const { updates } = this.state

      if (email) {
        // Send email and updates to mailchimp
        const pool = `preseason kit: ${kitId}`
        postEmail({pool, updates, onSuccess: this.handleSuccess})
      }

      setShowEmailPrompt(false)
    }

    handleRedirect = () => {
      const { authorizationRedirectUrl } = this.props
      Cookies.set('oauthReturnPath', windowProxy.getLocation('pathname'))
      windowProxy.setLocation({href: authorizationRedirectUrl})
    }

    handleChangeUpdates = (e) => {
      this.setState({
        updates: e.target.checked
      })
    }

    render () {
      const { title } = this.props
      const { updates } = this.state

      return (
        <WrappedComponent
          {...this.props}
          // presentation
          headerText={title}
          subheaderText={'Enter an email to get started'}
          buttonText={title}
          showLogin
          showUpdates
          // state
          updates={updates}
          // actions
          onClickLogin={this.handleRedirect}
          onChangeUpdates={this.handleChangeUpdates}
          onSubmit={this.handleSubmit}
        />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    authorizationRedirectUrl: state.appConfig.authorizationRedirectUrl
  }
}

const withRedux = connect(mapStateToProps, {setShowEmailPrompt})

export default compose(
  withRedux,
  withEmailFormLogic,
  withOutreachEmailFormLogic
)(OutreachEmailPrompt)
