import {
  SET_CAMP_STUDIO,
  SET_APP_MODE,
  SET_SESSION_TYPE,
  SET_WEEK,
  SET_CAMP_LOCATION,
  SET_ADMIN_CAMP_LOCATION,
  SET_MESSAGE,
  SET_SHOW_EMAIL_PROMPT
} from 'actions/actionTypes'

const appSettings = (state = {}, action) => {
  switch (action.type) {
    case SET_CAMP_STUDIO:
      const { campStudioId } = action
      return Object.assign({}, state, { campStudioId })
    case SET_APP_MODE:
      const { appMode } = action
      return Object.assign({}, state, {
        appMode,
        isUnoNative: appMode !== 'web',
        isKiosk: appMode === 'kiosk',
        isPreseason: appMode === 'preseason'
      })
    case SET_SESSION_TYPE:
      const { sessionType } = action
      return Object.assign({}, state, { sessionType })
    case SET_WEEK:
      const { week } = action
      return Object.assign({}, state, { week })
    case SET_CAMP_LOCATION:
      const { campLocationId } = action
      return Object.assign({}, state, { campLocationId })
    case SET_ADMIN_CAMP_LOCATION:
      const { adminCampLocation } = action
      return Object.assign({}, state, { adminCampLocation })
    case SET_MESSAGE:
      const { message } = action
      return Object.assign({}, state, { message })
    case SET_SHOW_EMAIL_PROMPT:
      const { showEmailPrompt } = action
      return Object.assign({}, state, { showEmailPrompt })
    default:
      return state
  }
}

export default appSettings
