import React from 'react'
import PropTypes from 'prop-types'

// components
import FilePreviewCell from 'components/FilePreviewCell'
import StudioFileDetailLink from 'components/StudioFileDetailLink'
import FilePreviewWrapper from 'components/FilePreviewWrapper'
import FilePreviewContent from 'components/FilePreviewContent'

// containers
import FilePreviewModerationBadgeContainer from 'containers/FileModerationBadgeContainer/Preview'

// moderation
import {
  isViewable,
  MODERATION_STATUS_PENDING
} from 'lib/moderation/status'

const StudioFilePreview = (props) => {
  const {userFile, file, selectUserFile, selectedUserFile} = props
  const selected = !!(selectedUserFile && (selectedUserFile.id === userFile.id))

  return (
    <FilePreviewCell>
      <StudioFileDetailLink userFile={userFile} selectUserFile={selectUserFile}>
        <FilePreviewWrapper file={file} selected={selected}>
          <FilePreviewContent file={file} />
          {!isViewable(file) &&
            <FilePreviewModerationBadgeContainer
              status={MODERATION_STATUS_PENDING}
            />
          }
        </FilePreviewWrapper>
      </StudioFileDetailLink>
    </FilePreviewCell>
  )
}

StudioFilePreview.propTypes = {
  userFile: PropTypes.object.isRequired, // from Apollo
  file: PropTypes.object.isRequired, // from Apollo
  selectedUserFile: PropTypes.object, // from Redux state
  selectUserFile: PropTypes.func.isRequired // from Redux actions
}

export default StudioFilePreview
