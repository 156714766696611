import React from 'react'
import PropTypes from 'prop-types'

// presentational components
import { Loading } from 'components/shared'

const withApolloData = (WrappedComponent) => {
  const ApolloData = (props) => {
    const {
      data: { studios } // from Apollo
    } = props

    if (!studios) { return <Loading /> }

    return <WrappedComponent
      {...props}
      studios={studios}
    />
  }

  ApolloData.propTypes = {
    data: PropTypes.object.isRequired
  }

  return ApolloData
}

export default withApolloData
