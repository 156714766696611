import React from 'react'
import PropTypes from 'prop-types'

const withFormState = (WrappedComponent) => {
  class TranscodeResourceVideosFormState extends React.Component {
    state = {
      sourcePrefix: null,
      result: null,
      error: null
    }

    // handle changed selection in form
    handleChange = (e) => {
      const sourcePrefix = e.target.value
      this.setState({sourcePrefix, result: null})
    }

    // handle successful result from GraphQL mutation
    handleResult = (result) => {
      const { data: { transcodeResourceVideos } } = result
      this.setState({ result: transcodeResourceVideos, error: null })
    }

    // handle error from GraphQL mutation
    handleError = (error) => {
      this.setState({error: error})
    }

    // handle form submission
    handleSubmit = (e) => {
      const { sourcePrefix } = this.state
      const { transcodeResourceVideos } = this.props
      transcodeResourceVideos({ sourcePrefix })
        .then(this.handleResult)
        .catch(this.handleError)
    }

    render () {
      const { sourcePrefix, result, error } = this.state

      return (
        <WrappedComponent
          {...this.props}
          sourcePrefix={sourcePrefix}
          result={result}
          error={error}
          onChange={this.handleChange}
          onResult={this.handleResult}
          onSubmit={this.handleSubmit}
        />
      )
    }
  }

  TranscodeResourceVideosFormState.propTypes = {
    // from mutation
    transcodeResourceVideos: PropTypes.func.isRequired
  }

  return TranscodeResourceVideosFormState
}

export default withFormState
