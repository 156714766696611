import React from 'react'
import PropTypes from 'prop-types'

// lib
import { userProfilePhotoUrl } from 'lib/urls'

// styles
import classes from './classes.scss'
import classNames from 'classnames'

// components
import LinkToUser from 'components/LinkToUser'

/**
Preview for an account's user as displayed in a list view.
*/
const AccountUserPreview = (props) => {
  const {
    user
  } = props

  const {
    first_name: firstName,
    last_name: lastName
  } = user

  const photoUrl = userProfilePhotoUrl(user)
  const userName = `${firstName} ${lastName}`

  const buttonClasses = classNames('kid-btn kid-btn--solid', classes.projectsButton)

  return (
    <div className={classes.accountUserPreview}>
      <div className={classes.accountUserProfileWrapper}>
        <p className={classes.accountUserProfileImage}>
          <img src={photoUrl} alt={userName} />
        </p>
        <p className={classes.accountUserProfileName}>
          {`${userName}`}
        </p>
      </div>
      <div className={classes.accountUserActions}>
        <div>
          <LinkToUser user={user} className={buttonClasses}>
            View Projects &amp; Photos >
          </LinkToUser>
        </div>
      </div>
    </div>
  )
}

AccountUserPreview.propTypes = {
  user: PropTypes.object.isRequired
}

export default AccountUserPreview
