import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// components
import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'
import FilterCheckbox from 'components/FilterCheckbox'
import classes from './classes.scss'

/**
Displays all available choices for one filterable attribute
(e.g. studio names), as well as their selection state.
*/
const FileFilterAttribute = (props) => {
  const {
    attribute, // studios, activities, moderation, etc.
    choices, // all selectable choices
    selected, // all currently selected choices
    isPrimary,
    isVisible
  } = props

  const filterClass = classNames(classes.filtersWrapper, {
    [`${classes.hidden}`]: !isVisible,
    [`${classes.filtersPrimary}`]: isPrimary,
    [`${classes.filtersSecondary}`]: !isPrimary
  })

  // return true if the choice is currently selected
  const isChoiceSelected = (value) => {
    return !!selected.find(selectedValue => selectedValue.id === value.id)
  }

  return (
    <ul className={filterClass}>
      <TransitionGroup>
        {isVisible && choices.map(value => (
          <MaxHeightTransition key={value.id}>
            <FilterCheckbox
              value={value}
              attribute={attribute}
              isSelected={isChoiceSelected(value)}
              hideCheckbox
            />
          </MaxHeightTransition>
        ))}
      </TransitionGroup>
    </ul>
  )
}

FileFilterAttribute.propTypes = {
  isPrimary: PropTypes.bool.isRequired,
  choices: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired
}

export default FileFilterAttribute
