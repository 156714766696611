import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { handleErrorResponse } from 'lib/xhr'

// presentational component
import TokenLogin from 'components/TokenLogin'

/**
HOC to add handling of the api server logout XHR request
*/
const withPreseasonXhrRequest = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      token: PropTypes.string.isRequired,
      history: PropTypes.object.isRequired
    }

    state = {
      error: null,
      success: false
    }

    _handleXhrResponse = (response) => {
      if (response.ok) {
        // redirect to the camper file defined in contents of token
        const { history } = this.props
        response.json().then(json => {
          const { data: { uid: userId, ufid: userFileId } } = json
          const path = `/campers/${userId}/files/${userFileId}?preseason=true`
          this.setState({ success: true })
          history.replace(path)
        })
      } else {
        handleErrorResponse.call(this, response)
      }
    }

    componentDidMount () {
      const { token } = this.props

      // Make XHR request to create session from token
      fetch(`/api/login/${token}`, { method: 'POST', credentials: 'include', redirect: 'error' })
        .then(this._handleXhrResponse)
    }

    render () {
      const { error, success } = this.state
      return (
        <WrappedComponent
          error={error}
          success={success}
        />
      )
    }
  }
}

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
const withRouterData = (WrappedComponent) => {
  return ({match, history}) => {
    const { token } = match.params

    return <WrappedComponent
      token={token}
      history={history}
    />
  }
}

export default compose(
  withRouterData,
  withPreseasonXhrRequest
)(TokenLogin)
