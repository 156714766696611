import React from 'react'
import { Link } from 'react-router-dom'

const LinkToRoute = ({ children, route, className }) => {
  const linkClass = className || 'kid-btn'

  return (
    <Link to={route} className={linkClass}>
      {children}
    </Link>
  )
}
export default LinkToRoute
