import React from 'react'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const AdminShowcaseDownloadConfirm = (props) => {
  const {
    file,
    isDropList,
    activeAction,
    onClickApprove,
    onClickCancel
  } = props

  const { url } = file

  return (
    <TransitionGroup>
      {activeAction === 'showcaseDownload' &&
        <MaxHeightTransition>
          <Confirmation
            visible
            isDropList={isDropList}
            header='You are downloading an unmoderated film.'
            subheader="Please don't showcase films that haven't been watched by staff."
          >
            <ConfirmationButton
              type='positive'
              text='Download and mark as moderated'
              href={url}
              target='_blank'
              action={onClickApprove}
            />
            <ConfirmationButton
              type='destructive'
              text='Download Anyway'
              href={url}
              target='_blank'
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

export default AdminShowcaseDownloadConfirm
