/**
Wraps any required `window` access in functions that can be mocked in tests.
*/
export default {
  setLocation: function (opts) {
    const { href } = opts

    if (href) {
      window.location.href = href
    }
  },

  reload: function () {
    window.location.reload()
  },

  getLocation: function (key) {
    return window.location[key]
  },

  setOnBarCodeRead: function (cb) {
    window.onBarCodeRead = cb
  },

  setOnLogout: function (cb) {
    window.onLogout = cb
  },

  setOnDownloadStart: function (cb) {
    window.onDownloadStart = cb
  },

  setOnDownloadComplete: function (cb) {
    window.onDownloadComplete = cb
  },

  postMessage: function (message, opt) {
    window.postMessage(message, opt)
  }
}
