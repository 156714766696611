import { combineReducers } from 'redux'
import fileFilters from './fileFilters'
import query from './query'
import appConfig from './appConfig'
import appSettings from './appSettings'
import userData from './userData'
import campLocations from './campLocations'

const rootReducer = combineReducers({
  query,
  fileFilters,
  appConfig,
  appSettings,
  userData,
  campLocations
})

export default rootReducer
