import React from 'react'

// components
import ActionButton from 'components/FileActionButtons/ActionButton'
import { IconDelete } from 'components/shared/icons'

const withAdminDeleteButtonAction = (WrappedComponent) => {
  const AdminDeleteButtonAction = (props) => {
    const {
      activeAction,
      setActiveAction
    } = props

    const handleClick = (e) => {
      e.preventDefault()
      setActiveAction('delete')
    }

    const active = activeAction === 'delete'
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        label='Delete'
        active={active}
        disabled={disabled}
        icon={IconDelete}
        onClick={handleClick}
      />
    )
  }

  return AdminDeleteButtonAction
}

export default withAdminDeleteButtonAction(ActionButton)
