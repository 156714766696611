import React from 'react'
import Select from 'components/AdminSettings/Select'

const CACHES = [
  { value: 'resources', title: 'Pool Resources' },
  { value: 'locations', title: 'Camp Locations' }
]

const CacheSelect = ({cacheId, onChange}) => (
  <Select value={cacheId || ''} onChange={onChange}>
    { CACHES.map(cache => (
      <option key={cache.value} value={cache.value}>
        { cache.title }
      </option>
    )) }
  </Select>
)

export default CacheSelect
