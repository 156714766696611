import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import classNames from 'classnames'
import { MEDIA_TYPE_STRATEGO_APP } from 'lib/media/types'

/**
Displays a thumbnail image for use in list and grid views
*/
const MediaThumbnail = (props) => {
  const { mediaType, thumbnailUrl, onImageError } = props
  const mediaThumbnailClass = classNames(classes.mediaThumbnail, {
    [`${classes.appThumbnail}`]: mediaType === MEDIA_TYPE_STRATEGO_APP
  })

  return (
    <img
      src={thumbnailUrl}
      className={mediaThumbnailClass}
      onError={onImageError}
    />
  )
}

MediaThumbnail.propTypes = {
  // mediaType may be null if this is a non-approved file
  mediaType: PropTypes.string,
  thumbnailUrl: PropTypes.string.isRequired,
  onImageError: PropTypes.func.isRequired
}

export default MediaThumbnail
