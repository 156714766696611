import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import StudioShowcaseSubmitLengthConfirm from 'components/FileActionConfirm/StudioShowcaseSubmitLengthConfirm'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const StudioShowcaseSubmitConfirm = (props) => {
  const {
    visible,
    showcaseReady,
    onClickCancel,
    onClickSubmit
  } = props

  if (!showcaseReady) {
    return (
      <StudioShowcaseSubmitLengthConfirm {...props} />
    )
  }

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            subheader='Add your file to showcase waitlist for a chance to share.'
          >
            <ConfirmationButton
              type='positive'
              action={onClickSubmit}
              text='I want a chance to showcase'
            />
            <ConfirmationButton
              action={onClickCancel}
              text='Cancel'
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

StudioShowcaseSubmitConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default StudioShowcaseSubmitConfirm
