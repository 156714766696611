import { connect } from 'react-redux'

// data for presentational components
const mapStateToProps = ({userData: {userFile}, appSettings: {isPreseason}}) => (
  { userFile, isPreseason }
)

const withRedux = connect(mapStateToProps)

export default withRedux
