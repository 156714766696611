import React from 'react'

const LinkToHistory = ({history, children, className}) => {
  const goBack = (e) => {
    e.preventDefault()
    history.goBack()
  }

  const linkClass = className || 'kid-btn'

  return (
    <a href='#' onClick={goBack} className={linkClass}>
      {children}
    </a>
  )
}

export default LinkToHistory
