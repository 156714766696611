import { connect } from 'react-redux'

// redux actions
import { setCampStudio, setCampLocation, setAppMode, setSessionType, setWeek } from 'actions'

const mapStateToProps = (state) => {
  const {
    campLocations: locations,
    appConfig: {
      nodeEnv
    },
    appSettings: {
      week,
      sessionType,
      appMode,
      campStudioId: studioId,
      campLocationId: locationId
    }
  } = state

  return {
    locations,
    week,
    sessionType,
    nodeEnv,
    appMode,
    studioId,
    locationId
  }
}

const withRedux = connect(mapStateToProps, {
  setCampStudio,
  setAppMode,
  setCampLocation,
  setSessionType,
  setWeek
})

export default withRedux
