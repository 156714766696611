import React from 'react'
import PropTypes from 'prop-types'
import StudioWeeklyFeatureContainer from 'containers/StudioWeeklyFeatureContainer'
import StudioPageButtons from 'components/StudioPageButtons'

const StudioContent = ({studio, programArc}) => {
  return (
    <div>
      <StudioWeeklyFeatureContainer programArc={programArc} studio={studio} />
      <StudioPageButtons studio={studio} />
    </div>
  )
}

StudioContent.propTypes = {
  studio: PropTypes.object.isRequired,
  programArc: PropTypes.object.isRequired
}

export default StudioContent
