exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---OutreachEmail__submit---8a1nH button {\n  text-transform: none;\n  font-style: italic; }\n\n.classes---OutreachEmail__header---2b7TA {\n  margin-bottom: 0;\n  font-weight: 900; }\n\n.classes---OutreachEmail__form---3u9sw form {\n  text-align: left; }\n  .classes---OutreachEmail__form---3u9sw form legend {\n    height: 0;\n    position: absolute;\n    text-indent: -3000px;\n    overflow: hidden; }\n\n.classes---OutreachEmail__submit---8a1nH button {\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2rem;\n  width: 100%; }\n\n.classes---OutreachEmail__linkAccount---1GyCG {\n  font-style: italic;\n  font-size: 18px;\n  line-height: 1.5; }\n  @media screen and (min-width: 768px) {\n    .classes---OutreachEmail__linkAccount---1GyCG {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1280px) {\n    .classes---OutreachEmail__linkAccount---1GyCG {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1600px) {\n    .classes---OutreachEmail__linkAccount---1GyCG {\n      font-size: 24px;\n      line-height: 1.5; } }\n", ""]);

// exports
exports.locals = {
	"OutreachEmail__submit": "classes---OutreachEmail__submit---8a1nH",
	"OutreachEmail__header": "classes---OutreachEmail__header---2b7TA",
	"OutreachEmail__form": "classes---OutreachEmail__form---3u9sw",
	"OutreachEmail__linkAccount": "classes---OutreachEmail__linkAccount---1GyCG"
};