import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { metric } from 'lib/metrics'
import StudioWeeklyFeature from 'components/StudioWeeklyFeature'

const withWeeklyFeatureLogic = (WrappedComponent) => {
  const WithWeeklyFeatureLogic = (props) => {
    const {
      isKiosk,
      isPreseason,
      programArc,
      studio
    } = props

    const onWeeklyFeatureClick = (weeklyFeatureId) => {
      metric('weeklyFeatureClick', { weeklyFeatureId })
    }

    const weeklyFeature = studio.featuredWeeklyInspiration[0]
    const studioTourImage = studio.studioTour[0].thumbnailLarge
    const leadText = !programArc ? 'Trust in Kids' : programArc.displayName
    const showBackToStudio = isKiosk || isPreseason

    return (
      <WrappedComponent
        weeklyFeature={weeklyFeature}
        onWeeklyFeatureClick={onWeeklyFeatureClick}
        studio={studio}
        studioTourImage={studioTourImage}
        leadText={leadText}
        showBackToStudio={showBackToStudio}
      />
    )
  }

  WithWeeklyFeatureLogic.propTypes = {
    week: PropTypes.number.isRequired,
    isKiosk: PropTypes.bool.isRequired,
    isPreseason: PropTypes.bool.isRequired,
    studio: PropTypes.object.isRequired
  }

  return WithWeeklyFeatureLogic
}

const mapStateToProps = ({
  appSettings: { week, isKiosk, isPreseason, sessionType }
}) => ({ week, isKiosk, isPreseason, sessionType })

const withReduxData = connect(mapStateToProps)

const StudioWeeklyFeatureContainer = compose(
  withReduxData,
  withWeeklyFeatureLogic
)(StudioWeeklyFeature)

export default StudioWeeklyFeatureContainer
