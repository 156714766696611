import React from 'react'
import Honeybadger from 'honeybadger-js'
import GoogleAnalytics from 'react-ga'

const withWebClientConfig = (WrappedComponent) => {
  const WebClientConfig = class extends React.Component {
    state = {
      success: false
    }

    UNSAFE_componentWillMount () {
      const {
        HONEYBADGER_API_KEY,
        GOOGLE_ANALYTICS_KEY,
        NODE_ENV
      } = window.env

      if (HONEYBADGER_API_KEY) {
        Honeybadger.configure({
          apiKey: HONEYBADGER_API_KEY,
          environment: NODE_ENV,
          disabled: NODE_ENV === 'development'
        })
      }

      if (GOOGLE_ANALYTICS_KEY) {
        GoogleAnalytics.initialize(GOOGLE_ANALYTICS_KEY)
      }
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
        />
      )
    }
  }

  return WebClientConfig
}

export default withWebClientConfig
