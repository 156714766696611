import React from 'react'
import classes from './classes.scss'

/**
Content that replaces the preview thumbnail when the file is still under
review and therefore its actual content can't be displayed.
*/
const AccountFilePreviewUnderReview = ({header, body}) => {
  return (
    <div>
      <div className={classes.comingSoonPreview}>
        {header}
      </div>
      <p className={classes.reviewInfo}>
        {body.map(line => (
          <span key={line}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  )
}

export default AccountFilePreviewUnderReview
