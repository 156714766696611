import React from 'react'

// presentational components
import { Loading, ServerError } from 'components/shared'

/**
HOC to handle raw Apollo data and state props and pass them on to a
wrapped (presentational) component
*/
const withApolloData = (WrappedComponent) => {
  return (props) => {
    const { data } = props
    const { appConfig, campLocations, error } = data

    if (error) { return <ServerError error={error} /> }
    if (!appConfig) { return <Loading /> }

    return <WrappedComponent
      {...props}
      appConfig={appConfig}
      campLocations={campLocations}
    />
  }
}

export default withApolloData
