import { graphql } from 'react-apollo'

// GraphQL query specific to this component
import query from './query.graphql'

/**
HOC to bind a GraphQL query and handlers to a wrapped component. `options`
takes a function that converts the component's received props into variables for
the GraphQL query.
*/
const withApolloQuery = graphql(query, {
  options: () => {
    return { variables: { usersCount: 1 } }
  }
})

export default withApolloQuery
