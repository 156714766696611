import React from 'react'
import PropTypes from 'prop-types'
import OutreachWrapper from 'components/OutreachWrapper'

/**
Outer wrapper for the file detail page when viewed by a non-admin account. Adds
an OutreachWrapper if this file is being accessed via a preseason link outside of uno-native.
*/
const AccountFileDetailWrapper = (props) => {
  const { isPreseasonFile, account, mancalaUrl, children } = props

  if (isPreseasonFile) {
    return (
      <OutreachWrapper account={account} mancalaUrl={mancalaUrl}>
        {children}
      </OutreachWrapper>
    )
  }

  return (
    <div>{children}</div>
  )
}

AccountFileDetailWrapper.propTypes = {
  isPreseasonFile: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  mancalaUrl: PropTypes.string.isRequired
}

export default AccountFileDetailWrapper
