import React from 'react'
import PropTypes from 'prop-types'
import MediaThumbnailContainer from 'containers/MediaThumbnailContainer'

/**
Displays a small thumbnail for the given file.
*/
const FilePreviewContent = (props) => {
  const { file } = props
  const {
    thumbnails,
    media_type: mediaType,
    content_type: contentType
  } = file

  return (
    <MediaThumbnailContainer
      mediaType={mediaType}
      contentType={contentType}
      thumbnailSmall={thumbnails.small}
      altThumbnailSmall={thumbnails.small_alt}
      defaultThumbnailSmall={thumbnails.small_default}
    />
  )
}

FilePreviewContent.propTypes = {
  file: PropTypes.object.isRequired
}

export default FilePreviewContent
