import React from 'react'

// components
import ActionButton from 'components/FileActionButtons/ActionButton'
import { IconUnsubmit } from 'components/shared/icons'

const withAdminDequeueButtonAction = (WrappedComponent) => {
  const AdminDequeueButtonAction = (props) => {
    const {
      activeAction,
      setActiveAction
    } = props

    const handleClick = (e) => {
      e.preventDefault()
      setActiveAction('dequeue')
    }

    const active = activeAction === 'dequeue'
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        label='Unsubmit'
        active={active}
        disabled={disabled}
        icon={IconUnsubmit}
        onClick={handleClick}
      />
    )
  }

  return AdminDequeueButtonAction
}

export default withAdminDequeueButtonAction(ActionButton)
