import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Container components
import Analytics from './Analytics'
import HomeContainer from 'containers/HomeContainer'
import UserHomeContainer from 'containers/UserHomeContainer'
import FileDetailContainer from 'containers/FileDetailContainer'
import AuthorizeContainer from 'containers/AuthorizeContainer'
import LogoutContainer from 'containers/LogoutContainer'
import AdminHomeContainer from 'containers/AdminHomeContainer'
import AdminSettingsContainer from 'containers/AdminSettingsContainer'
import AccountUsersContainer from 'containers/AccountUsersContainer'
import PreseasonLoginContainer from 'containers/PreseasonLoginContainer'
import TokenLoginContainer from 'containers/TokenLoginContainer'

import StudioSetupContainer from 'containers/StudioSetupContainer'
import StudioHomeContainer from 'containers/StudioHomeContainer'
import SkillGroupDetailContainer, {
  OutreachSkillGroupDetailContainer,
} from 'containers/SkillGroupDetailContainer'
import StudioResourcesContainer from 'containers/StudioResourcesContainer'

// Presentational components
import { NotFound } from 'components/shared'
import Responsibilities from 'components/Responsibilities'

const Routes = () => (
  <Router>
    <div>
      <Route path='/' component={Analytics} />
      <Switch>
        <Route exact path='/' component={HomeContainer} />
        {/* parents and staff: */}
        <Route exact path='/campers' component={AccountUsersContainer} />
        <Route
          exact
          path='/campers/:camperId/files'
          component={UserHomeContainer}
        />
        <Route
          exact
          path='/campers/:camperId/files/:fileId'
          component={FileDetailContainer}
        />
        {/* staff only: */}
        <Route exact path='/files' component={AdminHomeContainer} />
        <Route exact path='/files/:locationId' component={AdminHomeContainer} />
        <Route exact path='/settings' component={AdminSettingsContainer} />
        {/* parents, staff, and campers: */}
        <Route exact path='/studios/setup' component={StudioSetupContainer} />
        {/* campers: */}
        <Route
          exact
          path='/studios/:studioId'
          component={StudioHomeContainer}
        />
        <Route
          exact
          path='/studios/:studioId/:pageId(tour)'
          component={StudioHomeContainer}
        />
        <Route
          exact
          path='/studios/:studioId/:pageId'
          component={StudioResourcesContainer}
        />
        <Route
          exact
          path='/studios/:studioId/campers/:camperId'
          component={StudioHomeContainer}
        />
        <Route
          exact
          path='/studios/:studioId/skillgroups/:skillGroupId'
          component={SkillGroupDetailContainer}
        />
        <Route
          exact
          path='/studios/:studioId/skillgroups/:skillGroupId/skills/:skillId'
          component={SkillGroupDetailContainer}
        />
        {/* parents: */}
        <Route
          exact
          path='/diy/:kitId'
          component={OutreachSkillGroupDetailContainer}
        />
        <Route
          exact
          path='/diy/:kitId/:skillId'
          component={OutreachSkillGroupDetailContainer}
        />
        <Route path='/preseason/:token' component={PreseasonLoginContainer} />
        <Route path='/login/:token' component={TokenLoginContainer} />
        {/* parents and staff: */}
        <Route path='/authorize' component={AuthorizeContainer} />
        <Route path='/logout' component={LogoutContainer} />
        {/* everyone: */}
        <Route path='/responsibilities' component={Responsibilities} />
        <Route path='*' component={NotFound} />
      </Switch>
    </div>
  </Router>
)

export default Routes
