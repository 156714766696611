import React from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux'
import { compose } from 'redux'

// higher-order components
import withVideoJs from './withVideoJs'

// presentational component
import VideoPlayer from 'components/VideoPlayer'

const withFileVideoPlayer = (WrappedComponent) => {
  /**
  Given a camper File, pass relevant props to the `VideoJsPlayer` component
  */
  const FileVideoPlayer = (props) => {
    const { file } = props
    const { url, transcoded_url: transcodedUrl, thumbnails } = file
    const { small: poster, small_alt: altThumbnailSmall } = thumbnails

    // If the transcoded video is too short, use the first thumbnail instead of the second
    const handleLoadedMetadata = (player) => {
      if (altThumbnailSmall && player.duration() < 30) {
        player.poster(altThumbnailSmall)
      }
    }

    return (
      <WrappedComponent
        url={url}
        transcodedUrl={transcodedUrl}
        poster={poster}
        autoplay={false}
        loop={false}
        onLoadedMetadata={handleLoadedMetadata}
      />
    )
  }

  FileVideoPlayer.propTypes = {
    file: PropTypes.object.isRequired
  }

  return FileVideoPlayer
}

export default compose(
  withFileVideoPlayer,
  withVideoJs
)(VideoPlayer)
