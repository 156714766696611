import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { setMessage } from 'actions'

const withEmailFormLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      // data
      locations: PropTypes.array.isRequired,
      campLocation: PropTypes.object, // from StudioSetupContainer
      // redux actions
      setMessage: PropTypes.func.isRequired
    }

    state = {
      email: '',
      selectedLocation: null
    }

    /**
    If:
      * no location has been selected, and
      * a camp location has been configured, and
      * we have locations data
    Then:
      * set the camp location as the default selected location.
    TODO: remove the need for async handling of locations data!
    */
    UNSAFE_componentDidUpdate (nextProps) {
      const { campLocation, locations } = this.props
      const { selectedLocation } = this.state

      if (!selectedLocation && campLocation && locations.length > 1) {
        this.handleChangeLocation(campLocation.id)
      }
    }

    handleChangeEmail = (e) => {
      this.setState({
        email: e.target.value
      })
    }

    handleChangeLocation = (value) => {
      const { locations } = this.props
      const selectedLocation = locations.find(location => location.id === value)
      this.setState({selectedLocation})
    }

    displaySubscribeError = (error) => {
      const { setMessage } = this.props
      setMessage({
        type: 'FAILURE',
        header: 'Oops',
        body: error.message
      })
    }

    /**
    On successful Mailchimp response:
    * Clear out the local state for the form
    * Display a thank-you message
    * Optionally do additional handling via the `onSuccess` arg
    */
    handleSubscribeResponse = (response, updates, onSuccess) => {
      const { setMessage } = this.props

      if (response.ok) {
        this.setState({ email: '' })

        if (updates) {
          setMessage({
            type: 'SUCCESS',
            header: 'Thank You!',
            body: "We'll be in touch!"
          })
        } else {
          setMessage({
            type: 'SUCCESS',
            header: 'Thanks!',
            body: () => (
              <p>
                You will not receive further email communication from us.
                If you decide you want to receive news about camp,
                you can <a href='https://steveandkatescamp.com/email'>join our email list</a>.
              </p>
            )
          })
        }

        if (onSuccess) { onSuccess() }
      } else {
        response.json().then(body => {
          this.displaySubscribeError(new Error(body.error))
        })
      }
    }

    postEmail = ({pool, updates, onSuccess}) => {
      const { email, selectedLocation } = this.state
      const location = selectedLocation ? selectedLocation.name : null
      const status = updates ? 'subscribed' : 'unsubscribed'
      const signup = pool
      const params = location ? {email, signup, pool, status, location} : {email, signup, pool, status}

      const opts = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
      }

      fetch('/api/subscribe', opts)
        .then(response => this.handleSubscribeResponse(response, updates, onSuccess))
        .catch(this.displaySubscribeError)
    }

    render () {
      const { email, selectedLocation } = this.state

      return (
        <WrappedComponent
          {...this.props}
          // component state
          email={email}
          selectedLocation={selectedLocation}
          // actions
          onChangeEmail={this.handleChangeEmail}
          onChangeLocation={this.handleChangeLocation}
          postEmail={this.postEmail}
        />
      )
    }
  }
}

const mapStateToProps = (state) => {
  const {
    campLocations: locations,
    appSettings: { campLocationId }
  } = state

  return {
    locations,
    campLocationId
  }
}

const withRedux = connect(mapStateToProps, {setMessage})

export default compose(
  withRedux,
  withEmailFormLogic
)
