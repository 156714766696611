import React from 'react'

const VideoPlayer = ({getVideoNode}) => {
  return (
    <div data-vjs-player className='vjs-16-9 vjs-big-play-centered'>
      <img className='player-background' src='/assets/uno/images/kid-world/trans-1280x720.png' />
      <video ref={getVideoNode} className='video-js' />
    </div>
  )
}

export default VideoPlayer
