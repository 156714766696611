import React from 'react'
import PropTypes from 'prop-types'
import { getIcon } from 'components/shared/icons'
import classes from './classes.scss'
import classNames from 'classnames'

const StudioOption = ({studioId, studioIcon, selectedStudioId, onStudioChange}) => {
  const isSelected = studioId === selectedStudioId
  const studioClass = classNames(`StudioList__studio--${studioId}`, classes.studio, {[`${classes.selected}`]: isSelected})
  const icon = getIcon(studioIcon)

  return (
    <div className={classes.studioWrapper}>
      <div
        key={studioId}
        className={studioClass}
        onClick={() => onStudioChange(studioId)}
      >
        {icon}
      </div>
    </div>
  )
}

StudioOption.propTypes = {
  studioId: PropTypes.string.isRequired,
  studioIcon: PropTypes.string.isRequired,
  selectedStudioId: PropTypes.string,
  onStudioChange: PropTypes.func.isRequired
}

export default StudioOption
