import filesUrl from './filesUrl'

function mancalaAccountUrl (mancalaUrl, account) {
  const accountId = account.mancala_id

  // send unregistered users to account creation page
  if (!accountId) {
    return mancalaUrl
  }

  return `${mancalaUrl}/account`
}

function mancalaLogoutUrl (mancalaUrl) {
  return `${mancalaUrl}/logout`
}

function mancalaAdminOpsUrl (mancalaUrl) {
  return `${mancalaUrl}/admin/ops`
}

function mancalaAuthorizeUrl (mancalaUrl) {
  return `${mancalaUrl}/oauth/authorize`
}

// OAuth return path for exchanging auth token
function clientAuthorizeUrl (clientUrl) {
  return `${clientUrl}/authorize`
}

function mancalaAdminOpsCamperUrl (mancalaUrl, user) {
  const camperId = user.mancala_camper_id
  return `${mancalaUrl}/admin/ops/campers/${camperId}`
}

function mancalaHomeUrl (mancalaUrl, account) {
  if (account.user_type === 'AdminUser') {
    return mancalaAdminOpsUrl(mancalaUrl)
  } else {
    return mancalaAccountUrl(mancalaUrl, account)
  }
}

function mancalaAccountLoginUrl (mancalaUrl, staff) {
  return `${mancalaUrl}/login`
}

function marketingSiteUrl (path) {
  if (path) {
    return `https://steveandkatescamp.com/${path}`
  } else {
    return 'https://steveandkatescamp.com'
  }
}

function userFilesPath (user) {
  const { id, mancala_camper_id: mancalaCamperId } = user
  const userId = mancalaCamperId || id
  return `/campers/${userId}/files`
}

function userFilePath (user, userFile) {
  const basePath = userFilesPath(user)
  return `${basePath}/${userFile.id}`
}

function userProfilePhotoUrl (user) {
  return user.photo_thumbnail_url || 'https://skc-mancala.s3.amazonaws.com/production/assets/unknown_avatar.jpeg'
}

export {
  mancalaHomeUrl,
  mancalaLogoutUrl,
  mancalaAccountUrl,
  mancalaAdminOpsUrl,
  mancalaAdminOpsCamperUrl,
  mancalaAccountLoginUrl,
  mancalaAuthorizeUrl,
  marketingSiteUrl,
  clientAuthorizeUrl,
  filesUrl,
  userFilesPath,
  userFilePath,
  userProfilePhotoUrl
}
