exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---filePreviewCell---g1hNp {\n  display: block;\n  position: relative;\n  padding-bottom: 10px;\n  text-align: center; }\n  @media (min-width: 736px) and (max-width: 1023px) {\n    .classes---filePreviewCell---g1hNp {\n      display: inline-block;\n      margin: 0 24px 10px 0; }\n      .classes---filePreviewCell---g1hNp:nth-child(2n) {\n        margin-right: 0; } }\n  @media (min-width: 1024px) and (max-width: 1279px) {\n    .classes---filePreviewCell---g1hNp {\n      display: inline-block;\n      margin: 0 38px 0 0; }\n      .classes---filePreviewCell---g1hNp:nth-child(2n) {\n        float: right;\n        margin-right: 0; } }\n  @media (min-width: 1280px) and (max-width: 1920px) {\n    .classes---filePreviewCell---g1hNp {\n      display: inline-block;\n      margin: 0 38px 0 0; }\n      .classes---filePreviewCell---g1hNp:nth-child(3n) {\n        float: right;\n        margin-right: 0; } }\n  @media (min-width: 1920px) {\n    .classes---filePreviewCell---g1hNp {\n      display: inline-block;\n      margin: 0 26px 0 0; }\n      .classes---filePreviewCell---g1hNp:nth-child(4n) {\n        float: right;\n        margin-right: 0; } }\n", ""]);

// exports
exports.locals = {
	"filePreviewCell": "classes---filePreviewCell---g1hNp"
};