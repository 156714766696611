exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---comingSoonDetailWrapper---1UVMi {\n  min-height: 300px;\n  min-width: 300px;\n  padding: 20px;\n  border: 1px solid #e6e6e6; }\n\n.classes---comingSoonDetail---270mF {\n  font-size: 32px;\n  line-height: 1.25;\n  text-align: center;\n  text-transform: uppercase;\n  font-weight: 700;\n  margin-top: 40px; }\n  @media screen and (min-width: 768px) {\n    .classes---comingSoonDetail---270mF {\n      font-size: 42px;\n      line-height: 1.125; } }\n  @media screen and (min-width: 1280px) {\n    .classes---comingSoonDetail---270mF {\n      font-size: 42px;\n      line-height: 1.125; } }\n  @media screen and (min-width: 1600px) {\n    .classes---comingSoonDetail---270mF {\n      font-size: 48px;\n      line-height: 1.125; } }\n\n.classes---reviewInfo---20_Im {\n  font-style: italic;\n  font-weight: 300;\n  margin: 20px;\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"comingSoonDetailWrapper": "classes---comingSoonDetailWrapper---1UVMi",
	"comingSoonDetail": "classes---comingSoonDetail---270mF",
	"reviewInfo": "classes---reviewInfo---20_Im"
};