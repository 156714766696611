// import React from 'react'
import { compose } from 'redux'

// containers
import withMutation from './withMutation'
import withFormLogic from './withFormLogic'
import withRedux from './withRedux'

// presentational component
import AdminTranscodeResourceVideos from 'components/AdminTranscodeResourceVideos'

export default compose(
  withMutation,
  withFormLogic,
  withRedux
)(AdminTranscodeResourceVideos)
