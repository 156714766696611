import PropTypes from 'prop-types'

// components
import SubheaderFlourish from 'components/SubheaderFlourish'

// containers
import withFileSubheader from 'containers/withFileSubheader'

/**
 * Displays a user profile header for a given file, in the context of a scanned-in
 * camper
 */
const StudioUserFileHeader = withFileSubheader(SubheaderFlourish)

StudioUserFileHeader.propTypes = {
  file: PropTypes.object.isRequired
}

export default StudioUserFileHeader
