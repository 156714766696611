import React from 'react'
import StudioPageButton from './StudioPageButton'
import PropTypes from 'prop-types'

const StudioPageButtons = ({studio}) => {
  const studioPages = studio.studioPages

  return (
    <section>
      {studioPages.map(studioPage =>
        <StudioPageButton
          key={studioPage.id}
          studioPage={studioPage}
          studioId={studio.id}
        />
      )}
    </section>
  )
}

StudioPageButtons.propTypes = {
  studio: PropTypes.object.isRequired
}

export default StudioPageButtons
