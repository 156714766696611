import React from 'react'
import Select from './Select'

const WeekSelect = ({week, sessionType, programArcs, onChange}) => {
  const sessionProgramArcs = programArcs.filter(programArc => (
    programArc.sessionTypes.includes(sessionType)
  )).sort((a, b) => a.week - b.week)

  return (
    <Select value={week} onChange={onChange}>
      { sessionProgramArcs.map(programArc => (
        <option key={programArc.week} value={programArc.week}>
          { `${programArc.week}: ${programArc.displayName}` }
        </option>
      )) }
    </Select>
  )
}

export default WeekSelect
