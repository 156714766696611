import React from 'react'
import { ServerError, Loading } from 'components/shared'

const TokenLogin = ({success, error}) => {
  if (success) {
    // container should redirect before this displays
    return <h1>Welcome! Redirecting...</h1>
  } else if (error) {
    return <ServerError error={error} />
  } else {
    return <Loading />
  }
}

export default TokenLogin
