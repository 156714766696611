import { graphql } from 'react-apollo'
import query from 'mutations/File/ModerationStatusMutation.graphql'

const withModerationStatusMutation = graphql(query, {
  props: ({ mutate }) => ({
    submitModerationStatus: (id, input) => mutate({
      variables: { id, input },
      optimisticResponse: {
        __typename: 'Mutation',
        moderateFile: {
          __typename: 'File',
          id,
          moderation_status: input.moderation_status
        }
      }
    })
  })
})

export default withModerationStatusMutation
