import { graphql } from 'react-apollo'

// GraphQL query
import query from './query.graphql'

/**
HOC to bind a GraphQL query and handlers to a wrapped component. `options`
takes a function that converts the component's received props into variables for
the GraphQL query.
*/
const withApolloQuery = graphql(query, {
  options: (props) => {
    const {
      studioId,
      week,
      sessionType,
      camperId,
      userId,
      studioIds,
      activityIds,
      page,
      orderBy
    } = props

    const includeUser = !!camperId || !!userId

    return {
      variables: {
        studioId,
        week,
        sessionType,
        includeUser,
        camperId,
        userId,
        studioIds,
        activityIds,
        page,
        orderBy
      }
    }
  }
})

export default withApolloQuery
