import React from 'react'
import classes from './classes.scss'
import SkillPreview from 'components/SkillPreview'

const renderSkillPreviews = ({changeSelectedSkill, selectedSkill, skillGroup, setSelectedSkillNode}) => {
  const skills = skillGroup.skills
  const sequenceStart = skillGroup.isSkillsSequence ? (skillGroup.skillsSequenceStart[0] || skills[0]) : {}
  let skillNumber = 1
  let sequenceStarted

  return skills.map(skill => {
    sequenceStarted = sequenceStarted || sequenceStart.id === skill.id
    const isSelectedSkill = skill.id === selectedSkill.id
    const props = {
      skill: skill,
      onClick: changeSelectedSkill,
      selectedSkill: isSelectedSkill,
      skillNumber: sequenceStarted ? skillNumber : null,
      setSelectedSkillNode: setSelectedSkillNode
    }
    if (sequenceStarted) {
      skillNumber += 1
    }
    return <SkillPreview key={skill.id} {...props} />
  })
}

const SkillPreviewList = (props) => {
  const { setPreviewListNode } = props
  return (
    <ul ref={node => setPreviewListNode(node)} className={classes.skillsScroller}>
      {renderSkillPreviews(props)}
    </ul>
  )
}

export default SkillPreviewList
