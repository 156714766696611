// lib
import { studiosWithUploads } from 'lib/studios.js'
import { findStudio } from 'lib/studios'

const studiosFilter = (selectedStudioIds) => {
  const choices = studiosWithUploads()
  const selected = selectedStudioIds.map(id => findStudio(id))
  return { choices, selected }
}

export default studiosFilter
