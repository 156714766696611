import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import states from 'lib/states.json'

const OutreachStateList = ({selectedState, onChange}) => {
  const selectWrapperClass = classNames('mui-select')

  return (
    <div className={selectWrapperClass}>
      <label>State:</label>
      <select
        className='c-location-select__select'
        onChange={(e) => onChange(e.target.value)}
        value={selectedState}
        required
      >
        <option key='select' value=''>
          Select a State
        </option>
        {states.map(state => (
          <option key={state.value} value={state.value}>
            {state.name}
          </option>
        ))}
      </select>
    </div>
  )
}

OutreachStateList.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedState: PropTypes.object
}

export default OutreachStateList
