import { connect } from 'react-redux'
import FileTags from './component'

const mapStateToProps = (state) => {
  return {
    campLocations: state.campLocations
  }
}

export default connect(mapStateToProps)(FileTags)
