import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// styles
import classes from 'components/shared/classes.scss'

// components
import {
  Modal,
  TransitionGroup,
  FadeTransition
} from 'components/shared'

const AdminModalConfirm = (props) => {
  const {
    visible,
    headerText,
    paragraphText,
    emphasisText,
    buttonText,
    afterwardText,
    onClickContinue
  } = props

  const continueButtonClass = classNames('kid-btn', classes.continueButton)

  return (
    <TransitionGroup>
      {visible &&
        <FadeTransition>
          <Modal>
            <h1>{headerText}</h1>
            <p>{paragraphText}</p>
            <p><em>{emphasisText}</em></p>
            <div>
              <a className={continueButtonClass} onClick={onClickContinue}>
                {buttonText}
              </a>
            </div>
            <p className={classes.afterward}>
              {afterwardText}
            </p>
          </Modal>
        </FadeTransition>
      }
    </TransitionGroup>
  )
}

AdminModalConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  paragraphText: PropTypes.string,
  emphasisText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  afterwardText: PropTypes.string,
  onClickContinue: PropTypes.func.isRequired
}

export default AdminModalConfirm
