exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---status---1DuvK {\n  font-size: 18px;\n  line-height: 1.5;\n  font-weight: 400;\n  padding: 10px;\n  text-align: center;\n  white-space: nowrap;\n  text-transform: uppercase;\n  color: #ffffff;\n  background: rgba(0, 0, 0, 0.8); }\n  @media screen and (min-width: 768px) {\n    .classes---status---1DuvK {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1280px) {\n    .classes---status---1DuvK {\n      font-size: 22px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1600px) {\n    .classes---status---1DuvK {\n      font-size: 24px;\n      line-height: 1.5; } }\n  .classes---status---1DuvK.classes---pending---3gE36 {\n    background: #939598; }\n  .classes---status---1DuvK.classes---escalated---2eIRz {\n    background: #be1e2d; }\n", ""]);

// exports
exports.locals = {
	"status": "classes---status---1DuvK",
	"pending": "classes---pending---3gE36",
	"escalated": "classes---escalated---2eIRz"
};