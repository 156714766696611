import {
  STORE_APP_CONFIG,
  SET_CAMP_STUDIO,
  SET_APP_MODE,
  SET_SESSION_TYPE,
  SET_WEEK,
  SET_CAMP_LOCATION,
  SELECT_USER_FILE,
  SET_MESSAGE,
  STORE_LOCATIONS,
  SET_ADMIN_CAMP_LOCATION,
  SET_SHOW_EMAIL_PROMPT,
  SET_QUERY,
  SELECT_FILTER,
  SET_VIEWER,
  SET_REFETCH_QUERY,
  SET_USER,
  SET_LAST_STUDIO_PAGE_ID
} from 'actions/actionTypes'

export const selectFilter = (attribute) => {
  return {
    type: SELECT_FILTER,
    attribute
  }
}

export const setViewer = (viewer) => {
  return {
    type: SET_VIEWER,
    viewer
  }
}

export const setRefetchQuery = (refetchQuery) => {
  return {
    type: SET_REFETCH_QUERY,
    refetchQuery
  }
}

export const setUser = (user) => {
  return {
    type: SET_USER,
    user
  }
}

export const storeAppConfig = (appConfig) => {
  return {
    type: STORE_APP_CONFIG,
    appConfig
  }
}

export const setCampStudio = (campStudioId) => {
  return {
    type: SET_CAMP_STUDIO,
    campStudioId
  }
}

export const setAppMode = (appMode) => {
  return {
    type: SET_APP_MODE,
    appMode
  }
}

export const setSessionType = (sessionType) => {
  return {
    type: SET_SESSION_TYPE,
    sessionType
  }
}

export const setWeek = (week) => {
  return {
    type: SET_WEEK,
    week
  }
}

export const setCampLocation = (campLocationId) => {
  return {
    type: SET_CAMP_LOCATION,
    campLocationId
  }
}

export const selectUserFile = (userFile) => {
  return {
    type: SELECT_USER_FILE,
    userFile
  }
}

export const setMessage = (message) => {
  return {
    type: SET_MESSAGE,
    message
  }
}

export const storeLocations = (campLocations) => {
  return {
    type: STORE_LOCATIONS,
    campLocations
  }
}

export const setAdminCampLocation = (adminCampLocation) => {
  return {
    type: SET_ADMIN_CAMP_LOCATION,
    adminCampLocation
  }
}

export const setShowEmailPrompt = (showEmailPrompt) => {
  return {
    type: SET_SHOW_EMAIL_PROMPT,
    showEmailPrompt
  }
}

export const setQuery = (query) => {
  return {
    type: SET_QUERY,
    query
  }
}

export const setLastStudioPageId = (lastStudioPageId) => {
  return {
    type: SET_LAST_STUDIO_PAGE_ID,
    lastStudioPageId
  }
}
