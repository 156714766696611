exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---wrapper---2Cwrx {\n  max-width: 80%;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 4rem; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "classes---wrapper---2Cwrx"
};