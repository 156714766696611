import React from 'react'

// helpers
import { userFilesPath } from 'lib/urls'

// components
import { Link } from 'react-router-dom'

const LinkToUser = ({user, className, children}) => (
  <Link to={userFilesPath(user)} className={className}>
    {children}
  </Link>
)

export default LinkToUser
