
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SkillGroupDetails"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SkillGroup"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"headSet"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"icon"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isAddOn"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isSkillsSequence"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"week"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":126}};
    doc.loc.source = {"body":"fragment SkillGroupDetails on SkillGroup {\n  id\n  file\n  headSet\n  icon\n  image\n  isAddOn\n  isSkillsSequence\n  title\n  week\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
