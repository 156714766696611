import { connect } from 'react-redux'
import { compose } from 'redux'

import { selectFilter } from 'actions'

// presentational components
import FileFilterOptions from 'components/FileFilters/FileFilterOptions'

const mapStateToProps = (state) => ({
  orderBy: state.query.sort,
  filtersVisible: state.fileFilters.filtersVisible,
  selectedFilter: state.fileFilters.selectedFilter
})

const withRedux = connect(mapStateToProps, { selectFilter })

export default compose(
  withRedux
)(FileFilterOptions)
