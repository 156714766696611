import React from 'react'
import PropTypes from 'prop-types'

// components
import CacheSelect from './CacheSelect'

const AdminClearCache = (props) => {
  const {
    // from Redux
    nodeEnv,
    // from form state
    cacheId,
    // from GraphQL mutation
    result,
    error,
    // from container logic
    onChange,
    onSubmit
  } = props

  return (
    <div>
      <h5>Select Content Type</h5>
      <CacheSelect cacheId={cacheId} onChange={onChange} />

      <a onClick={onSubmit} className='kid-btn kid-btn--solid'>
        Publish to {nodeEnv}
      </a>

      {result.length > 0 && (
        <p>{result.length} cache key(s) cleared successfully!</p>
      )}

      {error && (
        <span>{error.message}</span>
      )}
    </div>
  )
}

AdminClearCache.propTypes = {
  nodeEnv: PropTypes.string.isRequired,
  cacheId: PropTypes.string,
  result: PropTypes.array.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default AdminClearCache
