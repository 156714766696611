import React from 'react'
import classes from './classes.scss'

const Message = () => (
  <p>
    <em>Steve &amp; Kate&rsquo;s reviews projects.</em>
  </p>
)

const PostingGuidelines = ({isPreseasonFile}) => {
  return (
    <section className={classes.privacyPolicy}>
      <Message />
      <p>Learn more about our <a href='/responsibilities'>posting guidelines</a>.</p>
    </section>
  )
}

export default PostingGuidelines
