import { compose } from 'redux'

// composable higher-order components
import withReduxForApollo from './withReduxForApollo'
import withApolloQuery from './withApolloQuery'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withApolloData from './withApolloData'
import withRedux from './withRedux'
import withScanLogic from './withScanLogic'
import withRouterQuery from './withRouterQuery'
import withMetric from './withMetric'

// presentational component
import StudioHome from 'components/StudioHome'

export default compose(
  withRouterQuery, // parse query params and save query to redux
  withReduxForApollo,
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedux, // fetch data required by presentational components
  withScanLogic, // handle barcode scanning and camper logout
  // FIXME: check `isTour` prop and report `studioTourView` instead
  withMetric
)(StudioHome)
