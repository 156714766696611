import {
  SET_QUERY
} from 'actions/actionTypes'

import { DEFAULT_QUERY } from 'lib/redux'

// Set query to a copy of the state where the new query is merged with the default query
const query = (state = {}, action) => {
  switch (action.type) {
    case SET_QUERY:
      return Object.assign({}, DEFAULT_QUERY, action.query)
    default:
      return state
  }
}

export default query
