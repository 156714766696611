import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

const AudioPlayer = (props) => {
  const { source, thumbnailLarge, getAudioNode } = props

  return (
    <div className={classes.audioWrapper}>
      <img className={classes.audioImage} src={thumbnailLarge} />
      <audio ref={getAudioNode} src={source} controls />
    </div>
  )
}

AudioPlayer.propTypes = {
  source: PropTypes.string.isRequired,
  thumbnailLarge: PropTypes.string.isRequired,
  getAudioNode: PropTypes.func.isRequired
}

export default AudioPlayer
