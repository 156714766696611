exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---back---1ds9e {\n  color: #ffffff;\n  background: #000000; }\n  .classes---back---1ds9e svg {\n    fill: #ffffff; }\n  .classes---back---1ds9e:hover, .classes---back---1ds9e:focus, .classes---back---1ds9e:active {\n    background: #333333; }\n\n.classes---mast---1-9V5 {\n  position: relative;\n  overflow: hidden;\n  padding: 0 10px 20px;\n  margin-top: 20px; }\n\n.classes---back---1ds9e a {\n  display: block;\n  margin: 10px auto 0; }\n  @media (min-width: 632px) {\n    .classes---back---1ds9e a {\n      position: absolute;\n      top: 24px;\n      margin: 0 auto;\n      left: 10px; } }\n  @media (min-width: 1025px) {\n    .classes---back---1ds9e a {\n      top: 30px;\n      left: 0px; } }\n", ""]);

// exports
exports.locals = {
	"back": "classes---back---1ds9e",
	"mast": "classes---mast---1-9V5"
};