import { graphql } from 'react-apollo'
import query from 'mutations/File/SubmitShowcaseFileMutation.graphql'

const withSubmitShowcaseFileMutation = graphql(query, {
  props: ({ mutate }) => ({
    submit: (input) => mutate({
      variables: { input },
      optimisticResponse: {
        __typename: 'Mutation',
        updateFile: {
          __typename: 'SubmitShowcaseFilePayload',
          clientMutationId: 0,
          file: {
            __typename: 'File',
            id: input.id,
            location: input.location,
            metadata: input.metadata,
            submitted: true,
            clientMutationId: '0'
          }
        }
      }
    })
  })
})

export default withSubmitShowcaseFileMutation
