import { connect } from 'react-redux'
import { setLastStudioPageId } from 'actions'

const mapStateToProps = ({ appSettings: { isPreseason } }) => (
  { isPreseason }
)

const withRedux = connect(mapStateToProps, { setLastStudioPageId })

export default withRedux
