import React from 'react'
import { metric } from 'lib/metrics'

// components
import DownloadButton from 'components/FileActionButtons/DownloadButton'

const withAccountFileDownloadButtonAction = (WrappedComponent) => {
  const AccountFileDownloadButtonAction = (props) => {
    const {
      isShowcase,
      activeAction,
      file: { url, id: fileId }
    } = props

    const handleClick = (e) => {
      const context = 'web'
      metric('fileDownload', { fileId, url, isShowcase, context })
    }

    const active = activeAction === 'download'
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        // label='Download'
        active={active}
        disabled={disabled}
        onClick={handleClick}
      />
    )
  }

  return AccountFileDownloadButtonAction
}

export default withAccountFileDownloadButtonAction(DownloadButton)
