exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---closeButton---1lMvY {\n  float: left;\n  margin-top: 8px; }\n\n.classes---modalNav---2-bL3 {\n  position: relative;\n  overflow: hidden;\n  padding: 6px;\n  margin: 1rem 0 0 1rem; }\n  .classes---modalNav---2-bL3 svg {\n    fill: white;\n    stroke: white;\n    background: rgba(0, 0, 0, 0.3);\n    padding: 8px;\n    border-radius: 20px; }\n\n.classes---studioFileDetail---35i6K {\n  position: relative;\n  background-color: rgba(242, 242, 242, 0.5);\n  margin: 40px;\n  border-radius: 4px; }\n  .classes---studioFileDetail---35i6K:after {\n    content: \"\";\n    display: table;\n    clear: both; }\n  .classes---studioFileDetail---35i6K:before {\n    content: ' ';\n    display: block;\n    width: 0;\n    height: 0;\n    border-left: 14px solid transparent;\n    border-right: 14px solid transparent;\n    border-top: 14px solid rgba(242, 242, 242, 0.5);\n    position: absolute;\n    bottom: -14px;\n    left: -webkit-calc(50% - 14px);\n    left: calc(50% - 14px); }\n", ""]);

// exports
exports.locals = {
	"closeButton": "classes---closeButton---1lMvY",
	"modalNav": "classes---modalNav---2-bL3",
	"studioFileDetail": "classes---studioFileDetail---35i6K"
};