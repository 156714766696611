import { compose } from 'redux'

// composable higher-order components
import withRouterData from './withRouterData'
import withReduxForApollo from './withReduxForApollo'
import withApolloQuery from './withApolloQuery'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withApolloData from './withApolloData'
import withRedux from './withRedux'
import withLastStudioPageId from './withLastStudioPageId'
import withBackLink from './withBackLink'
import withMetric from './withMetric'

// presentational component
import StudioResources from 'components/StudioResources'

const StudioResourcesContainer = compose(
  withRouterData,
  withMetric,
  withReduxForApollo,
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedux,
  withLastStudioPageId,
  withBackLink
)(StudioResources)

export default StudioResourcesContainer
