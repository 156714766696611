import React from 'react'
import Form from 'muicss/lib/react/form'
import Button from 'muicss/lib/react/button'

import classes from './classes.scss'

// components

const OutreachPromptWrapper = (props) => {
  const {
    // presentation
    headerText, // 'Make a light-up pin.'
    subheaderText, // 'Enter an email to get started.'
    footerText, // 'Kit will be sent while supplies lasts...'
    buttonText, // 'Make a light-up pin.'
    cancelButtonText, // 'Nevermind, I don't want a free kit'
    children,
    // state
    showLogin, // true
    // actions
    onClickLogin,
    onCancel,
    onSubmit
  } = props

  return (
    <div>
      <h1 className={classes.OutreachEmail__header}>{headerText}</h1>
      <p className={classes.OutreachEmail__subheader}>{subheaderText}</p>
      <section className={classes.OutreachEmail__form}>
        <Form onSubmit={onSubmit}>
          {children}
          {footerText && <p className={classes.OutreachEmail__subheader}>{footerText}</p>}
          <div className={classes.OutreachEmail__submit}>
            <Button variant='accent' type='submit'>
              {buttonText} »
            </Button>
            { cancelButtonText && <Button onClick={onCancel}>
              {cancelButtonText} »
            </Button> }
          </div>
        </Form>
        {showLogin && (
          <p className={classes.OutreachEmail__linkAccount}>Already have an account? <a onClick={onClickLogin}>Log in.</a></p>
        )}
      </section>
    </div>
  )
}

export default OutreachPromptWrapper
