import React from 'react'
import { ServerError, Loading } from 'components/shared'
import classes from 'components/shared/classes.scss'

const Logout = ({success, error}) => {
  if (success) {
    return (
      <section className={classes.statusWrapper}>
        <h1>Logged Out</h1>
        <p><muted>Bye!</muted></p>
      </section>
    )
  } else if (error) {
    return <ServerError error={error} />
  } else {
    return <Loading />
  }
}

export default Logout
