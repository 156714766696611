import withMetric from 'containers/withMetric'

const mapPropsToMetrics = ({ camperId, userId, studioId }) => {
  // only report studio home views (ignore camper scans etc)
  if (camperId || userId) { return null }

  return { studioId }
}

export default withMetric('studioHomeView', mapPropsToMetrics)
