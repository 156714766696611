import React from 'react'
import PropTypes from 'prop-types'

// containers
import BackButtonContainer from 'containers/BackButtonContainer'
import AdminFilePreviewContainer from 'containers/AdminFilePreviewContainer'
import AdminFileFilterSelectContainer from 'containers/AdminFileFilterSelectContainer'

// components
import LegacyLayout from 'components/LegacyLayout'
import UserProfile from 'components/UserProfile'
import AdminHomeHeader from 'components/AdminHomeHeader'
import FileFilters from 'components/FileFilters'
import FilePagination from 'components/FilePagination'
import FilesEmpty from 'components/FilesEmpty'
import LinkToOps from 'components/LinkToOps'
import AdminModerationFooter from 'components/AdminModerationFooter'

const AdminHome = ({ adminCampLocation, account }) => {
  const { edges: files, pageInfo } = account.files

  return (
    <LegacyLayout>
      <UserProfile account={account} user={null}>
        <BackButtonContainer
          linkTo={LinkToOps}
          text='Ops'
          displayMode='userProfile'
        />
      </UserProfile>
      <AdminHomeHeader header={adminCampLocation.name || 'All Locations'}>
        <AdminFileFilterSelectContainer />
      </AdminHomeHeader>
      <FileFilters isAdmin />
      {files.length === 0 ? (
        <FilesEmpty message='No files match your search.' />
      ) : (
        <div>
          {files.map(({ node: file }) => (
            <AdminFilePreviewContainer key={file.id} file={file} />
          ))}
          <FilePagination pageInfo={pageInfo} />
        </div>
      )}
      <AdminModerationFooter />
    </LegacyLayout>
  )
}

AdminHome.propTypes = {
  account: PropTypes.object.isRequired, // from Apollo
  adminCampLocation: PropTypes.object.isRequired // from Redux state
}

export default AdminHome
