exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---headerWrapper---1hvn1 {\n  overflow: hidden; }\n", ""]);

// exports
exports.locals = {
	"headerWrapper": "classes---headerWrapper---1hvn1"
};