import React from 'react'
import { CSSTransition } from 'react-transition-group'

const FadeTransition = (props) => {
  return (
    <CSSTransition
      {...props}
      classNames='fade-transition'
      timeout={{enter: 400, exit: 400}}
    />
  )
}

export default FadeTransition
