import React from 'react'

const withUnderReviewText = (WrappedComponent) => {
  const FileUnderReviewText = (props) => {
    return (
      <WrappedComponent
        {...props}
        header='Coming Soon'
        body={[
          'For the safety of campers, we review media.',
          'Check back. We\'ll post as soon as review is complete.'
        ]}
      />
    )
  }

  return FileUnderReviewText
}

export default withUnderReviewText
