exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---pageHeader---3HOOV {\n  text-align: center;\n  margin-top: 20px; }\n\n.classes---userImage---s1e8j {\n  display: inline-block;\n  position: relative; }\n  .classes---userImage---s1e8j img {\n    margin-top: -20px;\n    width: 60px;\n    height: 60px;\n    border-radius: 30px;\n    clip-path: circle(60px at center);\n    -webkit-clip-path: circle(30px at center);\n    margin-right: 10px;\n    margin-left: -60px;\n    object-fit: cover; }\n", ""]);

// exports
exports.locals = {
	"pageHeader": "classes---pageHeader---3HOOV",
	"userImage": "classes---userImage---s1e8j"
};