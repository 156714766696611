import React from 'react'
import Cookies from 'cookies-js'

const getSessionTypeForAppMode = (appMode, locationId, locations) => {
  if (appMode === 'preseason') {
    return 'preseason'
  }

  if (locationId) {
    const location = locations.find(location => location.id === locationId)

    if (location) {
      return location.session.break_type
    }
  }
}

const setSessionTypeForAppMode = (appMode, locationId, locations, setSessionType) => {
  const sessionType = getSessionTypeForAppMode(appMode, locationId, locations)
  if (sessionType) {
    setSessionType(sessionType)
    Cookies.set('campSessionType', sessionType)
  }
}

const expireCookies = () => {
  [
    'appMode', 'isUnoNative', 'campStudioId', 'campLocationId',
    'campSessionType', 'campWeekNumber', 'emailSubmitted'
  ].forEach(setting => Cookies.expire(setting))
}

/**
HOC which parses raw router props, detects staff configuration params,
and optionally sets/expires cookies and reloads
*/
const withConfigLogic = (WrappedComponent) => {
  return (props) => {
    const {
      // from GraphQL
      locations,
      // current settings
      appMode,
      locationId,
      // redux actions
      setCampStudio,
      setAppMode,
      setCampLocation,
      setSessionType,
      setWeek
    } = props

    const handleAppModeChange = (e) => {
      const appMode = e.target.value

      // reset cookies and state for uno-native modes
      if (appMode === 'web') {
        expireCookies()
        setAppMode('web')
        setCampLocation(null)
        setSessionType(null)
        setWeek(1)
        return
      }

      // simulate an uno-native mode
      setAppMode(appMode)
      Cookies.set('appMode', appMode)
      Cookies.set('isUnoNative', true)

      // set session type (if needed)
      setSessionTypeForAppMode(appMode, locationId, locations, setSessionType)
    }

    const handleStudioChange = (e) => {
      const studioId = e.target.value
      setCampStudio(studioId)
      Cookies.set('campStudioId', studioId)
    }

    const handleLocationChange = (e) => {
      // set location
      const locationId = e.target.value
      setCampLocation(locationId)
      Cookies.set('campLocationId', locationId)

      // set session type (if needed)
      setSessionTypeForAppMode(appMode, locationId, locations, setSessionType)
    }

    const handleWeekChange = (e) => {
      // set week
      const week = e.target.value
      setWeek(week)
      Cookies.set('campWeekNumber', week)
    }

    return <WrappedComponent
      {...props}
      onAppModeChange={handleAppModeChange}
      onStudioChange={handleStudioChange}
      onLocationChange={handleLocationChange}
      onWeekChange={handleWeekChange}
    />
  }
}

export default withConfigLogic
