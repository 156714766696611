import React from 'react'
import { compose } from 'redux'

// presentational component
import AdminMoreActionsConfirm from 'components/FileActionConfirm/AdminMoreActionsConfirm'

const withAdminMoreActions = WrappedComponent => {
  const AdminMoreActions = props => {
    const {
      activeAction, // from withActiveFileAction
      setActiveAction // from withActiveFileAction
    } = props

    const handleClickApprove = e => {
      e.preventDefault()
      setActiveAction('approve')
    }

    const handleClickUnapprove = e => {
      e.preventDefault()
      setActiveAction('unapprove')
    }

    const handleClickEscalate = e => {
      e.preventDefault()
      setActiveAction('escalate')
    }

    const handleClickDeescalate = e => {
      e.preventDefault()
      setActiveAction('deescalate')
    }

    const handleClickReport = e => {
      e.preventDefault()
      setActiveAction('report')
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'more'}
        onClickApprove={handleClickApprove}
        onClickUnapprove={handleClickUnapprove}
        onClickReport={handleClickReport}
        onClickEscalate={handleClickEscalate}
        onClickDeescalate={handleClickDeescalate}
      />
    )
  }

  return AdminMoreActions
}

export default compose(withAdminMoreActions)(AdminMoreActionsConfirm)
