import React from 'react'
import classes from './classes.scss'

const ConfirmationWrapper = (props) => {
  const { isDropList, children } = props

  if (!isDropList) {
    return (
      <div>{children}</div>
    )
  }

  return (
    <ul className={classes.dropList}>
      <li className={classes.dropPanel}>
        {children}
      </li>
    </ul>
  )
}

export default ConfirmationWrapper
