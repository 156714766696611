import React from 'react'
import classes from './classes.scss'

/**
Outer wrapper around the media displayed on a file detail page.
*/
const FileMediaWrapper = ({children}) => {
  return (
    <div className={classes.mediaWrapper}>
      {children}
    </div>
  )
}

export default FileMediaWrapper
