import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { setMessage } from 'actions'
import { forOwn } from 'lodash/object'

// presentational component
import OutreachAddressPrompt from 'components/OutreachAddressPrompt'

const withAddressFormLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      setMessage: PropTypes.func.isRequired
    }

    state = {
      firstName: '',
      lastName: '',
      streetAddress: '',
      extendedAddress: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: '',
      email: ''
    }

    handleChangeValue = (property, value) => {
      this.setState({
        [property]: value
      })
    }

    handleChangeState = (value) => {
      this.setState({
        state: value
      })
    }

    handleChangeValueFunction = (property) => {
      return (e) => {
        this.handleChangeValue(property, e.target.value)
      }
    }

    displayKitError = (error) => {
      const { setMessage } = this.props
      setMessage({
        type: 'FAILURE',
        header: 'Oops',
        body: error.message
      })
    }

    /**
    On successful response:
    * Clear out the local state for the form
    * Display a thank-you message
    */
    handleKitSentResponse = (response) => {
      const { setMessage } = this.props

      if (response.ok) {
        this.setState({
          firstName: '',
          lastName: '',
          streetAddress: '',
          extendedAddress: '',
          city: '',
          state: '',
          zip: '',
          phoneNumber: '',
          email: ''
        })

        setMessage({
          type: 'SUCCESS',
          header: 'Thank You!',
          body: 'Your kit is being prepared and sent!'
        })
      } else {
        response.json().then(body => {
          this.displayKitError(new Error(body.error))
        })
      }
    }

    sendToGoogleForm = () => {
      const {
        firstName,
        lastName,
        streetAddress,
        extendedAddress,
        city,
        state,
        zip,
        phoneNumber,
        email
      } = this.state

      const googleFormUrl = 'https://docs.google.com/forms/u/1/d/e/1FAIpQLSc2tKGtLrt6ZNu_opX6_WEZRA_95_q9IMLepyIPrtIJM0eFyg/formResponse'

      const params = {
        'entry.789139724': firstName,
        'entry.83246106': lastName,
        'entry.1768633812': streetAddress,
        'entry.362469442': extendedAddress,
        'entry.1898809648': city,
        'entry.81599339': state,
        'entry.1735255864': zip,
        'entry.1182202789': phoneNumber,
        'entry.1367208887': email
      }

      const queryString = Object
        .entries(params)
        .map(values => [values[0], encodeURIComponent(values[1])].join('='))
        .join('&')

      const postUrl = [googleFormUrl, queryString].join('?')

      const opts = {
        method: 'POST',
        mode: 'no-cors'
      }

      fetch(postUrl, opts)
        .then(response => this.handleKitSentResponse)
        .catch(this.displayKitError)
    }

    handleSubmit = (e) => {
      e.preventDefault()

      const { onCloseRequestKit } = this.props

      onCloseRequestKit()
      this.sendToGoogleForm()
    }

    render () {
      const {
        firstName,
        lastName,
        streetAddress,
        extendedAddress,
        city,
        state,
        zip,
        phoneNumber,
        email
      } = this.state

      const { onCloseRequestKit } = this.props

      const footerText = 'We are busy making these kits so that you can ' +
                         'get to Making at home! Kits will arrive in your ' +
                         'mailbox while supplies last.'

      return (
        <WrappedComponent
          {...this.props}
          // component state
          firstName={firstName}
          lastName={lastName}
          streetAddress={streetAddress}
          extendedAddress={extendedAddress}
          city={city}
          state={state}
          zip={zip}
          phoneNumber={phoneNumber}
          email={email}
          // actions
          handleChangeValueFunction={this.handleChangeValueFunction}
          handleChangeState={this.handleChangeState}
          onCancel={onCloseRequestKit}
          onSubmit={this.handleSubmit}
          // presentation
          headerText={'Request a kit.'}
          subheaderText={'Share a mailing address to get a kit sent to you or a friend.'}
          footerText={footerText}
          buttonText={'Send me my free kit'}
          cancelButtonText={"Nevermind, I don't want a free kit"}
        />
      )
    }
  }
}

const withRedux = connect(() => ({}), {setMessage})

export default compose(
  withRedux,
  withAddressFormLogic
)(OutreachAddressPrompt)
