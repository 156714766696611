import React from 'react'

import { findLocationName } from 'lib/campLocations'

/**
HOC to handle raw Apollo data and state props and pass them on to a
wrapped (presentational) component
*/
const withQueryResults = WrappedComponent => {
  return props => {
    const {
      data, // from Apollo
      campLocations, // from Redux state (via app initialization)
      setUser // from Redux actions
    } = props

    const { viewer: account } = data
    const isAdmin = (account || {}).user_type === 'AdminUser'
    const { user } = account || {}
    const { user_file: userFile } = user || {}
    if (!userFile) {
      return null
    }

    const { file } = userFile
    const fileLocationName =
      findLocationName(campLocations, file.location) || ''

    setUser(account.user)

    return (
      <WrappedComponent
        {...props}
        user={user}
        file={file}
        userFile={userFile}
        isAdmin={isAdmin}
        fileLocationName={fileLocationName}
        account={account}
      />
    )
  }
}

export default withQueryResults
