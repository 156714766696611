import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import classes from './classes.scss'

class SkillGroupTitle extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  }

  _renderTitleCharacters = (titleCharacters, characterLimit) => {
    const titleCharactersMidPoint = titleCharacters.length / 2

    // separate first half of word
    const firstHalfOfTitle = titleCharacters.slice(0, titleCharactersMidPoint + 1)

    // separate second half of word
    const secondHalfOfTitle = titleCharacters.slice(titleCharactersMidPoint + 1, titleCharacters.length)

    // fill spacers 24; span cells expected to wrap 360deg
    const emptySpansToFill = characterLimit - titleCharacters.length
    let spacerSpans = []
    for (let i = 0; i < emptySpansToFill; ++i) {
      spacerSpans.push(' ')
    }

    const titleCharactersReordered = secondHalfOfTitle.concat(spacerSpans).concat(firstHalfOfTitle)
    return titleCharactersReordered.map(this._renderCharacter)
  }

  _renderCharacter = (str, index) => {
    return <span key={`char${index}`}>{str}</span>
  }

  render () {
    const spannedTitleCharacters = this._renderTitleCharacters(this.props.text.split(''), 24)
    let titleWrapperClass = classNames(classes.wordWrap, {
      [`${classes.even}`]: this.props.text.length % 2 === 0
    })

    return (
      <span className={titleWrapperClass}>
        {spannedTitleCharacters}
      </span>
    )
  }
}

export default SkillGroupTitle
