import React from 'react'

// containers
import SkillGroupCategoryContainer from 'containers/SkillGroupCategoryContainer'

// components
import LegacyLayout from 'components/LegacyLayout'
import StudioMast from 'components/StudioMast'
import LinkToRoute from 'components/LinkToRoute'

const StudioResources = props => {
  const { studioPage, studioId, backLinkRoute, backLinkText } = props
  const headSet = studioPage.header
  const head = studioPage.subHeader
  const skillGroupCategories = studioPage.skillGroupCategories

  return (
    <LegacyLayout>
      <StudioMast
        headSet={headSet}
        head={head}
        studioId={studioId}
        linkTo={LinkToRoute}
        route={backLinkRoute}
        backLinkText={backLinkText}
      />

      {skillGroupCategories.map(skillGroupCategory => (
        <SkillGroupCategoryContainer
          key={skillGroupCategory.id}
          skillGroupCategory={skillGroupCategory}
          studioId={studioId}
        />
      ))}
    </LegacyLayout>
  )
}

export default StudioResources
