import React from 'react'
import OutreachFooter from 'components/OutreachFooter'
import AccountHeader from 'components/AccountHeader'

const OutreachWrapper = ({ children, mancalaUrl, account }) => {
  return (
    <div className='OutreachWrapper'>
      <AccountHeader mancalaUrl={mancalaUrl} account={account} />
      {children}
      <OutreachFooter mancalaUrl={mancalaUrl} />
    </div>
  )
}

export default OutreachWrapper
