import { connect } from 'react-redux'

import { selectUserFile } from 'actions'

// components
import StudioFilePreview from 'components/StudioFilePreview'

const mapStateToProps = ({userData: { userFile: selectedUserFile }}) => ({selectedUserFile})

const withRedux = connect(mapStateToProps, { selectUserFile })

export default withRedux(StudioFilePreview)
