export const STORE_APP_CONFIG = 'STORE_APP_CONFIG'
export const SET_CAMP_STUDIO = 'SET_CAMP_STUDIO'
export const SET_APP_MODE = 'SET_APP_MODE'
export const SET_SESSION_TYPE = 'SET_SESSION_TYPE'
export const SET_WEEK = 'SET_WEEK'
export const SET_CAMP_LOCATION = 'SET_CAMP_LOCATION'
export const SELECT_USER_FILE = 'SELECT_USER_FILE'
export const SET_MESSAGE = 'SET_MESSAGE'
export const STORE_LOCATIONS = 'STORE_LOCATIONS'
export const SET_ADMIN_CAMP_LOCATION = 'SET_ADMIN_CAMP_LOCATION'
export const SET_SHOW_EMAIL_PROMPT = 'SET_SHOW_EMAIL_PROMPT'
export const SET_QUERY = 'SET_QUERY'
export const SELECT_FILTER = 'SELECT_FILTER'
export const SET_VIEWER = 'SET_VIEWER'
export const SET_REFETCH_QUERY = 'SET_REFETCH_QUERY'
export const SET_USER = 'SET_USER'
export const SET_LAST_STUDIO_PAGE_ID = 'SET_LAST_STUDIO_PAGE_ID'
