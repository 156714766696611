import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getIcon } from 'components/shared/icons'

import classNames from 'classnames'
import classes from './classes.scss'

const StudioPageButton = ({ studioPage, studioId }) => {
  return (
    <Link
      to={`/studios/${studioId}/${studioPage.id}`}
      className={classNames('kid-btn', classes.studioPageButton, classes[studioPage.buttonIcon])}
    >{getIcon(studioPage.buttonIcon)} {studioPage.buttonName}</Link>
  )
}

StudioPageButton.propTypes = {
  studioPage: PropTypes.object.isRequired,
  studioId: PropTypes.string.isRequired
}

export default StudioPageButton
