import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// redux actions
import { setMessage } from 'actions'

/**
Provides shared action handling for FileActionConfirm components.
*/
const withActionResult = WrappedComponent => {
  const ActionResult = props => {
    const {
      setActiveAction,
      setMessage,
      history,
      refetchQuery,
      goBackOnSuccess
    } = props

    const handleClickCancel = e => {
      e.preventDefault()
      setActiveAction(null)
    }

    const handleSubmitSuccess = () => {
      refetchQuery()
      setActiveAction(null)
      if (history && goBackOnSuccess) {
        history.goBack()
      }
    }

    const handleSubmitFailure = (error, header) => {
      setMessage({
        header: header,
        body: error.message,
        type: 'FAILURE'
      })
    }

    return (
      <WrappedComponent
        {...props}
        onClickCancel={handleClickCancel}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFailure={handleSubmitFailure}
      />
    )
  }

  return ActionResult
}

const mapStateToProps = ({ userData: { refetchQuery } }) => ({ refetchQuery })
const withRedux = connect(mapStateToProps, { setMessage })

export default compose(withRedux, withActionResult)
