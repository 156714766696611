import React from 'react'
import PropTypes from 'prop-types'

// containers
import BackButtonContainer from 'containers/BackButtonContainer'

// components
import UserProfile from 'components/UserProfile'
import LinkToAccount from 'components/LinkToAccount'
import LinkToOps from 'components/LinkToOps'

// styles
import classes from './classes.scss'

const AccountUsersHeader = ({account, isAdmin}) => {
  let linkTo, backButtonText

  if (isAdmin) {
    linkTo = LinkToOps
    backButtonText = 'Ops'
  } else {
    linkTo = LinkToAccount
    backButtonText = 'Account'
  }

  return (
    <div>
      <UserProfile account={account}>
        <BackButtonContainer
          linkTo={linkTo}
          account={account}
          text={backButtonText}
          displayMode='userProfile'
        />
      </UserProfile>
      <div className={classes.pageHeader}>
        <section>
          <h1>
            Projects &amp; Photos
          </h1>
        </section>
        <h6 className='flourish'>
          Campers
        </h6>
      </div>
    </div>
  )
}

AccountUsersHeader.propTypes = {
  account: PropTypes.object.isRequired, // from Apollo
  isAdmin: PropTypes.bool.isRequired
}

export default AccountUsersHeader
