import React from 'react'
import { mancalaAccountUrl } from 'lib/urls'

const LinkToAccount = ({ account, mancalaUrl, children }) => {
  return (
    <a href={mancalaAccountUrl(mancalaUrl, account)} className='kid-btn'>
      {children}
    </a>
  )
}

export default LinkToAccount
