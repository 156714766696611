import { graphql } from 'react-apollo'
import query from 'mutations/UserFile/DeleteUserFileMutation.graphql'

const withDeleteUserFileMutation = graphql(query, {
  props: ({ mutate }) => ({
    deleteUserFile: (id) => mutate({
      variables: { id }
      // optimisticResponse: {
      //   __typename: 'Mutation',
      //   deleteUserFile: {
      //     __typename: 'UserFile',
      //     id
      //   }
      // }
    })
  })
})

export default withDeleteUserFileMutation
