const choices = [
  {id: 'camper', name: 'Camper Project'},
  {id: 'staff', name: 'Staff Project'}
]

const creatorsFilter = (selectedCreators) => {
  const selected = selectedCreators.map(id => {
    return choices.find((status) => id === status.id)
  })

  return { choices, selected }
}

export default creatorsFilter
