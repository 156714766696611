import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// styles
import classes from './skillMediaWrapper.scss'

// components
import SkillMediaViewer from 'components/SkillMediaViewer'
import SkillNextButton from './SkillNextButton'
import SkillPreviousButton from './SkillPreviousButton'

class SkillMediaWrapper extends React.Component {
  state = {
    titleHidden: false
  }

  hideTitle = () => {
    this.setState({
      titleHidden: true
    })
  }

  displayTitle = () => {
    this.setState({
      titleHidden: false
    })
  }

  onPlay = () => {
    this.hideTitle()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.skill.id !== nextProps.skill.id) {
      this.displayTitle()
    }
  }

  render () {
    const { skill, changeSelectedSkill, skills, autoplay } = this.props
    const videoTitle = skill.title ? `${skill.title}` : 'Untitled'
    const hideTitle = (autoplay || this.state.titleHidden)
    const titleClass = classNames(classes.videoTitle, {
      hidden: hideTitle
    })

    return (
      <div className='SkillGroupDetail__videoWrapper'>
        <SkillPreviousButton selectedSkillId={skill.id} changeSelectedSkill={changeSelectedSkill} skills={skills} />
        <SkillNextButton selectedSkillId={skill.id} changeSelectedSkill={changeSelectedSkill} skills={skills} />
        <SkillMediaViewer
          skill={skill}
          autoplay={autoplay}
          onPlay={this.onPlay}
        />
        <h1 className={titleClass}>{videoTitle}</h1>
      </div>
    )
  }
}

SkillMediaWrapper.propTypes = {
  autoplay: PropTypes.bool.isRequired,
  skill: PropTypes.object.isRequired,
  changeSelectedSkill: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired
}

export default SkillMediaWrapper
