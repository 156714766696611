import React from 'react'

// material ui
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'

// themes
import unoTheme from 'themes/unoTheme'
import kidTheme from 'themes/kidTheme'

const KidThemeProvider = props => (
  <ThemeProvider theme={unoTheme}>
    <CssBaseline />
    <ThemeProvider theme={kidTheme}>
      {props.children}
    </ThemeProvider>
  </ThemeProvider>
)

export default KidThemeProvider
