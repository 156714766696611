import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import classes from './classes.scss'

const ActionButton = (props) => {
  const {
    // button appearance
    active,
    disabled,
    label,
    showcase,
    icon: ActionIcon,
    // button action
    onClick,
    href,
    target,
    // children
    children
  } = props

  const actionClass = classNames('mui-btn', classes.action, {
    [`${classes.active}`]: active,
    [`${classes.disabled}`]: disabled,
    [`${classes.showcase}`]: showcase
  })

  /**
  Do nothing if button is disabled
  */
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault()
      return
    }

    if (onClick) { onClick(e) }
  }

  return (
    <div className={classes.actionWrapper}>
      {children}
      <div className={actionClass}>
        <a onClick={handleClick} href={href} target={target}>
          <ActionIcon />
        </a>
      </div>
      {label &&
        <div className={classes.actionLabel}>{label}</div>
      }
    </div>
  )
}

ActionButton.propTypes = {
  // required
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.func.isRequired,
  // optional
  onClick: PropTypes.func,
  showcase: PropTypes.bool,
  label: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string
}

export default ActionButton
