import React from 'react'
import PropTypes from 'prop-types'
import classes from '../classes.scss'
import { mancalaAccountUrl, mancalaAdminOpsUrl } from 'lib/urls'
import { IconBrand } from 'components/shared/icons'

class NotFound extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    mancalaUrl: PropTypes.string
  }

  _homeLink () {
    const { account, mancalaUrl } = this.props
    if (account.user_type === 'AdminUser') {
      return mancalaAdminOpsUrl(mancalaUrl)
    } else {
      return mancalaAccountUrl(mancalaUrl, account)
    }
  }

  _renderButton () {
    const { account, mancalaUrl } = this.props
    if (account && mancalaUrl) {
      const homeLink = this._homeLink()
      return (
        <div>
          <a href={homeLink} className='kid-btn'>Home</a>
        </div>
      )
    } else {
      return null
    }
  }

  render () {
    return (
      <section className={classes.statusWrapper}>
        <div className={classes.brand}><IconBrand /></div>
        <h1>Lost in Space.</h1>
        <p>The page you are looking for has moved or does not exist.</p>
        {this._renderButton()}
        <img src='/assets/uno/images/kid-world/space-404.png' />
      </section>
    )
  }
}

export default NotFound
