import ClientOAuth2 from 'client-oauth2'

import {
  STORE_APP_CONFIG
} from 'actions/actionTypes'

import {
  mancalaAuthorizeUrl,
  clientAuthorizeUrl
} from 'lib/urls'

// Given OAuth config vars, build a URL for the auth web server
const buildAuthRedirectUrl = (appConfig, scopes) => {
  const { oAuthClientId, mancalaUrl, clientUrl } = appConfig
  const authClient = new ClientOAuth2({
    clientId: oAuthClientId,
    authorizationUri: mancalaAuthorizeUrl(mancalaUrl),
    redirectUri: clientAuthorizeUrl(clientUrl),
    scopes: scopes
  })

  return authClient.code.getUri()
}

// merge existing app config with the new config
const appConfig = (state = {}, action) => {
  switch (action.type) {
    case STORE_APP_CONFIG:
      const authorizationRedirectUrl = buildAuthRedirectUrl(action.appConfig, [])
      const staffAuthorizationRedirectUrl = buildAuthRedirectUrl(action.appConfig, ['staff'])
      const appConfig = Object.assign(state, action.appConfig)
      return Object.assign({}, state, { appConfig, authorizationRedirectUrl, staffAuthorizationRedirectUrl })
    default:
      return state
  }
}

export default appConfig
