import React from 'react'
import PropTypes from 'prop-types'

const withAppInitialization = (WrappedComponent) => {
  class AppInitialization extends React.Component {
    state = {
      error: null,
      success: false
    }

    UNSAFE_componentWillMount () {
      const {
        appConfig, // from Apollo query
        campLocations, // from Apollo query
        storeLocations, // from Redux actions
        storeAppConfig // from Redux actions
      } = this.props

      storeAppConfig(appConfig)
      storeLocations(campLocations)
      this.setState({ success: true })
    }

    render () {
      const { error, success } = this.state

      return (
        <WrappedComponent
          success={success}
          error={error}
        />
      )
    }
  }

  AppInitialization.propTypes = {
    // from Redux actions
    storeAppConfig: PropTypes.func.isRequired,
    storeLocations: PropTypes.func.isRequired
  }

  return AppInitialization
}

export default withAppInitialization
