import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// redux
import { setRefetchQuery } from 'actions'

/**
Save the refetch query to Redux store so that it can be called when another mutation
causes the query results to become stale (for example, deleting a file from a list view)
*/
export const withRefetchQuery = (WrappedComponent) => {
  const RefetchQuery = (props) => {
    const {
      data, // from Apollo
      setRefetchQuery // from redux actions
    } = props

    const { refetch } = data

    // Only save refetch query whose name starts with "bound", otherwise refetch does not work.
    // TODO: What is the correct way to handle this?
    if (refetch.name.indexOf('bound') === 0) {
      setRefetchQuery(refetch)
    }

    return <WrappedComponent {...props} />
  }

  return RefetchQuery
}

// get Redux actions
const mapStateToProps = () => ({})
const withRedux = connect(mapStateToProps, { setRefetchQuery })

export default compose(
  withRedux,
  withRefetchQuery
)
