import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { IconX as IconClose } from 'components/shared/icons'

/**
@private
Render the message body. If the body is a react component, render the component.
If it's a string, render a string.
*/
const MessageBody = ({body}) => {
  if (typeof body === 'function') {
    const BodyComponent = body
    return <BodyComponent />
  }

  return <p>{body}</p>
}

const Message = (props) => {
  const { body, header, type, onClose } = props
  const msgClass = classNames('c-msg-center__msg', 'msg--is-active', {
    'msg--failure': type === 'FAILURE',
    'msg--success': type === 'SUCCESS'
  })

  return (
    <div className='c-msg-center'>
      <div className={msgClass}>
        <div className='group'>
          <a onClick={onClose} className='o-close'>
            <IconClose />
          </a>
          <h3>{header}</h3>
          <MessageBody body={body} />
        </div>
      </div>
    </div>
  )
}

Message.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  type: PropTypes.oneOf([
    'FAILURE',
    'SUCCESS'
  ]).isRequired,
  onClose: PropTypes.func.isRequired
}

export default Message
