import { compose } from 'redux'

// presentational component
import AdminSettings from 'components/AdminSettings'

// composable higher-order components
import withApolloQuery from './withApolloQuery'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withApolloData from './withApolloData'
import withRefetchQuery from 'containers/withRefetchQuery'
import withRedux from './withRedux'
import withSettingsLogic from './withSettingsLogic'

export default compose(
  withApolloQuery, // add Apollo query handler to a component
  withRefetchQuery, // save the refresh query to Redux store
  withApolloErrors, // handle any errors resulting from Apollo query (auth, etc)
  withApolloData, // parse raw Apollo data and pass on as props
  withRedux, // get Redux state and actions for changing settings
  withSettingsLogic // add handlers to set cookies when values change
)(AdminSettings)
