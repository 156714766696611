import { graphql } from 'react-apollo'
import query from 'mutations/File/UpdateFileMutation.graphql'

const withUpdateFileMutation = graphql(query, {
  props: ({ mutate }) => ({
    submit: (input) => mutate({
      variables: { input },
      optimisticResponse: {
        __typename: 'Mutation',
        updateFile: {
          __typename: 'UpdateFilePayload',
          clientMutationId: 0,
          file: {
            __typename: 'File',
            id: input.id,
            title: input.title
          }
        }
      }
    })
  })
})

export default withUpdateFileMutation
