import React from 'react'

// containers
import BackButtonContainer from 'containers/BackButtonContainer'

// components
import LegacyLayout from 'components/LegacyLayout'
import UserProfile from 'components/UserProfile'
import LinkToHistory from 'components/LinkToHistory'
import FileMeta from 'components/FileMeta'
import FileTags from 'components/FileTags'
import AccountUserFileHeader from 'components/AccountUserFileHeader'
import FileTitle from 'components/FileTitle'
import FileCollaborators from 'components/FileCollaborators'
import PrivacyPolicy from 'components/shared/PrivacyPolicy'

// file media components
import FileMediaWrapper from 'components/FileMedia/FileMediaWrapper'
import FileViewerWrapper from 'components/FileMedia/FileViewerWrapper'
import FileViewer from 'components/FileViewer'
import FileDetailModerationBadgeContainer from 'containers/FileModerationBadgeContainer/Detail'

// action buttons
import FileActions from 'components/FileActions'
import EditButtonContainer from 'containers/FileActionButtons/EditButtonContainer'
import AdminDeleteButtonContainer from 'containers/FileActionButtons/AdminDeleteButtonContainer'
import AdminMoreActionsButtonContainer from 'containers/FileActionButtons/AdminMoreActionsButtonContainer'
import AdminDownloadButton from 'components/FileActionButtons/AdminDownloadButton'

// action confirm
import EditConfirmContainer from 'containers/FileActionConfirm/EditConfirmContainer'
import AdminReportConfirmContainer from 'containers/FileActionConfirm/AdminReportConfirmContainer'
import AdminEscalateConfirmContainer from 'containers/FileActionConfirm/AdminEscalateConfirmContainer'
import AdminDeescalateConfirmContainer from 'containers/FileActionConfirm/AdminDeescalateConfirmContainer'
import AdminReportedSuccessConfirmContainer from 'containers/FileActionConfirm/AdminReportedSuccessConfirmContainer'
import AdminShowcaseDownloadConfirmContainer from 'containers/FileActionConfirm/AdminShowcaseDownloadConfirmContainer'
import AdminApproveConfirmContainer from 'containers/FileActionConfirm/AdminApproveConfirmContainer'
import AdminUnapproveConfirmContainer from 'containers/FileActionConfirm/AdminUnapproveConfirmContainer'
import AdminMoreActionsConfirmContainer from 'containers/FileActionConfirm/AdminMoreActionsConfirmContainer'
import AdminDeleteConfirmContainer from 'containers/FileActionConfirm/AdminDeleteConfirmContainer'
import AdminDeletedSuccessConfirmContainer from 'containers/FileActionConfirm/AdminDeletedSuccessConfirmContainer'

// admin components
import AdminFileShowcasePanel from 'components/AdminFileShowcasePanel'

// moderation
import { isUnreviewed } from 'lib/moderation/status'

/**
Full-size detail view for a media file being viewed by an admin account.
*/
const AdminFileDetail = props => {
  const {
    account,
    user,
    file,
    userFile,
    fileLocationName,
    activeAction,
    setActiveAction,
    setMessage,
    history
  } = props

  return (
    <LegacyLayout>
      <UserProfile account={account} user={user}>
        <BackButtonContainer
          linkTo={LinkToHistory}
          text='Back'
          displayMode='userProfile'
        />
      </UserProfile>
      <AccountUserFileHeader user={user} file={file} />

      <FileMediaWrapper>
        <FileViewerWrapper file={file}>
          <FileViewer file={file} />
          {!isUnreviewed(file) && (
            <FileDetailModerationBadgeContainer
              status={file.moderation_status}
            />
          )}
        </FileViewerWrapper>
      </FileMediaWrapper>

      <FileMeta>
        <FileActions setMessage={setMessage} file={file}>
          <AdminDeleteButtonContainer
            userFile={userFile}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
          <EditButtonContainer
            file={file}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
          <AdminDownloadButton
            file={file}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
          <AdminMoreActionsButtonContainer
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          >
            <AdminMoreActionsConfirmContainer
              file={file}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            />
          </AdminMoreActionsButtonContainer>
        </FileActions>
        <FileTitle file={file} />
        <FileCollaborators file={file} />
        <FileTags file={file} />

        <EditConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminShowcaseDownloadConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminReportConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminEscalateConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminDeescalateConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminApproveConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
          history={history}
          goBackOnSuccess
        />

        <AdminUnapproveConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminDeleteConfirmContainer
          user={user}
          userFile={userFile}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminReportedSuccessConfirmContainer
          user={user}
          activeAction={activeAction}
          history={history}
        />

        <AdminDeletedSuccessConfirmContainer
          user={user}
          activeAction={activeAction}
          history={history}
        />
      </FileMeta>

      {file.submitted && (
        <AdminFileShowcasePanel
          file={file}
          fileLocationName={fileLocationName}
          setMessage={setMessage}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />
      )}

      <PrivacyPolicy />
    </LegacyLayout>
  )
}

export default AdminFileDetail
