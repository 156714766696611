import React from 'react'
import classes from './classes.scss'

const AdminHeader = (props) => {
  const { header, children } = props

  return (
    <div className={classes.pageHeader}>
      <section>
        <h1>{header}</h1>
        {children}
      </section>
    </div>
  )
}

export default AdminHeader
