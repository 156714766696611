import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// containers
import withEmailFormLogic from './withEmailFormLogic'

// presentational component
import PreseasonEmailPrompt from 'components/PreseasonEmailPrompt'

const withPreseasonEmailFormLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      postEmail: PropTypes.func.isRequired,
      onSuccess: PropTypes.func.isRequired
    }

    /**
    Preseason-specific email form submission handling:
    * Always subscribe (there's no opt-out checkbox)
    * On successful response, toggle the video/email view
    */
    handleSubmit = (e) => {
      e.preventDefault()
      const { postEmail, onSuccess } = this.props
      const pool = 'preseason landing'
      const updates = true
      postEmail({pool, updates, onSuccess})
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          // presentation
          headerText={'Want to hear more?'}
          subheaderText={'We will only send email about camp news and events.'}
          buttonText={'Keep me in the loop'}
          showLogin={false}
          showUpdates={false}
          // actions
          onSubmit={this.handleSubmit}
        />
      )
    }
  }
}

export default compose(
  withEmailFormLogic,
  withPreseasonEmailFormLogic
)(PreseasonEmailPrompt)
