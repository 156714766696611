import React from 'react'
import classes from './classes.scss'

export default class Modal extends React.Component {
  render () {
    const { children, setWrapperRef } = this.props

    return (
      <div className={classes.modalWrapper}>
        <div ref={setWrapperRef} className={classes.modal}>
          {children}
        </div>
      </div>
    )
  }
}
