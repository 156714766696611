import React from 'react'
import PropTypes from 'prop-types'

// presentational components
import { Loading } from 'components/shared'

/**
HOC to handle raw Apollo data response
*/
const withApolloData = (WrappedComponent) => {
  const ApolloData = (props) => {
    const {
      setUser, // from Redux actions
      selectUserFile, // from Redux actions
      data: { viewer, studios, programArcs } // from Apollo
    } = props

    // TODO: better way to differentiate between initial load and subsequent updates?
    if (!viewer) { return <Loading /> }

    const { user } = viewer
    selectUserFile(null)
    setUser(user)

    return <WrappedComponent
      {...props}
      user={user}
      studio={studios[0]}
      programArc={programArcs[0]}
    />
  }

  ApolloData.propTypes = {
    setUser: PropTypes.func.isRequired,
    selectUserFile: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  }

  return ApolloData
}

export default withApolloData
