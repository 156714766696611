import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// containers
import { withRouter } from 'react-router-dom'

// helpers
import { userFilesPath } from 'lib/urls'

// presentational components
import AdminDeletedSuccessConfirm from 'components/FileActionConfirm/AdminDeletedSuccessConfirm'

/**
Displays a modal dialog after a file has been reported (flagged as inappropriate).
The only available action is reloading the timeline.
*/
const withAdminDeletedAction = (WrappedComponent) => {
  const AdminDeletedAction = (props) => {
    const {
      user,
      history, // from withRouter
      activeAction // from withActiveFileAction
    } = props

    const handleClickContinue = () => {
      const path = userFilesPath(user)
      history.replace(path)
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'deleted'}
        onClickContinue={handleClickContinue}
      />
    )
  }

  AdminDeletedAction.propTypes = {
    user: PropTypes.object.isRequired
  }

  return AdminDeletedAction
}

export default compose(
  withRouter,
  withAdminDeletedAction
)(AdminDeletedSuccessConfirm)
