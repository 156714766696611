import React from 'react'
import StudioHeader from 'components/StudioHeader'

const SkillGroupDetailMast = ({ head, headSet }) => {
  return (
    <header>
      <StudioHeader head={head} headSet={headSet} />
    </header>
  )
}

export default SkillGroupDetailMast
