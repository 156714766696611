import React from 'react'
import PropTypes from 'prop-types'

// presentational component
import MediaThumbnail from 'components/MediaThumbnail'

const withThumbnailLogic = (WrappedComponent) => {
  class MediaThumbnailLogic extends React.Component {
    state = {
      thumbnailUrl: ''
    }

    componentDidMount () {
      const { thumbnailSmall, defaultThumbnailSmall } = this.props

      this.setState({
        thumbnailUrl: thumbnailSmall || defaultThumbnailSmall
      })
    }

    handleImageError = () => {
      const { thumbnailSmall, altThumbnailSmall, defaultThumbnailSmall } = this.props
      if (altThumbnailSmall && this.state.thumbnailUrl === thumbnailSmall) {
        this.setState({ thumbnailUrl: altThumbnailSmall })
      } else {
        this.setState({ thumbnailUrl: defaultThumbnailSmall })
      }
    }

    render () {
      const { thumbnailUrl } = this.state

      return (
        <WrappedComponent
          {...this.props}
          thumbnailUrl={thumbnailUrl}
          onImageError={this.handleImageError}
        />
      )
    }
  }

  MediaThumbnailLogic.propTypes = {
    thumbnailSmall: PropTypes.string.isRequired,
    defaultThumbnailSmall: PropTypes.string.isRequired,
    altThumbnailSmall: PropTypes.string
  }

  return MediaThumbnailLogic
}

export default withThumbnailLogic(MediaThumbnail)
