import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import classNames from 'classnames'

// components
import { IconLeftArrow as IconBack } from 'components/shared/icons'

const BackButton = (props) => {
  const {
    linkTo: LinkTo,
    text,
    displayMode
  } = props
  const backButtonClass = classNames(classes.backButton, classes[displayMode])

  return (
    <div className={backButtonClass}>
      <LinkTo {...props}>
        <IconBack />
        <span> {text}</span>
      </LinkTo>
    </div>
  )
}

BackButton.propTypes = {
  linkTo: PropTypes.func.isRequired,
  user: PropTypes.object,
  account: PropTypes.object,
  mancalaUrl: PropTypes.string.isRequired
}

export default BackButton
