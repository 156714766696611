exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---detailLink---1NCj4 {\n  display: inline-block; }\n", ""]);

// exports
exports.locals = {
	"detailLink": "classes---detailLink---1NCj4"
};