import { compose } from 'redux'

// composable higher-order components
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withApolloData from './withApolloData'
import withApolloQuery from './withApolloQuery'
import withReduxForApollo from './withReduxForApollo'
import withRedux from './withRedux'
import withSetupLogic from './withSetupLogic'

// presentational component
import StudioSetup from 'components/StudioSetup'

export default compose(
  withReduxForApollo,
  withApolloQuery,
  withApolloErrors,
  withApolloData,
  withRedux, // get any Redux state required by the presentational component
  withSetupLogic
)(StudioSetup)
