import { connect } from 'react-redux'

// redux actions
import { setUser, selectUserFile } from 'actions'

/**
Get raw query from Redux state and transform into props for Apollo query
*/
const mapStateToApolloProps = (state) => {
  // nested destructuring
  const {
    studios: studioIds,
    activities: activityIds,
    sort: orderBy,
    page
  } = state.query

  const { week, sessionType } = state.appSettings

  return { week, sessionType, studioIds, activityIds, page, orderBy }
}

/**
HOC to pass relevant Redux state as props to a wrapped component
*/
const withReduxForApollo = connect(mapStateToApolloProps, { setUser, selectUserFile })

export default withReduxForApollo
