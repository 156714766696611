import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classes from './classes.scss'
import FileDownloadInstructions from './FileDownloadInstructions'

/**
Wrapper around the media content viewer displayed on a file detail page.
*/
const FileViewerWrapper = ({file, children}) => {
  const {
    created_at: createdAt,
    media_type: mediaType
  } = file

  return (
    <div className={classes.media}>
      <div>
        {children}
        <span className={classes.time}>
          {moment(createdAt).fromNow()}
        </span>
        <FileDownloadInstructions mediaType={mediaType} />
      </div>
    </div>
  )
}

FileViewerWrapper.propTypes = {
  file: PropTypes.object.isRequired
}

export default FileViewerWrapper
