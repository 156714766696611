import React from 'react'
import PropTypes from 'prop-types'
import { IconRightCarat } from 'components/shared/icons'
import classNames from 'classnames'
import classes from './skillMediaNav.scss'

class SkillNextButton extends React.Component {
  static propTypes = {
    changeSelectedSkill: PropTypes.func.isRequired,
    selectedSkillId: PropTypes.string.isRequired,
    skills: PropTypes.array.isRequired
  }

  isLastSkill () {
    const {selectedSkillId, skills} = this.props
    return skills[skills.length - 1].id === selectedSkillId
  }

  getNextSkillId () {
    const {selectedSkillId, skills} = this.props
    const index = skills.map(skill => skill.id).indexOf(selectedSkillId) + 1
    return index > 0 && index < skills.length ? skills[index].id : selectedSkillId
  }

  render () {
    const { changeSelectedSkill } = this.props
    const nextId = this.getNextSkillId()
    let nextClass = classNames(classes.next, {
      [`${classes.disabled}`]: this.isLastSkill()
    })

    return (
      <div className={nextClass} onClick={() => changeSelectedSkill(nextId)}><IconRightCarat /></div>
    )
  }
}
export default SkillNextButton
