import React from 'react'
import PropTypes from 'prop-types'
import { IconBrand, IconSettings } from 'components/shared/icons'
import { Loading } from 'components/shared'
import { mancalaHomeUrl } from 'lib/urls'

const getDisplayName = (account) => {
  const { last_name: lastName, first_name: firstName, email } = account
  return lastName ? `${firstName} ${lastName}` : email
}

export default class UserProfile extends React.Component {
  static propTypes = {
    account: PropTypes.object.isRequired, // from Relay
    mancalaUrl: PropTypes.string.isRequired // from Redux
  }

  render () {
    const { account, mancalaUrl, children } = this.props
    const homeUrl = mancalaHomeUrl(mancalaUrl, account)
    const displayName = getDisplayName(account)

    if (!account) {
      return <Loading />
    }

    return (
      <section className='UserProfileMasthead'>
        <div className='UserProfileMasthead__brand'><IconBrand /></div>
        <nav className='UserProfileMasthead__utilityNav'>
          {/* BackButton, etc. */}
          {children}
          <ul className='UserProfileMasthead__utilityLinks'>
            <li className='UserProfileMasthead__utilityLink'>
              Hi, <a href={homeUrl}>{displayName}</a>
              <IconSettings />
            </li>
          </ul>
        </nav>
      </section>
    )
  }
}
