import React from 'react'
import Honeybadger from 'honeybadger-js'
import { Link } from 'react-router-dom'

// lib
import { mancalaLogoutUrl } from 'lib/urls'

// styles
import classes from './classes.scss'

export default class WrongAccountError extends React.Component {
  componentDidMount () {
    Honeybadger.notify(this.props.error)
  }

  render () {
    const { error, quiet, mancalaUrl, accountEmail, accountId, userId } = this.props
    const logoutUrl = mancalaLogoutUrl(mancalaUrl)

    if (!quiet) {
      console.log('ServerError:', error)
    }

    return (
      <section className={classes.statusWrapper}>
        <h1>Please log in again.</h1>
        <p>
          This camper is associated with a different account.
          You can <Link to='/campers'>view a list of campers</Link> in your current account,
          or log out and then log in again with the appropriate account.
        </p>
        <img src='/assets/uno/images/kid-world/re-authenticate-600.png' />
        <div>
          <a href={logoutUrl} className='kid-btn kid-btn--primary'>LOG OUT</a>
        </div>
        <p className={classes.afterward}>
          <muted>
            Error details: account ID {accountId}, user ID {userId}, {accountEmail}
          </muted>
        </p>
      </section>
    )
  }
}
