import React from 'react'
import UserHeader from 'components/UserHeader'
import UserProfileImage from 'components/UserProfileImage'

const StudioUserHeader = ({user, subheader}) => (
  <UserHeader user={user} subheader={subheader}>
    <UserProfileImage user={user} />
  </UserHeader>
)

export default StudioUserHeader
