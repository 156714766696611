import React from 'react'
import Input from 'muicss/lib/react/input'
import OutreachPromptWrapper from 'components/OutreachPromptWrapper'
import OutreachStateList from 'components/OutreachStateList'

const OutreachAddressPrompt = (props) => {
  const {
    // presentation
    headerText, // 'Make a light-up pin.'
    subheaderText, // 'Enter an email to get started.'
    footerText, // Kit will be sent while supplies lasts.
    buttonText, // 'Make a light-up pin.'
    cancelButtonText, // Nevermind, I don't want a kit
    // actions
    onCancel,
    onSubmit,
    // state
    firstName,
    lastName,
    streetAddress,
    extendedAddress,
    city,
    state,
    zip,
    phoneNumber,
    email,
    // actions
    handleChangeValueFunction,
    handleChangeState
  } = props

  const inputWrapperClass = 'mui-textfield'

  return (
    <OutreachPromptWrapper
      headerText={headerText}
      subheaderText={subheaderText}
      footerText={footerText}
      buttonText={buttonText}
      cancelButtonText={cancelButtonText}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <legend>Keep me in the loop</legend>
      <div className={inputWrapperClass}>
        <Input
          required
          label='First Name:'
          name='firstName'
          placeholder='First Name'
          value={firstName}
          onChange={handleChangeValueFunction('firstName')}
        />
      </div>
      <div className={inputWrapperClass}>
        <Input
          required
          label='Last Name:'
          name='lastName'
          placeholder='Last Name'
          value={lastName}
          onChange={handleChangeValueFunction('lastName')}
        />
      </div>
      <div className={inputWrapperClass}>
        <Input
          required
          label='Address Line 1:'
          name='streetAddress'
          value={streetAddress}
          placeholder='Street Address'
          onChange={handleChangeValueFunction('streetAddress')}
        />
      </div>
      <div className={inputWrapperClass}>
        <Input
          label='Address Line 2:'
          name='extendedAddress'
          value={extendedAddress}
          placeholder='Apartment, Suite, Building, etc.'
          onChange={handleChangeValueFunction('extendedAddress')}
        />
      </div>
      <div className={inputWrapperClass}>
        <Input
          required
          label='City:'
          name='city'
          value={city}
          placeholder='City'
          onChange={handleChangeValueFunction('city')}
        />
      </div>
      <OutreachStateList
        onChange={handleChangeState}
        selectedLocation={state}
      />
      <div className={inputWrapperClass}>
        <Input
          required
          label='Zip Code:'
          name='zip'
          value={zip}
          placeholder='Zip Code'
          onChange={handleChangeValueFunction('zip')}
        />
      </div>
      <div className={inputWrapperClass}>
        <Input
          required
          label='Phone Number:'
          name='phoneNumber'
          value={phoneNumber}
          placeholder='Phone Number'
          onChange={handleChangeValueFunction('phoneNumber')}
        />
      </div>
      <div className={inputWrapperClass}>
        <Input
          required
          label='Email Address:'
          name='email'
          type='email'
          value={email}
          placeholder='Email'
          onChange={handleChangeValueFunction('email')}
        />
      </div>
    </OutreachPromptWrapper>
  )
}

export default OutreachAddressPrompt
