import { graphql } from 'react-apollo'
import query from './query.graphql'

const withApolloQuery = graphql(query, {
  options: (props) => {
    const { studioId, week, sessionType, pageId } = props

    return {
      variables: { studioId, week, sessionType, pageId }
    }
  }
})

export default withApolloQuery
