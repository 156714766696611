import React from 'react'

/**
Higher-order component which saves last skillGroupType to redux in
order to determine where to go back to.
*/
const withLastStudioPageId = (WrappedComponent) => {
  return class extends React.Component {
    UNSAFE_componentWillMount () {
      const { setLastStudioPageId, pageId } = this.props
      setLastStudioPageId(pageId)
    }

    getDerivedStateFromProps (nextProps) {
      const { setLastStudioPageId, pageId } = this.props
      if (pageId !== nextProps.pageId) {
        setLastStudioPageId(nextProps.pageId)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default withLastStudioPageId
