import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

// components
import FileFilterAttribute from 'components/FileFilters/FileFilterAttribute'

const withFilterAttributeChoices = (WrappedComponent) => {
  const FilterAttributeChoices = (props) => {
    const {
      attribute,
      fileFilters,
      filtersVisible,
      selectedFilter
    } = props

    const choices = fileFilters[attribute].choices
    const selected = fileFilters[attribute].selected
    const isPrimary = attribute !== 'activities'

    // handle "activities" special case
    const isSelectedAttribute = (
      attribute === 'activities'
        ? selectedFilter === 'studios'
        : selectedFilter === attribute
    )

    const isVisible = filtersVisible && isSelectedAttribute

    return (
      <WrappedComponent
        {...props}
        choices={choices}
        selected={selected}
        isPrimary={isPrimary}
        isVisible={isVisible}
      />
    )
  }

  FilterAttributeChoices.propTypes = {
    attribute: PropTypes.string.isRequired,
    fileFilters: PropTypes.object.isRequired
  }

  return FilterAttributeChoices
}

const mapStateToProps = ({fileFilters, fileFilters: { filtersVisible, selectedFilter }}) => (
  {fileFilters, filtersVisible, selectedFilter}
)
const withRedux = connect(mapStateToProps)

export default compose(
  withRedux,
  withFilterAttributeChoices
)(FileFilterAttribute)
