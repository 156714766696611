import React from 'react'
import classes from './classes.scss'
import PreseasonVideoPlayerContainer from 'containers/VideoPlayerContainer/PreseasonVideoPlayerContainer'

/**
Displays a location-specific promotional video for preseason events
*/
const PreseasonVideo = (props) => {
  const { campLocation, onEnd } = props

  if (!campLocation) {
    return null
  }

  return (
    <div className={classes.wrapper}>
      <PreseasonVideoPlayerContainer
        campLocation={campLocation}
        onEnd={onEnd}
      />
    </div>
  )
}

export default PreseasonVideo
