import React from 'react'

// components
import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'
import FilterCheckbox from 'components/FilterCheckbox'
import classes from './classes.scss'

/**
Displays all currently selected choices across all filter types
(e.g. moderation statuses, studios, activities)
*/
const FileFilterSelection = (props) => {
  const { selected } = props

  return (
    <ul className={classes.filtersSecondary}>
      <TransitionGroup>
        {selected.map(({value, attribute}) => (
          <MaxHeightTransition key={value.id}>
            <FilterCheckbox
              key={value.id}
              value={value}
              attribute={attribute}
              isSelected
            />
          </MaxHeightTransition>
        ))}
      </TransitionGroup>
    </ul>
  )
}

export default FileFilterSelection
