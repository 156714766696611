// es6
import '@babel/polyfill'

// react
import React from 'react'
import ReactDOM from 'react-dom'

// redux
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'lib/redux'

// apollo
import { ApolloProvider } from 'react-apollo'
import { buildApolloClient } from 'lib/apollo'

// ios
import FastClick from 'fastclick'

// containers
import MessageContainer from 'containers/MessageContainer'
import AppContainer from 'containers/AppContainer'

// build apollo client
const accessToken = store.getState().appConfig.accessToken
const client = buildApolloClient(accessToken)

FastClick.attach(document.body)

ReactDOM.render(
  <ReduxProvider store={store}>
    <ApolloProvider client={client}>
      <div>
        <MessageContainer />
        <AppContainer />
      </div>
    </ApolloProvider>
  </ReduxProvider>,
  document.getElementById('root')
)
