import React from 'react'
import { compose } from 'redux'

// mutations
import withMutation from 'mutations/withUpdateFileMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import EditConfirm from 'components/FileActionConfirm/EditConfirm'

const withFileEditState = (WrappedComponent) => {
  class FileEditState extends React.Component {
    state = {
      title: this.props.file.title
    }

    /**
    If another file is selected, update the form field with the title of the new file
    */
    UNSAFE_componentWillReceiveProps (nextProps) {
      const { title } = nextProps.file

      if (title !== this.state.title) {
        this.setState({
          title: title
        })
      }
    }

    handleChangeTitle = (event) => {
      this.setState({
        title: event.target.value
      })
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          title={this.state.title}
          onChangeTitle={this.handleChangeTitle}
        />
      )
    }
  }

  return FileEditState
}

const withFileEditAction = (WrappedComponent) => {
  const FileEditAction = (props) => {
    const {
      file, // from Apollo query
      title, // from withFileEditState
      activeAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submit // from Apollo mutation
    } = props

    const handleClickSave = () => {
      const input = {
        id: file.id,
        title: title,
        clientMutationId: '0'
      }

      submit(input)
        .then(onSubmitSuccess)
        .catch(error => onSubmitFailure(error, 'Error changing title'))
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'edit'}
        onClickSave={handleClickSave}
        onClickCancel={onClickCancel}
      />
    )
  }

  return FileEditAction
}

export default compose(
  withMutation,
  withFileEditState,
  withActionResult,
  withFileEditAction
)(EditConfirm)
