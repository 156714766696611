import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const AdminApproveConfirm = (props) => {
  const {
    visible,
    onClickApprove,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            header='Approving content will make it viewable in parent account and remove it from the review queue.'
            subheader={null}
          >
            <ConfirmationButton
              type='positive'
              text='Approve as reviewed'
              action={onClickApprove}
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

AdminApproveConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickApprove: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminApproveConfirm
