import React from 'react'
import PropTypes from 'prop-types'
import { compact, uniq } from 'lodash/array'
import classes from './classes.scss'
import { findStudio, findActivity } from 'lib/studios'
import { findLocation } from 'lib/campLocations'

export default class FileTags extends React.Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    campLocations: PropTypes.array // from redux
  }

  // return an array of <li> tags, separated by commas
  renderTags () {
    const { file, campLocations } = this.props

    const studio = findStudio(file.studio) || {}
    const activity = findActivity(file.activity) || {}
    const location = findLocation(campLocations, file.location) || {}
    const tags = uniq(compact([location.name, studio.name, activity.name]))

    return tags.map((tag, index) => {
      const comma = (index < tags.length - 1 ? ',' : null)
      return <li className={classes.tag} key={tag}>{tag}{comma}</li>
    })
  }

  render () {
    return (
      <ul className={classes.mediaTags}>
        {this.renderTags()}
      </ul>
    )
  }
}
