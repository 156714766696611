import React from 'react'
import Select from './Select'

const StudioSelect = ({studios, studioId, sessionType, onChange}) => {
  const sessionStudios = studios.filter(studio => studio.sessionTypes.includes(sessionType))
  return (
    <Select value={studioId} onChange={onChange}>
      { sessionStudios.map(studio => (
        <option key={studio.id} value={studio.id}>
          { `${studio.title} (${studio.id})` }
        </option>
      )) }
    </Select>
  )
}

export default StudioSelect
