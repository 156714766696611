import React from 'react'
import PropTypes from 'prop-types'

// lib
import windowProxy from 'lib/window'
import { userFilesPath, mancalaAdminOpsUrl } from 'lib/urls'

const withRedirectLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      isUnoNative: PropTypes.bool.isRequired, // from Redux store
      mancalaUrl: PropTypes.string.isRequired, // from Redux store
      account: PropTypes.object.isRequired, // from Apollo
      week: PropTypes.number, // from redux via cookie
      campStudioId: PropTypes.string, // from redux via cookie
      campLocationId: PropTypes.string, // from redux via cookie
      history: PropTypes.object // from redux via withRouter
    }

    state = {
      error: null
    }

    _unoNativeRedirect = () => {
      const { campStudioId, campLocationId, week, history, user } = this.props

      if (campStudioId && campLocationId && week) {
        if (user) {
          history.push(`/studios/${campStudioId}/campers/${user.id}`)
        } else {
          history.push(`/studios/${campStudioId}`)
        }
      } else {
        history.push('/studios/setup')
      }
    }

    // see also containers/AccountUsersContainer/withRedirectLogic.js
    _parentAccountRedirect = () => {
      const { history, account } = this.props
      const accountUsers = account.account_users
      const firstUser = accountUsers.edges[0].node.user
      const path = userFilesPath(firstUser)

      history.push(path)
    }

    _adminAccountRedirect = () => {
      const { mancalaUrl } = this.props
      windowProxy.setLocation({href: mancalaAdminOpsUrl(mancalaUrl)})
    }

    componentDidMount () {
      const { isUnoNative, account } = this.props
      if (isUnoNative) {
        this._unoNativeRedirect()
      } else if (account.user_type === 'AdminUser') {
        this._adminAccountRedirect()
      } else if (account.account_users.edges.length > 0) {
        this._parentAccountRedirect()
      } else {
        this.setState({error: new Error("Can't determine redirect")})
      }
    }

    render () {
      return (
        <WrappedComponent error={this.state.error} />
      )
    }
  }
}

export default withRedirectLogic
