import { compose } from 'redux'

// presentational component
import AdminHome from 'components/AdminHome'

// composable higher-order components
import withReduxForRouter from './withReduxForRouter'
import withStaffConfig from './withStaffConfig'
import withRouterData from './withRouterData'
import withReduxForApollo from './withReduxForApollo'
import withApolloQuery from './withApolloQuery'
import withApolloErrors from 'containers/RoutesContainer/withApolloErrors'
import withApolloData from './withApolloData'
import withRefetchQuery from 'containers/withRefetchQuery'
import withCampLocation from './withCampLocation'
import withRedux from './withRedux'

export default compose(
  withStaffConfig, // detect staff config params and redirect if necessary
  withReduxForRouter, // get redux actions
  withRouterData, // parse raw params from router and pass on as relevant props
  withReduxForApollo, // get any Redux state required by Apollo query (filtering, etc)
  withApolloQuery, // add Apollo query handler to a component
  withRefetchQuery, // save the refresh query to Redux store
  withApolloErrors, // handle any errors resulting from Apollo query (auth, etc)
  withApolloData, // parse raw Apollo data and pass on as props
  withRedux, // get any Redux state required by the presentational component
  withCampLocation // handle transformation of locationId to location data
)(AdminHome)
