import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import classNames from 'classnames'
import { userProfilePhotoUrl } from 'lib/urls'

const UserProfileImage = ({user, className}) => {
  const photoUrl = userProfilePhotoUrl(user)
  const userImageClass = classNames(classes.userImage, className)

  return (
    <span className={userImageClass}>
      <img src={photoUrl} />
    </span>
  )
}

UserProfileImage.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserProfileImage
