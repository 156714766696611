import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

// components
import StudioOption from './StudioOption'

const StudiosList = (props) => {
  const { studios, selectedStudioId, onChange } = props

  return (
    <div className={classes.studioList}>
      {studios.map(studio => (
        <StudioOption
          key={studio.id}
          studioId={studio.id}
          studioIcon={studio.studioSetupIcon}
          onStudioChange={onChange}
          selectedStudioId={selectedStudioId}
        />
      ))}
    </div>
  )
}

StudiosList.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedStudioId: PropTypes.string,
  studios: PropTypes.array.isRequired
}

export default StudiosList
