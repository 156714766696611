import { graphql } from 'react-apollo'
import query from './mutation.graphql'

const withTranscodeResourceVideosMutation = graphql(query, {
  props: ({ mutate }) => ({
    transcodeResourceVideos: (input) => mutate({
      variables: { input }
    })
  })
})

export default withTranscodeResourceVideosMutation
