import { connect } from 'react-redux'
import { setCampStudio, setCampLocation } from 'actions'

// Fetch any Redux state required by setup logic or presentational component
const mapStateToProps = (state) => {
  const {
    campLocations: locations,
    appSettings: { isKiosk, isPreseason, campLocationId, sessionType },
    userData: { user }
  } = state

  const userId = user ? user.id : null

  return { locations, isKiosk, isPreseason, campLocationId, userId, sessionType }
}

const withRedux = connect(mapStateToProps, {setCampStudio, setCampLocation})

export default withRedux
