import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import Form from 'muicss/lib/react/form'
import Input from 'muicss/lib/react/input'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const EditConfirm = (props) => {
  const {
    visible,
    title,
    onChangeTitle,
    onClickSave,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            header='Edit Title'
          >
            <Form>
              <Input
                value={title}
                onChange={onChangeTitle}
              />
            </Form>
            <ConfirmationButton
              type='positive'
              text='Save'
              action={onClickSave}
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

EditConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default EditConfirm
