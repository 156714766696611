exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---comingSoonPreview---DNAfp {\n  font-size: 32px;\n  line-height: 1.3;\n  text-transform: uppercase;\n  font-weight: 700;\n  text-align: center;\n  margin: 20px auto 15px;\n  max-width: 200px; }\n\n.classes---reviewInfo---19nXX {\n  font-style: italic;\n  font-size: 14px;\n  line-height: 1.25;\n  margin: 15px 10px; }\n", ""]);

// exports
exports.locals = {
	"comingSoonPreview": "classes---comingSoonPreview---DNAfp",
	"reviewInfo": "classes---reviewInfo---19nXX"
};