exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---OutreachInfo---1_hto {\n  text-align: center;\n  margin-top: 1rem; }\n\n.classes---OutreachInfo__title---3m3Mb {\n  font-weight: 900;\n  text-transform: uppercase; }\n\n.classes---OutreachInfo__image---3ys6q {\n  width: 100%;\n  max-width: 1280px;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 2rem; }\n", ""]);

// exports
exports.locals = {
	"OutreachInfo": "classes---OutreachInfo---1_hto",
	"OutreachInfo__title": "classes---OutreachInfo__title---3m3Mb",
	"OutreachInfo__image": "classes---OutreachInfo__image---3ys6q"
};