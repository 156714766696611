import React from 'react'
import classNames from 'classnames'
import classes from './classes.scss'

// containers
import FilesLinkContainer from 'containers/FilesLinkContainer'

// components
import { IconDownCarat } from 'components/shared/icons'

/**
Displays a clickable toggle to change the sort direction.
*/
const FileFilterToggleSort = (props) => {
  const {
    orderBy // from Redux state
  } = props

  const orderByField = Object.keys(orderBy)[0]
  const ascending = orderBy[orderByField] === 'ASC'
  const sortByClass = classNames(classes.filtersWrapper, {
    [`${classes.closed}`]: !ascending
  })

  const sortByText = ascending ? 'Oldest First' : 'Newest First'
  const sortByOptions = {
    set: {
      attribute: 'sort',
      value: { [`${orderByField}`]: ascending ? 'DESC' : 'ASC' }
    }
  }

  return (
    <li className={sortByClass}>
      <FilesLinkContainer className={sortByClass} opts={sortByOptions} >
        <span className={classes.filterLabel}>Sort by:</span>
        {sortByText} <IconDownCarat />
      </FilesLinkContainer>
    </li>
  )
}

export default FileFilterToggleSort
