exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".skillMediaNav---next---2swT3,\n.skillMediaNav---prev---1SEfE {\n  position: absolute;\n  z-index: 2;\n  bottom: -54px; }\n  .skillMediaNav---next---2swT3.skillMediaNav---disabled---3B0ZQ svg,\n  .skillMediaNav---prev---1SEfE.skillMediaNav---disabled---3B0ZQ svg {\n    fill: #e6e6e6; }\n  @media (min-width: 774px) {\n    .skillMediaNav---next---2swT3,\n    .skillMediaNav---prev---1SEfE {\n      bottom: auto;\n      top: 40%; }\n      .skillMediaNav---next---2swT3 svg,\n      .skillMediaNav---prev---1SEfE svg {\n        height: 100px;\n        width: 100px; } }\n\n.skillMediaNav---next---2swT3 {\n  right: 34%; }\n  @media (min-width: 774px) {\n    .skillMediaNav---next---2swT3 {\n      right: -100px; } }\n\n.skillMediaNav---prev---1SEfE {\n  left: 34%; }\n  @media (min-width: 774px) {\n    .skillMediaNav---prev---1SEfE {\n      left: -100px; } }\n", ""]);

// exports
exports.locals = {
	"next": "skillMediaNav---next---2swT3",
	"prev": "skillMediaNav---prev---1SEfE",
	"disabled": "skillMediaNav---disabled---3B0ZQ"
};