import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

// lib
import { parseQuery } from 'lib/location'
import { parseUserId } from 'lib/urls/helpers'

// redux
import { setQuery } from 'actions'

// redux
const mapStateToProps = ({ appSettings: { isKiosk } }) => ({ isKiosk })
const withRedux = connect(mapStateToProps, { setQuery })

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
export const withRouterData = (WrappedComponent) => {
  const RouterData = (props) => {
    const {
      match, // from react-router
      location, // from react-router
      history, // from react-router
      setQuery, // from redux actions
      isKiosk // from redux state
    } = props

    const { studioId, pageId } = match.params
    const { userId, camperId } = parseUserId(match.params)
    const query = parseQuery(location)
    const isTour = pageId === 'tour'

    if (isKiosk) {
      query.studios = null
      query.activities = null
    } else if (studioId === 'film') {
      query.studios = ['film']
      query.activities = ['live_action']
    } else if (studioId === 'music') {
      query.studios = ['music']
      query.activities = ['midi', 'songwriting', 'dj', 'karaoke', 'podcasting']
    } else if (studioId === 'film_animation') {
      query.studios = ['film']
      query.activities = ['animation']
    } else if (studioId === 'coding') {
      query.studios = ['coding']
      query.activities = ['coding', 'robotics']
    } else if (studioId === 'fashion') {
      query.studios = ['fashion']
      query.activities = ['hand_sewing', 'machine_sewing', 'crochet']
    }

    query.sort = query.sort || { created_at: 'DESC' }

    setQuery(query)

    return <WrappedComponent
      {...props}
      history={history}
      studioId={studioId}
      camperId={camperId}
      userId={userId}
      isTour={isTour}
    />
  }

  RouterData.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setQuery: PropTypes.func.isRequired,
    isKiosk: PropTypes.bool.isRequired
  }

  return RouterData
}

export default compose(
  withRedux,
  withRouterData
)
