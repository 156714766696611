import { createMuiTheme } from '@material-ui/core/styles'
// import { ThemeProvider } from "@material-ui/styles";

const defaultTheme = createMuiTheme()

// CHOICES:
const palette = {
  limeGreen: '#8dc63f',
  rightNowRed: '#be1e2d',
  wrongRed: '#F44336',
  helloYellow: '#f8c440',
  black: '#000',
  white: '#fff'
}

const fontFamily = {
  main: ['brandon-grotesque', 'sans-serif']
}

// DECISIONS:
const unoTheme = createMuiTheme({
  ...defaultTheme,
  breakpoints: {
    ...defaultTheme.breakpoints
    // values: {
    // uno/variables/typography.scss:
    // xs: 0,
    // sm: 320,
    // md: 768,
    // lg: 1280,
    // xl: 1600
    // material-ui defaults:
    // xs: 0 (through 599)
    // sm: 600 (through 959)
    // md: 960
    // lg: 1280
    // xl: 1920
    // }
  },
  palette: {
    ...defaultTheme.palette,
    // color intentions:
    primary: {
      main: palette.black,
      contrastText: palette.white
    },
    secondary: {
      main: palette.limeGreen,
      contrastText: palette.white
    },
    error: {
      main: palette.wrongRed
    },
    // custom color intentions:
    warning: {
      main: palette.helloYellow
    }
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: fontFamily.main,
    h1: {
      ...defaultTheme.typography.h1,
      fontFamily: fontFamily.main,
      marginTop: 0,
      marginBottom: '.6rem',
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '26px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: 1.125
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '41px',
        lineHeight: 1.125
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '46px',
        lineHeight: 1.125
      }
    },
    h2: {
      ...defaultTheme.typography.h2,
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '26px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '36px',
        lineHeight: 1.125
      }
    },
    h3: {
      ...defaultTheme.typography.h3,
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '22px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '26px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '28px',
        lineHeight: 1.125
      }
    },
    h4: {
      ...defaultTheme.typography.h4,
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '22px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '26px',
        lineHeight: 1.125
      }
    },
    h5: {
      ...defaultTheme.typography.h5,
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '22px',
        lineHeight: 1.125
      }
    },
    h6: {
      ...defaultTheme.typography.h6,
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '12px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '14px',
        lineHeight: 1.125
      }
    },
    subtitle1: {
      ...defaultTheme.typography.subtitle1,
      fontFamily: fontFamily.main
    },
    subtitle2: {
      ...defaultTheme.typography.subtitle2,
      fontFamily: fontFamily.main
    },
    body1: {
      ...defaultTheme.typography.body1,
      fontFamily: fontFamily.main,
      // uno/variables/typography.scss
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: 1.3
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: 1.3
      }
    },
    body2: {
      ...defaultTheme.typography.body2,
      fontFamily: fontFamily.main
    },
    button: {
      ...defaultTheme.typography.button,
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '20px'
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '22px'
      }
    },
    caption: {
      ...defaultTheme.typography.caption,
      fontFamily: fontFamily.main
    },
    overline: {
      ...defaultTheme.typography.overline,
      fontFamily: fontFamily.main
    }
  },
  shape: {
    borderRadius: 0
  }
})

export default unoTheme
