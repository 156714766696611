import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const AdminUnapproveConfirm = (props) => {
  const {
    visible,
    onClickUnapprove,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            header='Un-marking content as approved will make it un-viewable in parent account'
            subheader={null}
          >
            <ConfirmationButton
              text='Un-mark as approved'
              action={onClickUnapprove}
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

AdminUnapproveConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickUnapprove: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminUnapproveConfirm
