import React from 'react'
import classes from './classes.scss'
import classNames from 'classnames'

import BackButtonContainer from 'containers/BackButtonContainer'
import StudioHeader from 'components/StudioHeader'
import LinkToHistory from 'components/LinkToHistory'

import chuckie from './img/studioChick@2x.png'

const Responsibilities = () => {
  const backClass = classNames('kid-btn', classes.back)
  return (
    <section>
      <header className={classes.staticHeader}>
        <BackButtonContainer linkTo={LinkToHistory} text='Back' className={backClass} />
        <StudioHeader head='Responsibilities' headSet='Three' />
      </header>
      <h2 className={classes.responsibilitiesIntro}>In the Pool, just like anywhere else at camp, each camper has three important responsibilities:</h2>
      <div className={classes.responsibilities}>
        <div className={classes.responsibility}>
          <h3>Safety First</h3>
          <p className={classes.responsibilityDetail}>Campers behave safely while creating projects and photos.</p>
        </div>
        <div className={classes.responsibility}>
          <h3>Respect</h3>
          <p className={classes.responsibilityDetail}>Campers show respect for themselves and each other. Campers only post projects that they create.</p>
        </div>
        <div className={classes.responsibility}>
          <h3>Take Care</h3>
          <p className={classes.responsibilityDetail}>Campers take care of all equipment used for creating projects and photos.</p>
        </div>
      </div>
      <img src={chuckie} className={classes.studioChick} />
    </section>
  )
}

export default Responsibilities
