import { graphql } from 'react-apollo'

// GraphQL query specific to this component
import query from './query.graphql'

/**
HOC to bind a GraphQL query and handlers to a wrapped component. `options`
takes a function that converts the component's received props into variables for
the GraphQL query.
*/
const withApolloQuery = graphql(query, {
  options: (props) => {
    const { locationId, query } = props

    const {
      showcase,
      studios,
      activities,
      moderation,
      sort,
      creators,
      page
    } = query

    const staffUserFilter = () => {
      // return a staff filter if "creators" is either ['staff'] or ['camper']
      if (creators && creators.length === 1) {
        return { 'users.staff': creators[0] === 'staff' }
      }

      // don't filter if "creators" is all types ['staff', 'camper'], or not specified
      return {}
    }

    const showcaseFilter = () => {
      return showcase ? { submitted: true, enqueued: true } : {}
    }

    const filter = Object.assign({
      location: locationId,
      studio: studios,
      activity: activities,
      moderation_status: moderation
    }, staffUserFilter(), showcaseFilter())

    return {
      variables: { filter, orderBy: sort, page }
    }
  }
})

export default withApolloQuery
