import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import classes from './classes.scss'

import {
  MODERATION_STATUS_PENDING,
  MODERATION_STATUS_ESCALATED
} from 'lib/moderation/status'

/**
Displays a title overlay on a media thumbnail
*/
const MediaThumbnailTitle = (props) => {
  const { text, status } = props
  const className = classNames(
    classes.title,
    {
      [`${classes.pending}`]: status === MODERATION_STATUS_PENDING,
      [`${classes.escalated}`]: status === MODERATION_STATUS_ESCALATED
    }
  )

  return (
    <h6 className={className}>
      {text}
    </h6>
  )
}

MediaThumbnailTitle.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string
}

export default MediaThumbnailTitle
