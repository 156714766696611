import React from 'react'
import OutreachEmailPrompt from 'components/OutreachEmailPrompt'
import classes from './classes.scss'

/**
Preseason-specific version of the email collection form.
*/
const PreseasonEmailPrompt = (props) => {
  return (
    <section className={classes.wrapper}>

      <OutreachEmailPrompt
        {...props}
        updates
      />
    </section>
  )
}

export default PreseasonEmailPrompt
