exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes classes---gifFadeOut---2MIwt {\n  0% {\n    opacity: 1; }\n  45% {\n    opacity: 1; }\n  55% {\n    opacity: 0; }\n  100% {\n    opacity: 0; } }\n\n.classes---tipsTechniques----vhYX {\n  margin: 40px 0; }\n\n.classes---item---JzypB {\n  padding: 0 20px 16px;\n  margin: 0 26px;\n  display: inline-block;\n  overflow: hidden;\n  position: relative; }\n  .classes---item---JzypB.classes---addOn---A48e5:after {\n    content: '+';\n    font-size: 36px;\n    line-height: 30px;\n    color: #ffffff;\n    width: 30px;\n    height: 30px;\n    border-radius: 15px;\n    position: absolute;\n    top: 12px;\n    left: 22px;\n    z-index: 1;\n    text-align: center;\n    background: black; }\n\n.classes---item---JzypB.classes---animated---1zJlH img {\n  top: 1px;\n  animation-name: classes---gifFadeOut---2MIwt;\n  animation-timing-function: ease-out;\n  animation-fill-mode: forwards;\n  animation-delay: 1s;\n  animation-duration: 3s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(2) img {\n  animation-delay: 2s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(3) img {\n  animation-delay: 3s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(4) img {\n  animation-delay: 4s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(5) img {\n  animation-delay: 5s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(6) img {\n  animation-delay: 6s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(7) img {\n  animation-delay: 7s; }\n\n.classes---item---JzypB.classes---animated---1zJlH:nth-child(8) img {\n  animation-delay: 8s; }\n", ""]);

// exports
exports.locals = {
	"tipsTechniques": "classes---tipsTechniques----vhYX",
	"item": "classes---item---JzypB",
	"addOn": "classes---addOn---A48e5",
	"animated": "classes---animated---1zJlH",
	"gifFadeOut": "classes---gifFadeOut---2MIwt"
};