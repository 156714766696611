import React from 'react'
import IconBrand from 'uno/images/kid-world/svg/icon-sk.svg'
import IconBrandRound from 'uno/images/svg/skc.svg'
import IconStar from 'uno/images/kid-world/svg/icon-star.svg'
import IconThumbsUp from 'uno/images/kid-world/svg/icon-thumb-round.svg'
import IconFashionRoom from 'uno/images/svg/room-fashion.svg'
import IconCampValuesRoom from 'uno/images/svg/room-camp-values.svg'
import IconFilmRoom from 'uno/images/svg/room-film.svg'
import IconAnimationRoom from 'uno/images/svg/room-animation.svg'
import IconMusicRoom from 'uno/images/svg/room-music.svg'
import IconCodingRoom from 'uno/images/svg/room-coding.svg'
import IconLoungeRoom from 'uno/images/svg/room-lounge.svg'
import IconCreatorRoom from 'uno/images/svg/room-creator.svg'
import IconMakerRoom from 'uno/images/svg/room-maker.svg'
import IconRemove from 'uno/images/kid-world/svg/icon-close.svg'
import IconUnsubmit from 'uno/images/kid-world/svg/icon-unsubmit.svg'
import IconDownload from 'uno/images/kid-world/svg/icon-download.svg'
import IconEdit from 'uno/images/kid-world/svg/icon-edit.svg'
import IconFilmCamera from 'uno/images/kid-world/svg/icon-film-camera.svg'
import IconFilmEdit from 'uno/images/kid-world/svg/icon-film-edit.svg'
import IconFilmTrailer from 'uno/images/kid-world/svg/icon-film-trailer.svg'
import IconFilmSound from 'uno/images/kid-world/svg/icon-film-sound.svg'
import IconFilmDirector from 'uno/images/kid-world/svg/icon-film-director.svg'
import IconFilmShowcase from 'uno/images/kid-world/svg/icon-film-showcase.svg'
import IconFlag from 'uno/images/kid-world/svg/icon-flag.svg'
import IconiMovie from 'uno/images/kid-world/svg/icon-imovie.svg'
import IconGarageband from 'uno/images/kid-world/svg/icon-music-garageband.svg'
import IconStopMotion from 'uno/images/kid-world/svg/icon-stopmotion.svg'
import IconLaunchpad from 'uno/images/kid-world/svg/icon-launchpad.svg'
import IconLeftArrow from 'uno/images/kid-world/svg/icon-l-arrow.svg'
import IconDownCarat from 'uno/images/kid-world/svg/icon-d-carat.svg'
import IconLeftCarat from 'uno/images/kid-world/svg/icon-l-carat.svg'
import IconLogin from 'uno/images/kid-world/svg/icon-login.svg'
import IconRightArrow from 'uno/images/kid-world/svg/icon-r-arrow.svg'
import IconRightCarat from 'uno/images/kid-world/svg/icon-carat.svg'
import IconSave from 'uno/images/kid-world/svg/icon-save.svg'
import IconSettings from 'uno/images/kid-world/svg/icon-settings.svg'
import IconUpPointer from 'uno/images/kid-world/svg/icon-u-pointer.svg'
import IconX from 'uno/images/kid-world/svg/icon-x.svg'
import IconDelete from 'uno/images/kid-world/svg/icon-delete.svg'
import IconSew from 'uno/images/kid-world/svg/icon-fashion-hand-sew.svg'
import IconCrochet from 'uno/images/kid-world/svg/icon-fashion-crochet.svg'
import IconMachine from 'uno/images/kid-world/svg/icon-fashion-machine.svg'
import IconRoundBag from 'uno/images/kid-world/svg/icon-fashion-round-bag.svg'
import IconBucketBag from 'uno/images/kid-world/svg/icon-fashion-bucket-bag.svg'
import IconTallBag from 'uno/images/kid-world/svg/icon-fashion-tall-bag.svg'
import IconWideBag from 'uno/images/kid-world/svg/icon-fashion-wide-bag.svg'
import IconWallet from 'uno/images/kid-world/svg/icon-fashion-wallet.svg'
import IconTshirt from 'uno/images/kid-world/svg/icon-fashion-t-shirt.svg'
import IconDress from 'uno/images/kid-world/svg/icon-fashion-dress.svg'
import IconShorts from 'uno/images/kid-world/svg/icon-fashion-shorts.svg'
import IconShirts from 'uno/images/kid-world/svg/icon-fashion-skirt.svg'
import IconAddOn from 'uno/images/kid-world/svg/icon-add-on.svg'
import IconMore from 'uno/images/kid-world/svg/icon-more.svg'
import IconLock from 'uno/images/kid-world/svg/icon-lock.svg'
import IconPlay from 'uno/images/kid-world/svg/icon-play.svg'
import IconCardboardRoom from 'uno/images/svg/room-cardboard.svg'

const getIcon = (iconName) => {
  // TODO: rename in Airtable:
  // Fashion
  switch (iconName) {
    case 'IconStar':
      return <IconStar />
    case 'IconThumbsUp':
      return <IconThumbsUp />
    case 'IconCampValuesRoom':
      return <IconCampValuesRoom />
    case 'IconFashionRoom':
      return <IconFashionRoom />
    case 'IconFilmRoom':
      return <IconFilmRoom />
    case 'IconAnimationRoom':
      return <IconAnimationRoom />
    case 'IconMusicRoom':
      return <IconMusicRoom />
    case 'IconCodingRoom':
      return <IconCodingRoom />
    case 'IconLoungeRoom':
      return <IconLoungeRoom />
    case 'IconCreatorRoom':
      return <IconCreatorRoom />
    case 'IconMakerRoom':
      return <IconMakerRoom />
    case 'IconFilmCamera':
      return <IconFilmCamera />
    case 'IconFilmSound':
      return <IconFilmSound />
    case 'IconFilmTrailer':
      return <IconFilmTrailer />
    case 'IconFilmDirector':
      return <IconFilmDirector />
    case 'IconFilmShowcase':
      return <IconFilmShowcase />
    case 'IconFilmEdit':
      return <IconFilmEdit />
    case 'IconLaunchpad':
      return <IconLaunchpad />
    case 'IconFlag':
      return <IconFlag />
    case 'IconiMovie':
      return <IconiMovie />
    case 'IconGarageband':
      return <IconGarageband />
    case 'IconStopMotion':
      return <IconStopMotion />
    case 'IconSew':
      return <IconSew />
    case 'IconCrochet':
      return <IconCrochet />
    case 'IconMachine':
      return <IconMachine />
    case 'IconRoundBag':
      return <IconRoundBag />
    case 'IconBucketBag':
      return <IconBucketBag />
    case 'IconTallBag':
      return <IconTallBag />
    case 'IconWideBag':
      return <IconWideBag />
    case 'IconWallet':
      return <IconWallet />
    case 'IconTshirt':
      return <IconTshirt />
    case 'IconDress':
      return <IconDress />
    case 'IconShorts':
      return <IconShorts />
    case 'IconShirts':
      return <IconShirts />
    case 'IconAddOn':
      return <IconAddOn />
    case 'IconMore':
      return <IconMore />
    case 'IconBrandRound':
      return <IconBrandRound />
    case 'IconCardboardRoom':
      return <IconCardboardRoom />
    default:
      return null
  }
}

export {
  getIcon,
  IconBrand,
  IconBrandRound,
  IconFashionRoom,
  IconStar,
  IconThumbsUp,
  IconFilmRoom,
  IconAnimationRoom,
  IconMusicRoom,
  IconCodingRoom,
  IconLoungeRoom,
  IconCreatorRoom,
  IconMakerRoom,
  IconDownload,
  IconEdit,
  IconFilmCamera,
  IconFilmEdit,
  IconFilmSound,
  IconFilmDirector,
  IconFilmTrailer,
  IconFilmShowcase,
  IconFlag,
  IconiMovie,
  IconGarageband,
  IconStopMotion,
  IconLaunchpad,
  IconLeftArrow,
  IconLeftCarat,
  IconDownCarat,
  IconLogin,
  IconRightArrow,
  IconRightCarat,
  IconSave,
  IconSettings,
  IconUpPointer,
  IconX,
  IconDelete,
  IconRemove,
  IconUnsubmit,
  IconSew,
  IconCrochet,
  IconMachine,
  IconRoundBag,
  IconBucketBag,
  IconTallBag,
  IconWideBag,
  IconWallet,
  IconTshirt,
  IconDress,
  IconShorts,
  IconShirts,
  IconAddOn,
  IconMore,
  IconLock,
  IconPlay,
  IconCardboardRoom,
}
