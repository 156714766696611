import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// styles
import classes from './classes.scss'

// logic
import { checkPreventShowcase } from 'lib/studios'

// components
import { IconX as IconClose } from 'components/shared/icons'
import FileTitle from 'components/FileTitle'
import FileMeta from 'components/FileMeta'
import FileTags from 'components/FileTags'
import StudioUserFileHeader from 'components/StudioUserFileHeader'
import FileCollaborators from 'components/FileCollaborators'

// containers
import FileDetailModerationBadgeContainer from 'containers/FileModerationBadgeContainer/Detail'

// media components
import FileMediaWrapper from 'components/FileMedia/FileMediaWrapper'
import FileViewerWrapper from 'components/FileMedia/FileViewerWrapper'
import FileViewer from 'components/FileViewer'

// action buttons
import FileActions from 'components/FileActions'
import EditButtonContainer from 'containers/FileActionButtons/EditButtonContainer'
import StudioShowcaseSubmitButtonContainer from 'containers/FileActionButtons/StudioShowcaseSubmitButtonContainer'
import StudioDownloadButtonContainer from 'containers/FileActionButtons/StudioDownloadButtonContainer'

// action confirmation
import EditConfirmContainer from 'containers/FileActionConfirm/EditConfirmContainer'
import StudioShowcaseSubmitConfirmContainer from 'containers/FileActionConfirm/StudioShowcaseSubmitConfirmContainer'

// moderation
import {
  isViewable,
  MODERATION_STATUS_PENDING
} from 'lib/moderation/status'

const StudioFileDetail = (props) => {
  const {
    // data from Redux
    user,
    file,
    // app state from Redux
    mediaDuration,
    showcaseReady,
    // actions
    onClose,
    activeAction,
    setActiveAction,
    setMessage
  } = props

  const closeButtonClasses = classNames('o-close', classes.closeButton)
  const allowShowcaseSubmit = !checkPreventShowcase(file.studio)

  return (
    <div className={classes.studioFileDetail}>
      <nav className={classes.modalNav}>
        <a href='#' className={closeButtonClasses} onClick={onClose}>
          <IconClose />
        </a>
      </nav>
      <StudioUserFileHeader user={user} file={file} />

      <FileMediaWrapper>
        <FileViewerWrapper file={file}>
          <FileViewer file={file} />
          {!isViewable(file) &&
            <FileDetailModerationBadgeContainer
              status={MODERATION_STATUS_PENDING}
            />
          }
        </FileViewerWrapper>
      </FileMediaWrapper>

      <FileMeta>
        <FileActions file={file} setMessage={setMessage}>
          <EditButtonContainer
            file={file}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
          {isViewable(file) &&
            <StudioDownloadButtonContainer
              file={file}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            />
          }
          {allowShowcaseSubmit && (
            <StudioShowcaseSubmitButtonContainer
              file={file}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              disabled={file.submitted}
            />
          )}
        </FileActions>
        <FileTitle file={file} />
        <FileCollaborators file={file} />
        <FileTags file={file} />
        {file.submitted &&
          <section>
            <p className='caption'>
              This film was submitted to Showcase
            </p>
          </section>
        }
        <EditConfirmContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />
        <StudioShowcaseSubmitConfirmContainer
          file={file}
          studioId={file.studio}
          mediaDuration={mediaDuration}
          showcaseReady={showcaseReady}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />
      </FileMeta>
    </div>
  )
}

StudioFileDetail.propTypes = {
  // data
  user: PropTypes.object.isRequired, // from Apollo
  file: PropTypes.object.isRequired, // from Apollo
  mediaDuration: PropTypes.number.isRequired, // from container state
  showcaseReady: PropTypes.bool.isRequired, // from container state
  // ui state
  isUnoNative: PropTypes.bool.isRequired, // from redux state
  // redux actions
  onClose: PropTypes.func.isRequired, // from redux actions
  setMessage: PropTypes.func.isRequired // from redux actions
}

export default StudioFileDetail
