import React from 'react'
import Select from './Select'

const LocationSelect = ({locations, locationId, onChange}) => (
  <Select value={locationId} onChange={onChange}>
    { locations.map(location => (
      <option key={location.id} value={location.id}>
        { `${location.name} (${location.session.name})` }
      </option>
    )) }
  </Select>
)

export default LocationSelect
