exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---expandedFilePreview---1lRH9 {\n  border: 1px solid #e6e6e6;\n  max-width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 20px; }\n  .classes---expandedFilePreview---1lRH9:after {\n    content: \" \";\n    display: block;\n    clear: both; }\n\n.classes---filePreviewWrapper---2dGNm {\n  width: 100%;\n  float: left;\n  margin-left: 0;\n  margin-right: 0;\n  position: relative;\n  max-height: 177px; }\n  @media (min-width: 1024px) {\n    .classes---filePreviewWrapper---2dGNm {\n      width: 32.20339%;\n      float: left;\n      margin-right: 1.69492%;\n      padding-left: 0;\n      padding-right: 0;\n      text-align: left; } }\n\n.classes---expandedMediaMetaWrapper---25uBB {\n  width: 100%;\n  float: left;\n  margin-left: 0;\n  margin-right: 0;\n  margin-top: 20px;\n  padding-left: 20px;\n  padding-right: 20px;\n  position: relative; }\n  @media (min-width: 1024px) {\n    .classes---expandedMediaMetaWrapper---25uBB {\n      width: 57.62712%;\n      float: left;\n      margin-right: 1.69492%;\n      float: right;\n      margin-right: 0;\n      padding-left: 0;\n      padding-right: 0;\n      margin-top: 0px; } }\n", ""]);

// exports
exports.locals = {
	"expandedFilePreview": "classes---expandedFilePreview---1lRH9",
	"filePreviewWrapper": "classes---filePreviewWrapper---2dGNm",
	"expandedMediaMetaWrapper": "classes---expandedMediaMetaWrapper---25uBB"
};