// Must match codes in uno-api
const INAPPROPRIATE_CONTENT_ERROR = 'INAPPROPRIATE_CONTENT_ERROR'
const NOT_AUTHENTICATED_ERROR = 'NOT_AUTHENTICATED_ERROR'
const USER_NOT_UNDER_ACCOUNT_ERROR = 'USER_NOT_UNDER_ACCOUNT_ERROR'

// Detect a GraphQL error with the given code
const detectError = (error, code) => {
  return (
    error.graphQLErrors &&
    error.graphQLErrors.find(e => e.code === code)
  )
}

export {
  detectError,
  INAPPROPRIATE_CONTENT_ERROR,
  NOT_AUTHENTICATED_ERROR,
  USER_NOT_UNDER_ACCOUNT_ERROR
}
