import React from 'react'

// presentational components
import { Loading } from 'components/shared'

/**
HOC to handle raw Apollo data and state props and pass them on to a component
*/
const withApolloData = (WrappedComponent) => {
  return (props) => {
    const { viewer } = props.data

    // TODO: better way to differentiate between initial load and subsequent updates?
    if (!viewer) { return <Loading /> }

    return (
      <WrappedComponent
        {...props}
        account={viewer}
      />
    )
  }
}

export default withApolloData
