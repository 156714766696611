import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

import {
  MEDIA_TYPE_STRATEGO_APP,
  MEDIA_TYPE_SCRATCHJR_APP,
  MEDIA_TYPE_SPHERO_APP
} from 'lib/media/types'

/**
Wrapper around the media content viewer displayed on a file detail page.
*/
const FileDownloadInstructions = ({mediaType}) => {
  if (mediaType === MEDIA_TYPE_SCRATCHJR_APP) {
    const scratchjrInstructions = 'Scratch Jr. projects can be viewed by ' +
                                  'downloading on an iPad and opening in the ' +
                                  'Scratch Jr. app.'

    return <p className={classes.downloadInstructions}>{scratchjrInstructions}</p>
  } else if (mediaType === MEDIA_TYPE_STRATEGO_APP) {
    const strategoInstructions = 'Coding Studio apps can be viewed in mobile. ' +
                                 "If you aren't on a mobile device, click play, " +
                                 'then enter a phone number when prompted and ' +
                                 'we will text a mobile friendly link.'

    return <p className={classes.downloadInstructions}>{strategoInstructions}</p>
  } else if (mediaType === MEDIA_TYPE_SPHERO_APP) {
    const spheroInstructions = 'Your camper created this program to control a ' +
                                 'Sphero robot. To see it in action, download & open ' +
                                 'in the Sphero EDU app... or just leave that part ' +
                                 'to your camper!'

    return <p className={classes.downloadInstructions}>{spheroInstructions}</p>
  } else {
    return null
  }
}

FileDownloadInstructions.propTypes = {
  mediaType: PropTypes.string.isRequired
}

export default FileDownloadInstructions
