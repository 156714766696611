import React from 'react'
import { compose } from 'redux'

import withActionResult from './withActionResult'

// mutations
import withModerationStatusMutation from 'mutations/withModerationStatusMutation'

// presentational components
import AdminEscalateConfirm from 'components/FileActionConfirm/AdminEscalateConfirm'

import { MODERATION_STATUS_ESCALATED } from 'lib/moderation/status'

const withAdminEscalateAction = WrappedComponent => {
  const AdminReportAction = props => {
    const {
      file,
      activeAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submitModerationStatus
    } = props

    const handleClickEscalate = () => {
      const input = {
        moderation_status: MODERATION_STATUS_ESCALATED
      }

      submitModerationStatus(file.id, input)
        .then(onSubmitSuccess)
        .catch(error =>
          onSubmitFailure(error, 'Error updating moderation status')
        )
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'escalate'}
        onClickEscalate={handleClickEscalate}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminReportAction
}

export default compose(
  withActionResult,
  withModerationStatusMutation,
  withAdminEscalateAction
)(AdminEscalateConfirm)
