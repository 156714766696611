import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// redux action creators
import { setMessage } from 'actions'

// mutations
import withModerationStatusMutation from 'mutations/withModerationStatusMutation'

// presentational components
import AdminReportConfirm from 'components/FileActionConfirm/AdminReportConfirm'

import { MODERATION_STATUS_FLAGGED } from 'lib/moderation/status'

const FAILURE_REASONS = [
  'Access Denied',
  'The specified bucket is not valid',
  'The specified bucket does not exist',
  'The specified key does not exist',
  'Missing credentials in config'
]

const humanizedError = (errorMessage) => {
  const failureReason = FAILURE_REASONS.find((reason) => errorMessage.includes(reason))
  return failureReason ? `${failureReason}. The S3 permissions could not be changed.` : errorMessage
}

const withAdminReportAction = (WrappedComponent) => {
  const AdminReportAction = (props) => {
    const {
      file,
      activeAction, // from withActiveFileAction
      setActiveAction, // from withActiveFileAction
      submitModerationStatus,
      setMessage
    } = props

    const handleSubmitFailure = (error) => {
      const errorMessage = humanizedError(error.message)

      setMessage({
        body: errorMessage,
        header: 'Error reporting file',
        type: 'FAILURE'
      })
    }

    const handleSubmitSuccess = () => {
      setActiveAction('reported')
    }

    const handleClickReport = () => {
      const input = {
        moderation_status: MODERATION_STATUS_FLAGGED
      }

      submitModerationStatus(file.id, input)
        .then(handleSubmitSuccess)
        .catch(handleSubmitFailure)
    }

    const handleClickCancel = (e) => {
      e.preventDefault()
      setActiveAction(null)
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'report'}
        onClickReport={handleClickReport}
        onClickCancel={handleClickCancel}
      />
    )
  }

  return AdminReportAction
}

const mapStateToProps = () => ({})

const withRedux = connect(mapStateToProps, { setMessage })

export default compose(
  withRedux,
  withModerationStatusMutation,
  withAdminReportAction
)(AdminReportConfirm)
