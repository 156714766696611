import React from 'react'
import classes from './classes.scss'

const FileActions = ({children}) => {
  return (
    <div className={classes.fileActions}>
      {children}
    </div>
  )
}

export default FileActions
