import React from 'react'

const ConfirmationButton = (props) => {
  const { type, action, text, href, target } = props
  const className = 'kid-btn kid-btn--light'

  return (
    <li className={type}>
      {href ? (
        <a onClick={action} href={href} target={target} className={className}>
          {text}
        </a>
      ) : (
        <a onClick={action} className={className}>
          {text}
        </a>
      )}
    </li>
  )
}

export default ConfirmationButton
