import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

// lib
import windowProxy from 'lib/window'
import { metric } from 'lib/metrics'

// actions
import { setUser } from 'actions'

const mapStateToProps = () => ({})

const withRedux = connect(mapStateToProps, { setUser })

const withScanLogic = (WrappedComponent) => {
  class ScanLogic extends React.Component {
    /**
    If barcode is a QR code, close the camera pane and redirect to camper files.
    If barcode is NOT a QR code, keep on truckin'
    */
    _handleBarCodeRead = (e) => {
      const { studioId, history } = this.props
      const userId = e.data
      if (!userId) { return }

      history.push(`/studios/${studioId}/campers/${userId}`)
      metric('badgeScan', { userId })
    }

    _handleLogout = (e) => {
      const { studioId, history, setUser } = this.props
      setUser(null)
      history.push(`/studios/${studioId}`)
    }

    UNSAFE_componentWillMount () {
      windowProxy.setOnBarCodeRead(this._handleBarCodeRead)
      windowProxy.setOnLogout(this._handleLogout)
    }

    // componentDidMount () {
    //   // DEBUG ONLY: trigger a scan on page load
    //   window.onBarCodeRead({'type': 'org.iso.QRCode', 'data': '94'})
    // }

    render () {
      return (
        <WrappedComponent
          {...this.props}
        />
      )
    }
  }

  ScanLogic.propTypes = {
    studioId: PropTypes.string.isRequired, // from Router
    history: PropTypes.object.isRequired, // from Router
    setUser: PropTypes.func.isRequired // from Redux
  }

  return ScanLogic
}

export default compose(
  withRedux,
  withScanLogic
)
