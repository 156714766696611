import React from 'react'
import { compose } from 'redux'

// mutations
import withShowcaseFlagsMutation from 'mutations/withShowcaseFlagsMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import AdminDequeueConfirm from 'components/FileActionConfirm/AdminDequeueConfirm'

const withAdminDequeueAction = (WrappedComponent) => {
  const AdminDequeueAction = (props) => {
    const {
      file,
      activeAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submitShowcaseFlags // from withShowcaseFlagsMutation
    } = props

    const handleClickDequeue = () => {
      const input = {
        id: file.id,
        enqueued: false
      }

      submitShowcaseFlags(input)
        .then(onSubmitSuccess)
        .catch(error => onSubmitFailure(error, 'Error removing file from queue'))
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'dequeue'}
        onClickDequeue={handleClickDequeue}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminDequeueAction
}

export default compose(
  withShowcaseFlagsMutation,
  withActionResult,
  withAdminDequeueAction
)(AdminDequeueConfirm)
