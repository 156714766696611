import React from 'react'
import sharedClasses from 'components/shared/classes.scss'
import classes from './classes.scss'
import { Loading, ServerError } from 'components/shared'

/**
Returns true if the error response is because this auth code is expired, likely because:
1) it was already exchanged for a token by this user, and then
2) they later hit the browser "back" button, causing this component to resubmit the code.
@param {Object} error - the error that was returned from the server (via lib/xhr)
@returns {boolean} "true" if the error is due to the auth code being expired
*/
function authCodeInvalid (error) {
  return error.message.match(/The provided authorization grant is invalid/)
}

/**
Renders the S&K logo with a link to Mancala root.
*/
const Logo = ({mancalaUrl}) => {
  return (
    <div className={sharedClasses.statusWrapper}>
      <a href={mancalaUrl}>
        <img className={classes.logo} src='/assets/uno/images/skc-logo.png' />
      </a>
    </div>
  )
}

const Authorize = ({error, invalidCode, mancalaUrl}) => {
  if (error) {
    if (authCodeInvalid(error)) {
      return <Logo mancalaUrl={mancalaUrl} />
    } else {
      return <ServerError error={error} />
    }
  } else {
    return <Loading />
  }
}

export default Authorize
