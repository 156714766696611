import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const buildApolloClient = (accessToken) => {
  return new ApolloClient({
    link: new HttpLink({
      uri: '/api/graphql',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }),
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__)
  })
}

export { buildApolloClient }
