import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles'
import {
  style,
  compose,
  typography,
  color,
  spacing,
  sizing,
  positions,
  flexbox
} from '@material-ui/system'

// custom style prop:
// https://material-ui.com/system/basics/#custom-style-props
const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
  transform: value => value
})

const StyledText = styled(Typography)(
  compose(
    typography,
    textTransform,
    spacing,
    sizing,
    positions,
    color,
    flexbox
  )
)

export default StyledText
