import React from 'react'
import classes from 'components/shared/classes.scss'

const FilesEmpty = ({message}) => {
  return (
    <h2 className={classes.emptyState}>
      {message}
    </h2>
  )
}

export default FilesEmpty
