const getSkillRedirectPath = ({kitId, studioId, skillGroup, skillId}) => {
  if (!skillId) {
    skillId = skillGroup.skills[0].id
  }

  if (kitId) { return `/diy/${kitId}/${skillId}` }

  return `/studios/${studioId}/skillgroups/${skillGroup.id}/skills/${skillId}`
}

export default getSkillRedirectPath
