exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---pageHeader---pzKYY {\n  text-align: center;\n  margin-top: 20px; }\n", ""]);

// exports
exports.locals = {
	"pageHeader": "classes---pageHeader---pzKYY"
};