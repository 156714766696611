import React from 'react'
import PropTypes from 'prop-types'

// containers
import BackButtonContainer from 'containers/BackButtonContainer'
import AdminClearCacheContainer from 'containers/AdminClearCacheContainer'
import AdminTranscodeResourceVideosContainer from 'containers/AdminTranscodeResourceVideosContainer'

// components
import LegacyLayout from 'components/LegacyLayout'
import UserProfile from 'components/UserProfile'
import AdminHomeHeader from 'components/AdminHomeHeader'
import { Loading } from 'components/shared'
import LinkToOps from 'components/LinkToOps'
import AppModeSelect from './AppModeSelect'
import StudioSelect from './StudioSelect'
import LocationSelect from './LocationSelect'
import WeekSelect from './WeekSelect'

const AdminSettings = props => {
  if (!props.account) {
    return <Loading />
  }

  const {
    // data from GraphQL
    account,
    locations,
    studios,
    programArcs,
    // current settings from redux
    nodeEnv,
    appMode,
    locationId,
    studioId,
    sessionType,
    week,
    // redux actions
    onAppModeChange,
    onStudioChange,
    onLocationChange,
    onWeekChange
  } = props

  return (
    <LegacyLayout>
      <UserProfile account={account} user={null}>
        <BackButtonContainer
          linkTo={LinkToOps}
          text='Ops'
          displayMode='userProfile'
        />
      </UserProfile>
      <AdminHomeHeader header='App Settings' />

      <hr />
      <h3>Preview Content</h3>

      <h5>Select app mode</h5>
      <AppModeSelect appMode={appMode} onChange={onAppModeChange} />

      <h5>Select location</h5>
      <LocationSelect
        locations={locations}
        locationId={locationId}
        onChange={onLocationChange}
      />

      <h5>Select {sessionType} studio</h5>
      <StudioSelect
        studios={studios}
        studioId={studioId}
        sessionType={sessionType}
        onChange={onStudioChange}
      />

      <h5>Select {sessionType} program arc week</h5>
      <WeekSelect
        programArcs={programArcs}
        week={week}
        sessionType={sessionType}
        onChange={onWeekChange}
      />

      <a href='/' target='_blank' className='kid-btn kid-btn--solid'>
        Preview in {nodeEnv}
      </a>

      <hr />
      <h3>Transcode Content</h3>
      <AdminTranscodeResourceVideosContainer />

      <hr />
      <h3>Publish Content</h3>
      <AdminClearCacheContainer />
    </LegacyLayout>
  )
}

AdminSettings.propTypes = {
  account: PropTypes.object.isRequired, // from Apollo
  locations: PropTypes.array.isRequired, // from Apollo
  studios: PropTypes.array.isRequired, // from Apollo
  appMode: PropTypes.string // from Redux
}

export default AdminSettings
