exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---filePreview---1KJqg {\n  display: inline-block;\n  position: relative;\n  padding-bottom: 10px;\n  text-align: center; }\n  @media (min-width: 632px) and (max-width: 1023px) {\n    .classes---filePreview---1KJqg {\n      margin: 0 24px 10px 0; }\n      .classes---filePreview---1KJqg:nth-child(2n) {\n        margin-right: 0; } }\n  @media (min-width: 1024px) and (max-width: 1920px) {\n    .classes---filePreview---1KJqg {\n      margin: 0 38px 0 0; }\n      .classes---filePreview---1KJqg:nth-child(3n) {\n        float: right;\n        margin-right: 0; } }\n  @media (min-width: 1920px) {\n    .classes---filePreview---1KJqg {\n      margin: 0 26px 0 0; }\n      .classes---filePreview---1KJqg:nth-child(4n) {\n        float: right;\n        margin-right: 0; } }\n\n.classes---fileDetail---2L1b1 {\n  position: relative;\n  display: inline-block;\n  border: 2px solid #e6e6e6;\n  width: 315px;\n  height: 177px;\n  overflow: hidden; }\n  .classes---fileDetail---2L1b1.classes---selected---1MOJB {\n    border: 2px solid #000000; }\n  .classes---fileDetail---2L1b1:focus, .classes---fileDetail---2L1b1:hover {\n    opacity: .8; }\n\n.classes---fileStamp---3xOuB {\n  width: 66px;\n  height: 66px;\n  border-radius: 33px;\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  padding-top: 14px;\n  text-align: center;\n  background: #000000;\n  color: #ffffff;\n  z-index: 1; }\n  .classes---fileStamp---3xOuB .classes---studio---REClq {\n    font-size: 12px;\n    text-transform: uppercase;\n    font-weight: 700; }\n  .classes---fileStamp---3xOuB .classes---time---_-yCd {\n    text-transform: lowercase;\n    font-size: 10px;\n    font-weight: 300;\n    line-height: .7;\n    display: block;\n    position: relative;\n    top: -4px; }\n", ""]);

// exports
exports.locals = {
	"filePreview": "classes---filePreview---1KJqg",
	"fileDetail": "classes---fileDetail---2L1b1",
	"selected": "classes---selected---1MOJB",
	"fileStamp": "classes---fileStamp---3xOuB",
	"studio": "classes---studio---REClq",
	"time": "classes---time---_-yCd"
};