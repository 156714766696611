import React from 'react'
import classes from './classes.scss'
import classNames from 'classnames'
import ConfirmationWrapper from './ConfirmationWrapper'

const Confirmation = (props) => {
  const {
    children,
    header,
    subheader,
    visible,
    isDropList
  } = props

  const panelWrapperClass = classNames(classes.panelWrapper, 'panel', {
    [`${classes.hidden}`]: !visible
  })

  return (
    <ConfirmationWrapper isDropList={isDropList}>
      <div className={panelWrapperClass}>
        <p className='caption'><b>{header}</b></p>
        <p className='caption'>{subheader}</p>
        <ul className='panel-confirmation'>
          {children}
        </ul>
      </div>
    </ConfirmationWrapper>
  )
}

export default Confirmation
