import studios from './studios.json'
import { flatten } from 'lodash/array'

/**
Find a studio by ID
@param {String} studioId - the ID of the studio
@return {object} The studio matching the given ID
*/
function findStudio (id) {
  if (!id) {
    return null
  }
  if (id.includes('_')) {
    id = id.split('_').shift()
  }
  return studios.find(studio => studio.id === id)
}

/**
Find an activity by ID (in any studio)
@param {String} activityId - the ID of the activity
@return {object} The activity matching the given ID
*/
function findActivity (activityId) {
  const activities = flatten(studios.map(studio => studio.activities))
  return activities.find(activity => activity.id === activityId)
}

/**
Find an activity by ID (scoped to a specific studio)
@param {String} studioId - the ID of the studio
@param {String} activityId - the ID of the activity
@return {object} The activity matching the given ID
*/
function findStudioActivity (studioId, activityId) {
  const studio = findStudio(studioId)
  if (studio) {
    return studio.activities.find(activity => activity.id === activityId)
  }
}

function checkPreventShowcase (studioId) {
  const studio = findStudio(studioId)
  if (studio) {
    return !!studio.preventShowcase
  }
}

function studiosWithUploads () {
  return studios.filter(studio => studio.hasUploads)
}

function activitiesWithUploads (studio) {
  return studio.activities.filter(activity => activity.hasUploads)
}

export {
  findStudio,
  findActivity,
  findStudioActivity,
  checkPreventShowcase,
  studiosWithUploads,
  activitiesWithUploads
}
