import React from 'react'
import { CSSTransition } from 'react-transition-group'

const MaxHeightTransition = (props) => {
  return (
    <CSSTransition
      {...props}
      classNames='max-height-transition'
      timeout={{enter: 400, exit: 400}}
    />
  )
}

export default MaxHeightTransition
