import React from 'react'
import PropTypes from 'prop-types'

// lib
import { userFilesPath } from 'lib/urls'

const withRedirectLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      account: PropTypes.object.isRequired, // from Apollo
      history: PropTypes.object // from redux via withRouter
    }

    state = {
      error: null
    }

    // see also containers/HomeContainer/withRedirectLogic.js
    redirectToFirstUser = () => {
      const { history, account } = this.props
      const accountUsers = account.account_users
      const firstUser = accountUsers.edges[0].node.user
      const path = userFilesPath(firstUser)

      history.push(path)
    }

    componentDidMount () {
      const { account } = this.props
      const count = account.account_users.edges.length

      if (count === 0) {
        this.setState({error: new Error('No campers in account')})
      } else if (count === 1) {
        this.redirectToFirstUser()
      } else {
        // renders presentational component
      }
    }

    render () {
      const { account } = this.props
      const { error } = this.state

      return (
        <WrappedComponent
          account={account}
          error={error}
        />
      )
    }
  }
}

export default withRedirectLogic
