import React from 'react'

// containers
import StudioUserFilesHeaderContainer from 'containers/StudioUserFilesHeaderContainer'
import StudioFilePreviewContainer from 'containers/StudioFilePreviewContainer'

// components
import FilesEmpty from 'components/FilesEmpty'
import FilePagination from 'components/FilePagination'
import PostingGuidelines from 'components/shared/PostingGuidelines'

// styles
import classes from 'components/UserHome/classes.scss'

/**
If a user has been scanned (QR) and fetched (Apollo), display user files.
Otherwise, display scan button.
*/
export default function StudioFiles ({user, studioData}) {
  if (!user) {
    return null
  }

  const { edges: userFiles, pageInfo } = user.user_files

  return (
    <div>
      <StudioUserFilesHeaderContainer
        user={user}
        studioData={studioData}
      />
      {userFiles.length === 0 ? (
        <FilesEmpty message='You haven&rsquo;t added any projects yet.' />
      ) : (
        <div className={classes.userFileList}>
          {userFiles.map(({node: userFile}) => (
            <StudioFilePreviewContainer
              key={userFile.id}
              file={userFile.file}
              userFile={userFile}
            />
          ))}
          <FilePagination pageInfo={pageInfo} />
          <PostingGuidelines />
        </div>
      )}
    </div>
  )
}
