import React from 'react'
import classes from './classes.scss'

/**
Content that replaces the detail page file viewer when the file is still under
review and therefore its actual content can't be displayed.
*/
const AccountFileDetailUnderReview = ({header, body}) => {
  return (
    <div className={classes.comingSoonDetailWrapper}>
      <div className={classes.comingSoonDetail}>
        {header}
      </div>
      <p className={classes.reviewInfo}>
        {body.map(line => (
          <span key={line}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  )
}

export default AccountFileDetailUnderReview
