import React from 'react'

// components
import LegacyLayout from 'components/LegacyLayout'
import { NotFound } from 'components/shared'
import StudioContent from 'components/StudioContent'
import StudioFiles from 'components/StudioFiles'
import StudioTour from 'components/StudioTour'

// containers
import StudioUserFileDetailContainer from 'containers/StudioUserFileDetailContainer'

const StudioHome = props => {
  const {
    user, // from Apollo query
    studio, // from Apollo query
    programArc, // from Apollo query
    userFile, // from Redux state
    isPreseason, // from Redux state
    isTour
  } = props

  if (!studio) {
    return <NotFound />
  }

  if (isTour) {
    return <StudioTour studio={studio} />
  }

  return (
    <LegacyLayout>
      {userFile ? (
        <StudioUserFileDetailContainer user={user} userFile={userFile} />
      ) : (
        <StudioContent programArc={programArc} studio={studio} />
      )}

      {!isPreseason && <StudioFiles user={user} studioData={studio} />}
    </LegacyLayout>
  )
}

export default StudioHome
