import React from 'react'
import classes from './classes.scss'

// containers
import FileFilterOptionsContainer from 'containers/FileFilterOptionsContainer'
import FileFilterAttributeContainer from 'containers/FileFilterAttributeContainer'
import FileFilterSelectionContainer from 'containers/FileFilterSelectionContainer'

/**
Displays file filters, available choices, and selected choices.
Used by admins as well as regular accounts.
*/
const FileFilters = (props) => {
  const {isAdmin} = props

  return (
    <nav className={classes.filterBar}>
      <FileFilterOptionsContainer isAdmin={isAdmin} />
      <div className={classes.filtersWrapper}>
        <FileFilterAttributeContainer attribute='studios' />
        <FileFilterAttributeContainer attribute='activities' />
        <FileFilterAttributeContainer attribute='moderation' />
        <FileFilterAttributeContainer attribute='creators' />
        <FileFilterSelectionContainer />
      </div>
    </nav>
  )
}

export default FileFilters
