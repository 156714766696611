import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

// styles
import classes from './classes.scss'

/**
Wrapper around the preview thumbnail for a file. Includes an overlay stamp
displaying file summary info.
*/
const FilePreviewWrapper = (props) => {
  const { selected, file, children } = props
  const {
    studio,
    created_at: createdAt
  } = file

  const fileDetailClasses = classNames(classes.fileDetail, {
    [`${classes.selected}`]: selected
  })

  return (
    <div className={fileDetailClasses}>
      <span className={classes.fileStamp}>
        <span className={classes.studio}>{studio}</span>
        <span className={classes.time}>{moment(createdAt).fromNow()}</span>
      </span>
      {children}
    </div>
  )
}

FilePreviewWrapper.propTypes = {
  file: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired
}

export default FilePreviewWrapper
