import React from 'react'
import PropTypes from 'prop-types'

const AdminFileFilterOption = ({value, name}) => (
  <option key={value} value={value}>
    {name}
  </option>
)

const AdminFileFilterSelect = (props) => {
  const { options, selectedValue, onChange } = props

  return (
    <div className='admin-file-filter-select-wrapper'>
      <div className='mui-select'>
        <select
          className='c-location-select__select'
          onChange={(e) => onChange(e.target.value)}
          value={selectedValue}
        >
          {options.map(option => (
            <AdminFileFilterOption
              key={option.value}
              name={option.name}
              value={option.value}
            />
          ))}
        </select>
      </div>
    </div>
  )
}

AdminFileFilterSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string
}

export default AdminFileFilterSelect
