import React from 'react'

const withApolloData = (WrappedComponent) => {
  return (props) => {
    const { data } = props
    const { viewer: account, studios, skillGroups } = data

    return <WrappedComponent
      {...props}
      studio={studios[0]}
      skillGroup={skillGroups[0]}
      account={account}
    />
  }
}

export default withApolloData
