import React from 'react'

// components
import ActionButton from 'components/FileActionButtons/ActionButton'
import { IconEdit } from 'components/shared/icons'

const withEditButtonAction = (WrappedComponent) => {
  const EditButtonAction = (props) => {
    const {
      activeAction,
      setActiveAction
    } = props

    const handleClick = (e) => {
      e.preventDefault()
      setActiveAction('edit')
    }

    const active = activeAction === 'edit'
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        label='Edit'
        active={active}
        disabled={disabled}
        icon={IconEdit}
        onClick={handleClick}
      />
    )
  }

  return EditButtonAction
}

export default withEditButtonAction(ActionButton)
