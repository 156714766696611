import React from 'react'
import { Loading, ServerError } from 'components/shared'

const Home = ({error}) => {
  if (error) {
    return <ServerError error={error} />
  } else {
    return <Loading />
  }
}

export default Home
