import { graphql } from 'react-apollo'
import query from 'mutations/File/ShowcaseFlagsMutation.graphql'

const withShowcaseFlagsMutation = graphql(query, {
  props: ({ mutate }) => ({
    submitShowcaseFlags: (input) => mutate({
      variables: { input }
      // TODO:
      // optimisticResponse: {
      //   __typename: 'Mutation',
      //   updateFile: {
      //     __typename: 'ModerateShowcaseFilePayload',
      //     clientMutationId: 0,
      //     file: {
      //       __typename: 'File',
      //       id: input.id,
      //       starred: input.location,
      //       clientMutationId: '0'
      //     }
      //   }
      // }
    })
  })
})

export default withShowcaseFlagsMutation
