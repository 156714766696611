import React from 'react'
import classes from './classes.scss'

const FilePreviewCell = ({children}) => {
  return (
    <div className={classes.filePreviewCell}>
      {children}
    </div>
  )
}

export default FilePreviewCell
