import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

// components
import SubheaderFlourish from 'components/SubheaderFlourish'
import SkillGroupPreview from 'components/SkillGroupPreview'

class SkillGroupList extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    skillGroups: PropTypes.array.isRequired,
    studioId: PropTypes.string.isRequired
  }

  render () {
    const { skillGroups, studioId, title } = this.props

    return (
      <section className={classes.tipsTechniques}>
        <SubheaderFlourish subheader={title} />
        <ul className={classes.scrollingList}>
          {skillGroups.map(skillGroup => (
            <SkillGroupPreview
              key={skillGroup.id}
              skillGroup={skillGroup}
              studioId={studioId}
            />)
          )}
        </ul>
      </section>
    )
  }
}

export default SkillGroupList
