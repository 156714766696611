exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@keyframes classes---gifFadeOut---12e5D {\n  0% {\n    opacity: 1; }\n  45% {\n    opacity: 1; }\n  55% {\n    opacity: 0; }\n  100% {\n    opacity: 0; } }\n\n.classes---tipsTechniques---2ekKs {\n  margin: 40px 0; }\n\n.classes---scrollingList---LVe8N {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  white-space: nowrap;\n  box-shadow: inset -8px 0 7px -8px rgba(0, 0, 0, 0.05);\n  border-right: 1px solid #ffffff;\n  overflow-y: scroll; }\n  .classes---scrollingList---LVe8N::-webkit-scrollbar {\n    width: 0px;\n    background: transparent; }\n  .classes---scrollingList---LVe8N .classes---item---3tvnZ {\n    padding: 0 20px 16px;\n    margin: 0 26px;\n    display: inline-block;\n    overflow: hidden; }\n", ""]);

// exports
exports.locals = {
	"tipsTechniques": "classes---tipsTechniques---2ekKs",
	"scrollingList": "classes---scrollingList---LVe8N",
	"item": "classes---item---3tvnZ",
	"gifFadeOut": "classes---gifFadeOut---12e5D"
};