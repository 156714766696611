import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// mutations
import withDeleteUserFileMutation from 'mutations/withDeleteUserFileMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import AdminDeleteConfirm from 'components/FileActionConfirm/AdminDeleteConfirm'

const withAdminDeleteAction = (WrappedComponent) => {
  const AdminDeleteAction = (props) => {
    const {
      userFile,
      refetchQuery, // from Redux state
      activeAction, // from withActiveFileAction
      setActiveAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitFailure, // from withActionResult
      deleteUserFile // from withDeleteUserFileMutation
    } = props

    const handleSubmitSuccess = () => {
      refetchQuery()
      setActiveAction('deleted')
    }

    const handleClickDelete = () => {
      deleteUserFile(userFile.id)
        .then(handleSubmitSuccess)
        .catch(error => onSubmitFailure(error, 'Error deleting the file'))
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'delete'}
        onClickDelete={handleClickDelete}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminDeleteAction
}

const mapStateToProps = ({userData: {refetchQuery}}) => ({refetchQuery})
const withReduxData = connect(mapStateToProps)

export default compose(
  withDeleteUserFileMutation,
  withActionResult,
  withReduxData,
  withAdminDeleteAction
)(AdminDeleteConfirm)
