import React from 'react'

// presentational component
import { Loading } from 'components/shared'

/**
HOC to handle raw Apollo data and state props and pass them on to a
wrapped (presentational) component
*/
const withApolloData = (WrappedComponent) => {
  return (props) => {
    const { viewer, studios, programArcs } = props.data

    if (!viewer) { return <Loading /> }

    return <WrappedComponent
      {...props}
      account={viewer}
      studios={studios}
      programArcs={programArcs}
    />
  }
}

export default withApolloData
