import {
  SELECT_USER_FILE,
  SET_VIEWER,
  SET_USER,
  SET_REFETCH_QUERY,
  SET_LAST_STUDIO_PAGE_ID
} from 'actions/actionTypes'

import { pick } from 'lodash/object'

const userData = (state = {}, action) => {
  switch (action.type) {
    case SELECT_USER_FILE:
      const { userFile } = action
      return Object.assign({}, state, { userFile })
    case SET_LAST_STUDIO_PAGE_ID:
      const { lastStudioPageId } = action
      return Object.assign({}, state, { lastStudioPageId })
    case SET_VIEWER:
      const viewer = pick(action.viewer, [
        'id', 'email', 'mancala_id', 'user_type', 'first_name', 'last_name'
      ])
      return Object.assign({}, state, { viewer })
    case SET_USER:
      const user = pick(action.user, [
        'id', 'mancala_camper_id', 'staff', 'first_name', 'last_name', 'photo_thumbnail_url'
      ])
      return Object.assign({}, state, { user })
    case SET_REFETCH_QUERY:
      const refetchQuery = action.refetchQuery || state.refetchQuery
      return Object.assign({}, state, { refetchQuery })
    default:
      return state
  }
}

export default userData
