
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SkillDetails"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Skill"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fullURL"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"imageThumbnail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thumbnailLarge"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thumbnailSmall"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thumbnailType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":138}};
    doc.loc.source = {"body":"fragment SkillDetails on Skill {\n  id\n  file\n  fullURL\n  imageThumbnail\n  thumbnailLarge\n  thumbnailSmall\n  thumbnailType\n  title\n  url\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
