import React, { useEffect } from 'react'

const withEffects = WrappedComponent => {
  return props => {
    useEffect(() => window.scrollTo(0, 0), [])

    return <WrappedComponent {...props} />
  }
}

export default withEffects
