import React from 'react'
import PropTypes from 'prop-types'
import { findActivity, findStudio } from 'lib/studios'

/**
 * Add a `subheader` prop to the wrapped component with appropriate text
 * for the given `file`
 */
const withFileSubheader = (WrappedComponent) => {
  const FileSubheader = (props) => {
    const { file } = props
    const activity = findActivity(file.activity) || {}
    const studio = findStudio(file.studio) || {}
    const subheader = activity.name || studio.name

    return (
      <WrappedComponent
        {...props}
        subheader={subheader}
      />
    )
  }

  FileSubheader.propTypes = {
    file: PropTypes.object.isRequired
  }

  return FileSubheader
}

export default withFileSubheader
