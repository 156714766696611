import React from 'react'
import getSkillRedirectPath from './getSkillRedirectPath'

/**
HOC which takes IDs from router and passes on resource data to the presentational
component
*/
const withResourceData = (WrappedComponent) => {
  return (props) => {
    const { kitId, studioId, studio, skillGroup, skillId, history, account } = props
    const skills = skillGroup.skills
    const skill = skillGroup.skills.find(skill => skill.id === skillId)
    const addOnSkillGroups = skillGroup.relatedAddOns
    let selectedSkillNode
    let previewListNode

    // changes the scroll in the preview list to center the selected skillPreview
    const setScroll = () => {
      if (selectedSkillNode != null && previewListNode != null) {
        const style = selectedSkillNode.currentStyle || window.getComputedStyle(selectedSkillNode)
        const index = Array.prototype.indexOf.call(previewListNode.children, selectedSkillNode)
        const width = selectedSkillNode.offsetWidth + parseInt(style.marginLeft) + parseInt(style.marginRight)

        previewListNode.scrollLeft = width * index - previewListNode.offsetWidth / 2 + width / 2
      }
    }

    const setSelectedSkillNode = (node) => {
      selectedSkillNode = node
      // Note: it would make sense for setScroll() to be called here, but when the
      // component updates, setPreviewListNode is called with null, then setSelectedSkillNode
      // is called with the correct node, then setPreviewListNode is called with the appropriate node
    }

    const setPreviewListNode = (node) => {
      previewListNode = node
      setScroll()
    }

    const changeSelectedSkill = (skillId) => {
      const path = getSkillRedirectPath({kitId, studioId, skillGroup, skillId})
      history.push(path)
    }

    const getBackToRoute = () => {
      const { studioId, lastStudioPageId } = props
      if (!studioId) {
        return '/'
      } else if (lastStudioPageId) {
        return `/studios/${studioId}/${lastStudioPageId}`
      } else {
        return `/studios/${studioId}`
      }
    }

    return (
      <WrappedComponent
        {...props}
        skill={skill}
        studio={studio}
        skillGroup={skillGroup}
        skills={skills}
        backToRoute={getBackToRoute()}
        addOnSkillGroups={addOnSkillGroups}
        account={account}
        changeSelectedSkill={changeSelectedSkill}
        setSelectedSkillNode={setSelectedSkillNode}
        setPreviewListNode={setPreviewListNode}
        kitId={kitId}
      />
    )
  }
}

export default withResourceData
