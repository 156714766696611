import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'
import classNames from 'classnames'
import { MEDIA_TYPE_SCRATCHJR_APP } from 'lib/media/types'

const ImageViewer = (props) => {
  const { source, mediaType } = props

  const imageViewerClass = classNames(classes.image, {
    [`${classes.scratchImage}`]: mediaType === MEDIA_TYPE_SCRATCHJR_APP
  })

  return (
    <img className={imageViewerClass} src={source} />
  )
}

ImageViewer.propTypes = {
  source: PropTypes.string.isRequired
}

export default ImageViewer
