import React from 'react'
import Cookies from 'cookies-js'

// helpers
import { parseQuery } from 'lib/location'

/**
HOC which parses raw router props, detects staff configuration params,
and optionally sets/expires cookies and reloads
*/
const withStaffConfig = (WrappedComponent) => {
  return (props) => {
    const { location, history } = props

    // get query params
    const query = parseQuery(location)

    // detect if an admin user is changing their configuration
    if (query.config) {
      const settings = [
        'appMode', 'isUnoNative', 'campStudioId', 'campLocationId',
        'campSessionType', 'campWeekNumber', 'emailSubmitted',
        'UNO_NATIVE_ACCESS_TOKEN'
      ]

      // set or expire settings cookies
      settings.forEach(setting => {
        if (setting) {
          Cookies.set(setting, query[setting])
        } else {
          Cookies.expire(setting)
        }
      })

      // redirect to home and reload
      // (scope=staff ensures proper OAuth redirect if necessary)
      history.replace('/?scope=staff')
      window.location.reload()
    }

    return <WrappedComponent
      {...props}
    />
  }
}

export default withStaffConfig
