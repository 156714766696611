import { graphql } from 'react-apollo'
import query from './query.graphql'

/**
HOC to bind a GraphQL query and handlers to a wrapped component. `options`
takes a function that converts the component's received props into variables for
the GraphQL query.
*/
const withApolloQuery = graphql(query, {
  options: (props) => {
    const { kitId, studioId, skillGroupId } = props
    const includeViewer = !kitId

    return {
      variables: { includeViewer, studioId, skillGroupId }
    }
  }
})

export default withApolloQuery
