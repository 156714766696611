/**
* Parses an XHR response object as JSON and sets the component's `state.error` to
* an error instance with the error message in the response. If response body is
* not parseable as JSON, sets the error message to the response status and status text.
* N.B. this is a bare function, so be sure to bind `this` to the calling component:
* `handleErrorResponse.call(this, response)`
* @param {object} response - XHR response object from `fetch`
* @returns nothing
*/
function handleErrorResponse (response) {
  return response
    .json()
    .then(json => {
      const message = json.error
      this.setState({ error: new Error(message) })
    })
    .catch(_parseError => {
      const message = `Server responded with ${response.status}: ${response.statusText}`
      this.setState({ error: new Error(message) })
    })
}

/**
* Returns the networkOptions object required to fetch
* @returns networkOptions with Uno Native authorization header if necessary
*/
function networkOptions (accessToken) {
  let options = {
    credentials: 'include' // include cookies in request header
  }

  // token injected by UnoNative
  if (accessToken) {
    options.headers = {
      'Authorization': `Bearer ${accessToken}`
    }
  }

  return options
}

export { handleErrorResponse, networkOptions }
