import React from 'react'
import PropTypes from 'prop-types'

import { findLocation } from 'lib/campLocations'

/**
HOC to set the adminCampLocation in Redux store based on locationId and
locations, asynchronously (because locations is returned via /api/locations)
FIXME: just return locations via /config to avoid all this craziness
*/
const withCampLocation = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      locationId: PropTypes.string.isRequired, // from Router
      locations: PropTypes.array.isRequired, // from Redux state
      adminCampLocation: PropTypes.object.isRequired, // from Redux state
      setAdminCampLocation: PropTypes.func.isRequired // from Redux actions
    }

    _setCampLocation (props) {
      const { locationId, locations } = props
      const location = findLocation(locations, locationId)
      if (location) {
        this.props.setAdminCampLocation(location)
      }
    }

    _locationChanged (nextProps) {
      const { locationId, locations } = this.props
      const { nextLocationId, nextLocationsData } = nextProps

      return (locationId !== nextLocationId) || (JSON.stringify(locations) !== JSON.stringify(nextLocationsData))
    }

    UNSAFE_componentWillMount () {
      const { locations } = this.props
      if (locations.length > 0) {
        this._setCampLocation(this.props)
      }
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if (this._locationChanged(nextProps)) {
        this._setCampLocation(nextProps)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default withCampLocation
