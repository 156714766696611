import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const AdminDeleteConfirm = (props) => {
  const {
    visible,
    user,
    onClickDelete,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            header={`Are you sure you want to delete ${user.first_name}’s file? It will still be available in the Pool for any other collaborators.`}
            subheader='If this file contains inappropriate content, you should "Report as Inappropriate" instead.'
          >
            <ConfirmationButton
              type='destructive'
              text='Delete'
              action={onClickDelete}
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

AdminDeleteConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminDeleteConfirm
