import React from 'react'
import classNames from 'classnames'

// styles
import classes from './classes.scss'

// components
import FileShowcaseStatus from 'components/FileShowcaseStatus'

// buttons
import AdminDequeueButtonContainer from 'containers/FileActionButtons/AdminDequeueButtonContainer'
import AdminRecommendButtonContainer from 'containers/FileActionButtons/AdminRecommendButtonContainer'

// confirmation
import AdminRecommendConfirmContainer from 'containers/FileActionConfirm/AdminRecommendConfirmContainer'
import AdminDequeueConfirmContainer from 'containers/FileActionConfirm/AdminDequeueConfirmContainer'

const AdminFileShowcasePanel = (props) => {
  const {
    file,
    setMessage,
    fileLocationName,
    activeAction,
    setActiveAction
  } = props

  const statusMessage = file.enqueued
    ? 'This film is waiting in the Showcase queue'
    : 'This film was removed from the showcase queue'

  // FIXME: hidden is not defined
  const panelClass = classNames(classes.showcaseStatusPanel, 'panel')

  return (
    <div className={panelClass}>
      <div className={classes.showcasePanelHead}>Showcase Status</div>

      <p className={classes.statusMessage}>{statusMessage}</p>

      <FileShowcaseStatus
        file={file}
        setMessage={setMessage}
      />

      <div className={classes.showcaseFileActions}>
        <AdminRecommendButtonContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />

        <AdminDequeueButtonContainer
          file={file}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
        />
      </div>

      <AdminRecommendConfirmContainer
        file={file}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
      />

      <AdminDequeueConfirmContainer
        file={file}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
      />

      {fileLocationName &&
        <p className={classes.queueLink}>
          <a href={`/files/${file.location}?showcase=true`}>
            Go to {fileLocationName} Showcase Queue »
          </a>
        </p>
      }
    </div>
  )
}

export default AdminFileShowcasePanel
