import React from 'react'

/**
HOC which keeps state for the outreach kit request address modal and passes
state and actions to the presentational component
*/
const withAddressModalLogic = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      displayAddressModal: false
    }

    handleRequestKit = () => {
      this.setState({ displayAddressModal: true })
    }

    handleCloseRequestKit = () => {
      this.setState({ displayAddressModal: false })
    }

    render () {
      const { requestKit } = this.props
      const onRequestKit = requestKit ? this.handleRequestKit : null

      return (
        <WrappedComponent
          {...this.props}
          // actions
          onRequestKit={onRequestKit}
          onCloseRequestKit={this.handleCloseRequestKit}
          // state
          displayAddressModal={this.state.displayAddressModal}
        />
      )
    }
  }
}

export default withAddressModalLogic
