exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---userImage---2ffb4 {\n  display: inline-block;\n  position: relative; }\n  .classes---userImage---2ffb4 img {\n    margin-top: -20px;\n    width: 60px;\n    height: 60px;\n    border-radius: 30px;\n    clip-path: circle(60px at center);\n    -webkit-clip-path: circle(30px at center);\n    margin-right: 10px;\n    margin-left: -60px;\n    object-fit: cover; }\n", ""]);

// exports
exports.locals = {
	"userImage": "classes---userImage---2ffb4"
};