import React from 'react'
import classes from './classes.scss'

const StudioSetupHelp = ({showHelp, helpMessage}) => {
  if (!showHelp) {
    return null
  }

  return (
    <p className={classes.message}>
      {helpMessage}
    </p>
  )
}

export default StudioSetupHelp
