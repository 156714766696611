import uuidv4 from 'uuid/v4'
import Honeybadger from 'honeybadger-js'
import { store } from 'lib/redux'

function postMetric (tableName, data) {
  const body = JSON.stringify({
    tableName: tableName,
    data: data
  })

  return fetch('/metrics', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: body
  })
}

/**
Fetch application state from the redux store to provide additional context for
any metrics being reported to the metrics backend.
@return {Object} An object containing the app state
*/
function getUserContext () {
  const state = store.getState()
  const {
    appMode,
    isUnoNative,
    campLocationId,
    adminCampLocation,
    campStudioId: studioId
  } = state.appSettings

  // app environment
  const {
    clientId,
    sessionId,
    nodeEnv
  } = state.appConfig

  // location
  let locationId = null
  if (campLocationId) {
    locationId = parseInt(campLocationId)
  } else if (adminCampLocation) {
    locationId = adminCampLocation.id
  }

  // account and user
  const {
    id: accountId,
    user_type: accountUserType
  } = (state.userData.viewer || {id: null, user_type: null})

  const {
    id: userId,
    mancala_camper_id: camperId,
    staff: staffUser
  } = (state.userData.user || {id: null, mancala_camper_id: null, staff: null})

  const adminAccount = accountUserType === 'AdminUser'

  // metrics
  return {
    accountId,
    adminAccount,
    appMode,
    camperId,
    clientId,
    isUnoNative,
    locationId,
    nodeEnv,
    sessionId,
    staffUser,
    studioId,
    userId
  }
}

/**
Log event data to the specified table in the metrics backend (currently RJ Metrics, via our Express web server).
@param {String} tableName - name of the table in which to log the data
@param {Object} eventData - an object containing data to log
@return {Promise} Resolves on response from the metrics backend.
*/
export function metric (tableName, eventData = {}) {
  // TODO: replace RJMetrics backend with another metrics service
  return Promise.resolve()

  // try {
  //   const userContext = getUserContext()
  //   const payload = Object.assign({
  //     'keys': ['uuid'],
  //     'uuid': uuidv4()
  //   }, userContext, eventData)

  //   return postMetric(tableName, payload)
  // } catch (e) {
  //   Honeybadger.notify(e)
  //   return Promise.resolve()
  // }
}
