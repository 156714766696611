import { graphql } from 'react-apollo'
import query from './query.graphql'

// return any variables needed by Apollo query
const withApolloQuery = graphql(query, {
  options: (props) => {
    const { sessionType } = props

    return {
      variables: { sessionType }
    }
  }
})

export default withApolloQuery
