exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---adminModerationFooter---3PC7u {\n  border-top: 1px solid;\n  padding: 20px;\n  text-align: center; }\n  .classes---adminModerationFooter---3PC7u p {\n    font-style: italic; }\n\n.classes---footerHead---1KtEi {\n  text-transform: none;\n  font-weight: 400; }\n", ""]);

// exports
exports.locals = {
	"adminModerationFooter": "classes---adminModerationFooter---3PC7u",
	"footerHead": "classes---footerHead---1KtEi"
};