import {
  STORE_LOCATIONS
} from 'actions/actionTypes'

const campLocations = (state = {}, action) => {
  switch (action.type) {
    case STORE_LOCATIONS:
      const locations = action.campLocations
      return [ ...state, ...locations ]
    default:
      return state
  }
}

export default campLocations
