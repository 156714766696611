import React from 'react'
import classes from './classes.scss'

import FileFilterToggleSort from './FileFilterToggleSort'
import FileFilterToggle from './FileFilterToggle'

/**
Displays clickable toggles for filter sort direction and filter types
*/
const FileFilterOptions = (props) => {
  const {
    // redux state
    orderBy,
    filtersVisible,
    selectedFilter,
    // redux actions
    selectFilter,
    // config prop
    isAdmin
  } = props

  return (
    <ul className={classes.filterOptions}>
      <FileFilterToggleSort orderBy={orderBy} />
      <FileFilterToggle
        isOpen={filtersVisible && selectedFilter === 'studios'}
        onClick={() => selectFilter('studios')}
        displayName='Studios'
        displayLabel
      />
      {isAdmin &&
        <FileFilterToggle
          isOpen={filtersVisible && selectedFilter === 'moderation'}
          onClick={() => selectFilter('moderation')}
          displayName='Review Status'
        />
      }
      {isAdmin &&
        <FileFilterToggle
          isOpen={filtersVisible && selectedFilter === 'creators'}
          onClick={() => selectFilter('creators')}
          displayName='Creator'
        />
      }
    </ul>
  )
}

export default FileFilterOptions
