exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---select---33XGh {\n  padding-top: 0px;\n  margin-bottom: 40px; }\n", ""]);

// exports
exports.locals = {
	"select": "classes---select---33XGh"
};