import React from 'react'
import Honeybadger from 'honeybadger-js'
import classes from './classes.scss'
import LegacyLayout from 'components/LegacyLayout'

export default class ServerError extends React.Component {
  componentDidMount() {
    Honeybadger.notify(this.props.error)
  }

  render() {
    const { error, quiet } = this.props

    if (!quiet) {
      console.log('ServerError:', error)
    }

    return (
      <LegacyLayout>
        <section className={classes.statusWrapper}>
          <h1>We make big mistakes.</h1>
          <p>
            Happily, we can learn from unexpected errors. Until we can resolve
            this one, please reload or restart.
          </p>
          <img src='/assets/uno/images/kid-world/blam-500.png' />
          <div>
            <a href='/' className='kid-btn'>
              Restart
            </a>
          </div>
          <p className={classes.caption}>
            <muted>{error.message}</muted>
          </p>
        </section>
      </LegacyLayout>
    )
  }
}
