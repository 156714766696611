import React from 'react'
import PropTypes from 'prop-types'
import { IconLeftCarat } from 'components/shared/icons'
import classNames from 'classnames'
import classes from './skillMediaNav.scss'

class SkillPreviousButton extends React.Component {
  static propTypes = {
    changeSelectedSkill: PropTypes.func.isRequired,
    selectedSkillId: PropTypes.string.isRequired,
    skills: PropTypes.array.isRequired
  }

  isFirstSkill () {
    const {selectedSkillId, skills} = this.props
    return skills[0].id === selectedSkillId
  }

  getPrevSkillId () {
    const {selectedSkillId, skills} = this.props
    const index = skills.map(skill => skill.id).indexOf(selectedSkillId) - 1
    return index >= 0 ? skills[index].id : selectedSkillId
  }

  render () {
    const { changeSelectedSkill } = this.props
    const prevId = this.getPrevSkillId()
    let prevClass = classNames(classes.prev, {
      [`${classes.disabled}`]: this.isFirstSkill()
    })

    return (
      <div className={prevClass} onClick={() => { changeSelectedSkill(prevId) }}><IconLeftCarat /></div>
    )
  }
}
export default SkillPreviousButton
