exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".skillMediaWrapper---videoTitle---1sA8f {\n  position: absolute;\n  bottom: 0px;\n  width: 100%;\n  margin: 0;\n  padding: 16px;\n  z-index: 2;\n  background: rgba(0, 0, 0, 0.8);\n  text-align: center;\n  color: #ffffff;\n  font-weight: 700;\n  white-space: nowrap;\n  -moz-transition: opacity 0.4s ease;\n  -o-transition: opacity 0.4s ease;\n  -webkit-transition: opacity 0.4s ease;\n  transition: opacity 0.4s ease; }\n", ""]);

// exports
exports.locals = {
	"videoTitle": "skillMediaWrapper---videoTitle---1sA8f"
};