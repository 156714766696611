import { connect } from 'react-redux'

/**
Container to provide Redux state as props for a component.
*/
const mapStateToProps = ({appConfig, appSettings, userData}) => {
  const { mancalaUrl } = appConfig
  const { isUnoNative, week, campStudioId, campLocationId } = appSettings
  const { user } = userData

  return {
    mancalaUrl,
    isUnoNative,
    week,
    campStudioId,
    campLocationId,
    user
  }
}

/**
HOC to pass relevant Redux state as props to a wrapped component
*/
const withRedux = connect(mapStateToProps)

export default withRedux
