import qs from 'qs'
import { cloneDeep } from 'lodash/lang'
import { findStudio } from 'lib/studios'

function sanitizeQuery (query) {
  ['studios', 'activities', 'moderation', 'creators'].forEach(function (attribute) {
    const value = query[attribute]
    if (!value || (Array.isArray(value) && value.length === 0)) {
      delete query[attribute]
    }
  })
}

/**
@private helper to remove all activities for the given studio from the query.
*/
function removeStudioActivities (query, studioId) {
  const queryActivityIds = query['activities']
  if (!Array.isArray(queryActivityIds)) {
    return
  }

  const studioActivityIds = findStudio(studioId).activities.map(activity => activity.id)
  query['activities'] = queryActivityIds.filter(id => !studioActivityIds.includes(id))
}

/**
@private helper to remove a single value from the query.
*/
function removeAttribute (query, attribute, value) {
  if (!Array.isArray(query[attribute])) {
    return
  }

  query[attribute] = query[attribute].filter(existingValue => existingValue !== value)

  // handle special case for studio activities
  if (attribute === 'studios') {
    removeStudioActivities(query, value)
  }
}

/**
@private helper to add a single value to the query.
*/
function addAttribute (query, attribute, value) {
  if (!query[attribute]) {
    query[attribute] = []
  }

  query[attribute].push(value)
}

/**
@private helper to toggle a specific attribute value on or off in the query.
*/
function toggleAttribute (query, toggle) {
  const { isSelected, attribute, value } = toggle

  if (isSelected) {
    removeAttribute(query, attribute, value)
  } else {
    addAttribute(query, attribute, value)
  }
}

function setAttribute (query, set) {
  const { attribute, value, modify } = set
  if (modify) {
    if (modify === 'increment') {
      query[attribute] = parseInt(query[attribute]) + 1
    } else if (modify === 'decrement') {
      query[attribute] = parseInt(query[attribute]) - 1
    }
  } else {
    if (value) {
      query[attribute] = value
    } else {
      delete query[attribute]
    }
  }
}

function resetPage (query) {
  setAttribute(query, {
    attribute: 'page',
    value: 1
  })
}

function stringifyQuery (query, location) {
  const search = qs.stringify(query)
  return `${location.pathname}?${search}`
}

/**
Generate a navigation URL given the current query state, current location, and
options to add or remove search criteria.
@param {object} query - Current query state from Redux store
@param {object} location - Current location from `withRouter` via `react-router`
@param {object} opts - Options to toggle or set search criteria (examples below)
@returns {string} a routeable URL with updated params

@example
To toggle selection of a specific studio, provide these opts:
{
  toggle: {
    attribute: 'studios',
    value: 'film',
    isSelected: true
  }
}
*/
function filesUrl (query, location, opts) {
  let newQuery = cloneDeep(query)
  const { toggle, set } = opts

  if (toggle) {
    toggleAttribute(newQuery, toggle)
    resetPage(newQuery)
  }

  if (set) {
    setAttribute(newQuery, set)
    if (opts.set.attribute === 'sort') {
      resetPage(newQuery)
    }
  }

  sanitizeQuery(newQuery)
  return stringifyQuery(newQuery, location)
}

export default filesUrl
