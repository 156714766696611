exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---skillsScroller---3IG_d {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  margin: 40px 0;\n  width: 100%;\n  white-space: nowrap;\n  box-shadow: inset -8px 0 7px -8px rgba(0, 0, 0, 0.05);\n  border-right: 1px solid #ffffff;\n  overflow-y: scroll; }\n  .classes---skillsScroller---3IG_d::-webkit-scrollbar {\n    width: 0px;\n    background: transparent; }\n  @media (max-width: 1023px) {\n    .classes---skillsScroller---3IG_d {\n      margin-top: 60px; } }\n", ""]);

// exports
exports.locals = {
	"skillsScroller": "classes---skillsScroller---3IG_d"
};