import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import classes from './classes.scss'
import KidButton from 'components/atoms/KidButton'

const StudioSetupButton = (props) => {
  const { onSubmit } = props
  const setUpButtonClass = classNames('kid-btn', classes.setUpButton)

  return (
    <KidButton variant='contained' color='primary' fullWidth onClick={onSubmit}>Go!</KidButton>
  )
}

StudioSetupButton.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default StudioSetupButton
