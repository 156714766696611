exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.classes---btn-toggle__btn---UvgMK {\n  color: #000000;\n  text-decoration: none;\n  outline: none;\n  background: #ffffff;\n  margin-bottom: .5rem;\n  display: block;\n  padding: .4rem 1rem;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: middle;\n  -ms-touch-action: manipulation;\n  touch-action: manipulation;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  background-image: none;\n  border: 1px solid transparent;\n  border-radius: 4px; }\n\n\n.classes---btn-toggle__btn---UvgMK {\n  font-size: 14px;\n  line-height: 1.125;\n  color: #939598;\n  font-weight: 900;\n  text-transform: uppercase;\n  border: 1px solid #cccccc; }\n  @media screen and (min-width: 768px) {\n    \n    .classes---btn-toggle__btn---UvgMK {\n      font-size: 16px;\n      line-height: 1.125; } }\n  @media screen and (min-width: 1280px) {\n    \n    .classes---btn-toggle__btn---UvgMK {\n      font-size: 16px;\n      line-height: 1.125; } }\n  @media screen and (min-width: 1600px) {\n    \n    .classes---btn-toggle__btn---UvgMK {\n      font-size: 16px;\n      line-height: 1.125; } }\n  @media (min-width: 568px) {\n    \n    .classes---btn-toggle__btn---UvgMK {\n      border-top-right-radius: 0;\n      border-bottom-right-radius: 0;\n      border-right: 0; } }\n  \n  .classes---btn-toggle__btn---UvgMK:nth-child(2n) {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n    border-left: 0;\n    border-right: 1px solid #cccccc; }\n\n\n.classes---btn-toggle__btn--selected---23BtQ {\n  background: #000000;\n  color: #ffffff; }\n\n.classes---pageHeader---16P65 {\n  text-align: center;\n  margin-top: 20px; }\n", ""]);

// exports
exports.locals = {
	"btn-toggle__btn": "classes---btn-toggle__btn---UvgMK",
	"btn-toggle__btn--selected": "classes---btn-toggle__btn--selected---23BtQ",
	"pageHeader": "classes---pageHeader---16P65"
};