import React from 'react'

// components
import FilePreviewCell from 'components/FilePreviewCell'
import UserFileDetailLink from 'components/UserFileDetailLink'
import FilePreviewWrapper from 'components/FilePreviewWrapper'
import FilePreviewContent from 'components/FilePreviewContent'

// containers
import AccountFilePreviewUnderReviewContainer from 'containers/AccountFileUnderReviewContainer/Preview'

// moderation
import {
  isViewable
} from 'lib/moderation/status'

/**
Preview for a media file being viewed by a non-admin account.
*/
const AccountFilePreview = ({user, userFile, file}) => {
  return (
    <FilePreviewCell>
      <UserFileDetailLink user={user} userFile={userFile}>
        <FilePreviewWrapper file={file} selected={false}>
          {isViewable(file) ? (
            <FilePreviewContent file={file} />
          ) : (
            <div>
              <AccountFilePreviewUnderReviewContainer />
            </div>
          )}
        </FilePreviewWrapper>
      </UserFileDetailLink>
    </FilePreviewCell>
  )
}

export default AccountFilePreview
