import React from 'react'
import PropTypes from 'prop-types'

const withFormState = (WrappedComponent) => {
  class CacheFormState extends React.Component {
    state = {
      cacheId: null,
      result: [],
      error: null
    }

    // handle changed selection in form
    handleChange = (e) => {
      const cacheId = e.target.value
      this.setState({cacheId, result: []})
    }

    // handle successful result from GraphQL mutation
    handleResult = (result) => {
      const { data: { clearCache } } = result
      this.setState({ result: clearCache, error: null })
    }

    // handle error from GraphQL mutation
    handleError = (error) => {
      this.setState({error: error})
    }

    // handle form submission
    handleSubmit = (e) => {
      const { cacheId } = this.state
      const { clearCache } = this.props
      clearCache(cacheId)
        .then(this.handleResult)
        .catch(this.handleError)
    }

    render () {
      const { cacheId, result, error } = this.state

      return (
        <WrappedComponent
          {...this.props}
          cacheId={cacheId}
          result={result}
          error={error}
          onChange={this.handleChange}
          onResult={this.handleResult}
          onSubmit={this.handleSubmit}
        />
      )
    }
  }

  CacheFormState.propTypes = {
    // from mutation
    clearCache: PropTypes.func.isRequired
  }

  return CacheFormState
}

export default withFormState
