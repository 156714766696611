import React from 'react'
import PropTypes from 'prop-types'
import UserFileDetailLink from 'components/UserFileDetailLink'
import classes from './classes.scss'

/**
Returns a name with a comma appended if there are more names to follow,
e.g. 'Bob Smith,'
*/
const nameWithComma = (user, index, count) => {
  const separator = (index + 1) === count ? '' : ','
  const { first_name: firstName, last_name: lastName } = user
  const displayName = [firstName, lastName].join(' ')
  return `${displayName}${separator}`
}

const Collaborator = ({name, userFile, linkToDetail, children}) => {
  return (
    <li className={classes.collaborator}>
      {linkToDetail ? (
        <UserFileDetailLink user={userFile.user} userFile={userFile}>
          {children}
        </UserFileDetailLink>
      ) : (
        children
      )}
    </li>
  )
}

const FileCollaborators = ({file, linkToDetail}) => {
  const { user_files: { edges: userFiles } } = file

  return (
    <ul className={classes.collaborators}>
      {userFiles.length === 0 ? (
        <Collaborator>
          File Deleted
        </Collaborator>
      ) : (
        userFiles.map(({node: userFile}, index, edges) => (
          <Collaborator
            key={userFile.id}
            linkToDetail={linkToDetail}
            userFile={userFile}
          >
            {nameWithComma(userFile.user, index, edges.length)}
          </Collaborator>
        ))
      )}
    </ul>
  )
}

FileCollaborators.propTypes = {
  file: PropTypes.object.isRequired
}

export default FileCollaborators
