import { graphql } from 'react-apollo'

// GraphQL query specific to this component
import query from './query.graphql'

/**
HOC to bind a GraphQL query and handlers to a wrapped component.
*/
const withApolloQuery = graphql(query)

export default withApolloQuery
