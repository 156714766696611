import React from 'react'
import { ServerError, Loading } from 'components/shared'
import RoutesContainer from 'containers/RoutesContainer'
import ErrorBoundary from 'containers/ErrorBoundary'

const App = ({success, error}) => {
  if (error) {
    return <ServerError error={error} />
  } else if (success) {
    return (
      <ErrorBoundary>
        <RoutesContainer />
      </ErrorBoundary>
    )
  } else {
    return <Loading />
  }
}

export default App
