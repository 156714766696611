import React from 'react'
import { compose } from 'redux'

// moderation
import { MODERATION_STATUS_REQUIRED } from 'lib/moderation/status'

// mutations
import withModerationStatusMutation from 'mutations/withModerationStatusMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import AdminDeescalateConfirm from 'components/FileActionConfirm/AdminDeescalateConfirm'

const withAdminDeescalateAction = WrappedComponent => {
  const AdminDeescalateAction = props => {
    const {
      file,
      activeAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submitModerationStatus
    } = props

    const handleClickDeescalate = () => {
      const input = {
        moderation_status: MODERATION_STATUS_REQUIRED
      }

      submitModerationStatus(file.id, input)
        .then(onSubmitSuccess)
        .catch(error =>
          onSubmitFailure(error, 'Error updating moderation status')
        )
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'deescalate'}
        onClickDeescalate={handleClickDeescalate}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminDeescalateAction
}

export default compose(
  withModerationStatusMutation,
  withActionResult,
  withAdminDeescalateAction
)(AdminDeescalateConfirm)
