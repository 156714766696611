exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---showcaseStatusPanel---2HIP6 {\n  clear: both;\n  margin: 20px 0 20px 0;\n  padding: 10px;\n  position: relative;\n  z-index: 1; }\n  @media (min-width: 1024px) {\n    .classes---showcaseStatusPanel---2HIP6 {\n      padding-top: 20px;\n      margin: 20px; } }\n\n.classes---showcasePanelHead---1yL2- {\n  font-size: 24px;\n  line-height: 1.5;\n  font-weight: 700;\n  border-bottom: 1px solid #ffffff;\n  margin-bottom: 1rem; }\n  @media screen and (min-width: 768px) {\n    .classes---showcasePanelHead---1yL2- {\n      font-size: 32px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1280px) {\n    .classes---showcasePanelHead---1yL2- {\n      font-size: 32px;\n      line-height: 1.5; } }\n  @media screen and (min-width: 1600px) {\n    .classes---showcasePanelHead---1yL2- {\n      font-size: 36px;\n      line-height: 1.5; } }\n  @media (min-width: 1024px) {\n    .classes---showcasePanelHead---1yL2- {\n      padding-bottom: 20px; } }\n\n@media (min-width: 1024px) {\n  .classes---showcaseFileActions---IfVfq {\n    position: absolute;\n    top: 10px;\n    right: 10px; } }\n\n.classes---statusMessage---35Aj4 {\n  text-align: center; }\n\n.classes---queueLink---119kw {\n  text-align: center;\n  font-weight: 100;\n  font-style: italic; }\n", ""]);

// exports
exports.locals = {
	"showcaseStatusPanel": "classes---showcaseStatusPanel---2HIP6",
	"showcasePanelHead": "classes---showcasePanelHead---1yL2-",
	"showcaseFileActions": "classes---showcaseFileActions---IfVfq",
	"statusMessage": "classes---statusMessage---35Aj4",
	"queueLink": "classes---queueLink---119kw"
};