import React from 'react'

const withActiveFileAction = (WrappedComponent) => {
  class ActiveFileAction extends React.Component {
    state = {
      activeAction: null
    }

    setActiveAction = (activeAction) => {
      this.setState({activeAction})
    }

    render () {
      const { activeAction } = this.state
      return (
        <WrappedComponent
          {...this.props}
          activeAction={activeAction}
          setActiveAction={this.setActiveAction}
        />
      )
    }
  }

  return ActiveFileAction
}

export default withActiveFileAction
