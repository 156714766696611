import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const AdminRecommendConfirm = (props) => {
  const {
    visible,
    onClickRecommend,
    onClickRecommendApprove,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            header='You are recommending an unreviewed film.'
            subheader="Please don't showcase films that haven't been watched by staff."
          >
            <ConfirmationButton
              type='positive'
              text='Recommend and mark as approved'
              action={onClickRecommendApprove}
            />
            <ConfirmationButton
              type='destructive'
              text='Recommend Anyway'
              action={onClickRecommend}
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

AdminRecommendConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClickRecommend: PropTypes.func.isRequired,
  onClickRecommendApprove: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminRecommendConfirm
