import { connect } from 'react-redux'

// redux actions
import { setMessage, setUser } from 'actions'

const mapStateToProps = ({ campLocations, appConfig: { mancalaUrl } }) => ({
  mancalaUrl,
  campLocations
})

const actions = { setMessage, setUser }

const withRedux = connect(mapStateToProps, actions)

export default withRedux
