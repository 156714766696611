import React from 'react'

// components
import LegacyLayout from 'components/LegacyLayout'
import StudioMast from 'components/StudioMast'
import SkillPreviewList from 'components/SkillPreviewList'
import SkillGroupList from 'components/SkillGroupList'
import SkillMediaWrapper from './SkillMediaWrapper'
import LinkToRoute from 'components/LinkToRoute'

const SkillGroupDetail = props => {
  const {
    skill,
    studio,
    skillGroup,
    skills,
    addOnSkillGroups,
    backToRoute,
    changeSelectedSkill,
    setSelectedSkillNode,
    setPreviewListNode
  } = props

  return (
    <LegacyLayout>
      <StudioMast
        head={skillGroup.title}
        headSet={skillGroup.headSet}
        studioId={studio.id}
        linkTo={LinkToRoute}
        route={backToRoute}
      />
      <SkillMediaWrapper
        autoplay
        skill={skill}
        changeSelectedSkill={changeSelectedSkill}
        skills={skills}
      />
      <SkillPreviewList
        selectedSkill={skill}
        changeSelectedSkill={changeSelectedSkill}
        setPreviewListNode={setPreviewListNode}
        setSelectedSkillNode={setSelectedSkillNode}
        skillGroup={skillGroup}
      />
      {addOnSkillGroups && !!addOnSkillGroups.length && (
        <SkillGroupList
          title='Related'
          studioId={studio.id}
          skillGroups={addOnSkillGroups}
        />
      )}
    </LegacyLayout>
  )
}

export default SkillGroupDetail
