import React from 'react'
import PropTypes from 'prop-types'

// components
import KioskStudioSetup from './KioskStudioSetup'
import PreseasonStudioSetup from './PreseasonStudioSetup'
import { ServerError } from 'components/shared'

const StudioSetup = (props) => {
  const { isKiosk, isPreseason } = props

  if (isKiosk) {
    return <KioskStudioSetup {...props} />
  } else if (isPreseason) {
    return <PreseasonStudioSetup {...props} />
  } else {
    const error = new Error('Setup is only supported in kiosk and preseason mode')
    return <ServerError error={error} />
  }
}

StudioSetup.propTypes = {
  isKiosk: PropTypes.bool.isRequired,
  isPreseason: PropTypes.bool.isRequired
}

export default StudioSetup
