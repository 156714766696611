import React from 'react'

// presentational components
import { NotFound } from 'components/shared'

// private helper
const kits = {
  // Note different urls are being used for metrics
  diygames: {
    skillGroupId: 'preseason_diy',
    requestKit: true
  },
  maker: {
    skillGroupId: 'preseason_diy',
    requestKit: true
  },
  diy: {
    skillGroupId: 'preseason_diy',
    requestKit: true
  },
  fashion: {
    skillGroupId: 'fashion_preseason_zipper_pouch',
    requestKit: false
  },
  pouch: {
    skillGroupId: 'fashion_preseason_zipper_pouch',
    requestKit: false
  },
  notebook: {
    skillGroupId: 'fashion_preseason_notebook',
    requestKit: false
  },
  pin: {
    skillGroupId: 'fashion_preseason_pin',
    requestKit: false
  },
  bread: {
    skillGroupId: 'bread_preseason',
    requestKit: false
  }
}
// private helper
const getSkill = (kitId, match) => {
  if (kitId) {
    return kits[kitId] || {}
  } else {
    return {
      studioId: match.params.studioId,
      skillGroupId: match.params.skillGroupId
    }
  }
}

/**
HOC which parses raw router props and passes relevant params on to wrapped
component
*/
const withRouterData = (WrappedComponent) => {
  return (props) => {
    const { match } = props
    const { kitId, skillId } = match.params

    const { studioId, skillGroupId } = getSkill(kitId, match)

    if (!skillGroupId) {
      return <NotFound account={null} />
    }

    const { requestKit } = kits[kitId] || {}

    return <WrappedComponent
      {...props}
      kitId={kitId}
      studioId={studioId}
      skillGroupId={skillGroupId}
      skillId={skillId}
      requestKit={requestKit}
    />
  }
}

export default withRouterData
