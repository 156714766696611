import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// containers
import withShowcaseFlagsMutation from 'mutations/withShowcaseFlagsMutation'

// components
import ActionButton from 'components/FileActionButtons/ActionButton'
import { IconThumbsUp } from 'components/shared/icons'

// moderation
import { isApproved } from 'lib/moderation/status'

// redux actions
import { setMessage } from 'actions'

const withAdminRecommendButtonAction = (WrappedComponent) => {
  const AdminRecommendButtonAction = (props) => {
    const {
      file,
      activeAction,
      setActiveAction,
      setMessage,
      submitShowcaseFlags
    } = props

    const handleClick = (e) => {
      e.preventDefault()
      if (isApproved(file) || file.starred) {
        // allow immediate toggle if file is approved or already recommended
        toggleRecommend()
      } else {
        // open confirmation dialog if file is not approved
        setActiveAction('recommend')
      }
    }

    const handleSubmitFailure = (error) => {
      setMessage({
        header: 'Error updating recommended status',
        body: error.message,
        type: 'FAILURE'
      })
    }

    const toggleRecommend = () => {
      const input = {
        id: file.id,
        starred: !file.starred
      }

      submitShowcaseFlags(input)
        .catch(handleSubmitFailure)
    }

    const active = (file.starred || activeAction === 'recommend')
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        label={file.starred ? 'Recommended' : 'Recommend'}
        active={active}
        disabled={disabled}
        icon={IconThumbsUp}
        onClick={handleClick}
      />
    )
  }

  return AdminRecommendButtonAction
}

const mapStateToProps = () => ({})

const withRedux = connect(mapStateToProps, { setMessage })

export default compose(
  withRedux,
  withShowcaseFlagsMutation,
  withAdminRecommendButtonAction
)(ActionButton)
