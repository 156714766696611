import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

// lib
import withMetric from 'containers/withMetric'

// containers
import withActiveFileAction from 'containers/FileActionButtons/withActiveFileAction'

// components
import StudioFileDetail from 'components/StudioFileDetail'

// redux
import { selectUserFile, setMessage } from 'actions'

export const withLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      // from parent component
      user: PropTypes.object.isRequired,
      userFile: PropTypes.object.isRequired,
      // from Redux state
      isUnoNative: PropTypes.bool.isRequired,
      // from Redux actions
      setMessage: PropTypes.func.isRequired
    }

    state = {
      mediaDuration: 0,
      showcaseReady: false
    }

    _getMediaDuration = (userFile) => {
      const { file } = userFile
      const media = document.getElementsByTagName('video')[0] || document.getElementsByTagName('audio')[0]
      if (media) {
        media.onloadedmetadata = () => {
          if (isNaN(media.duration)) {
            this.props.setMessage({ body: 'This file is unable to play or be submitted to showcase', header: 'Error with file', type: 'FAILURE' })
          } else {
            // no longer than 2 min, 30 sec for video, always true for music studio because no way to download and shorten
            const showcaseReady = file.studio === 'music' ? true : media.duration < 150
            this.setState({ mediaDuration: media.duration, showcaseReady: showcaseReady })
          }
        }
      }
    }

    componentDidMount () {
      window.scrollTo(0, 0)
      this._getMediaDuration(this.props.userFile)
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if (!this.props.userFile || !nextProps.userFile) {
        return
      }
      if (this.props.userFile.file.id !== nextProps.userFile.file.id) {
        this.props.setActiveAction(null)
        this._getMediaDuration(nextProps.userFile)
      }
    }

    /**
    Remove the selected file redux store so studio content will render
    */
    onClose = (e) => {
      e.preventDefault()
      this.props.selectUserFile(null)
    }

    render () {
      const { userFile } = this.props

      if (!userFile) { return null }
      const { file } = userFile
      const { mediaDuration, showcaseReady } = this.state

      return (
        <WrappedComponent
          {...this.props}
          // data
          file={file}
          mediaDuration={mediaDuration}
          showcaseReady={showcaseReady}
          // actions
          onClose={this.onClose}
        />
      )
    }
  }
}

const mapStateToProps = ({appSettings: { isUnoNative }}) => (
  { isUnoNative }
)

const withReduxData = connect(mapStateToProps, { selectUserFile, setMessage })

const metricData = (props) => {
  const {
    userFile: {
      id: userFileId,
      file: {
        id: fileId
      }
    }
  } = props

  return { userFileId, fileId }
}

export default compose(
  withReduxData,
  withMetric('studioFileView', metricData),
  withActiveFileAction,
  withLogic
)(StudioFileDetail)
