import React from 'react'
import PropTypes from 'prop-types'

import classes from './classes.scss'

// components
import LegacyLayout from 'components/LegacyLayout'
import PrivacyPolicy from 'components/shared/PrivacyPolicy'
import FilesEmpty from 'components/FilesEmpty'
import AccountUserPreview from 'components/AccountUserPreview'
import AccountUsersHeader from 'components/AccountUsersHeader'

const AccountUsers = ({ account, isAdmin }) => {
  if (!account) {
    return null
  }

  const { edges: users } = account.account_users

  return (
    <LegacyLayout>
      <AccountUsersHeader account={account} isAdmin={isAdmin} />

      {users.length === 0 ? (
        <FilesEmpty
          message={`${account.first_name} doesn't have any campers.`}
        />
      ) : (
        <div className={classes.camperList}>
          {users.map(({ node: { user } }) => (
            <AccountUserPreview key={user.id} user={user} />
          ))}
        </div>
      )}
      <PrivacyPolicy />
    </LegacyLayout>
  )
}

AccountUsers.propTypes = {
  account: PropTypes.object.isRequired, // from Apollo
  isAdmin: PropTypes.bool.isRequired
}

export default AccountUsers
