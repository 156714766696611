import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// mutations
import withMutation from 'mutations/withSubmitShowcaseFileMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import StudioShowcaseSubmitConfirm from 'components/FileActionConfirm/StudioShowcaseSubmitConfirm'

const withStudioShowcaseSubmitAction = (WrappedComponent) => {
  const ShowcaseSubmitAction = (props) => {
    const {
      file,
      activeAction,
      campLocationId,
      mediaDuration,
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submit // from withMutation
    } = props

    const handleClickSubmit = () => {
      const metadata = { duration: mediaDuration }

      const input = {
        metadata,
        location: campLocationId,
        id: file.id,
        submitted: true,
        clientMutationId: '0'
      }

      submit(input)
        .then(onSubmitSuccess)
        .catch(error => onSubmitFailure(error, 'Error submitting to showcase'))
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'submit'}
        onClickSubmit={handleClickSubmit}
        onClickCancel={onClickCancel}
      />
    )
  }

  return ShowcaseSubmitAction
}

const mapStateToProps = (state) => {
  const { campLocationId, campStudioId } = state.appSettings
  return { campLocationId, campStudioId }
}

const withRedux = connect(mapStateToProps)

export default compose(
  withMutation,
  withRedux,
  withActionResult,
  withStudioShowcaseSubmitAction
)(StudioShowcaseSubmitConfirm)
