import React from 'react'
import Select from './Select'

const APP_MODES = [
  { value: 'web', title: 'Web Browser' },
  { value: 'preseason', title: 'Event (preseason)' },
  { value: 'kiosk', title: 'Camp (Kiosk)' },
  { value: 'studio', title: 'Camp (Studio)' }
]

const AppModeSelect = ({appMode, onChange}) => (
  <Select value={appMode} onChange={onChange}>
    {APP_MODES.map(mode => (
      <option key={mode.value} value={mode.value}>
        { mode.title }
      </option>
    ))}
  </Select>
)

export default AppModeSelect
