import React from 'react'
import PropTypes from 'prop-types'

const withSetupLogic = (WrappedComponent) => {
  return class extends React.Component {
    static propTypes = {
      // redux actions
      setCampStudio: PropTypes.func.isRequired,
      setCampLocation: PropTypes.func.isRequired,
      // redux store
      locations: PropTypes.array.isRequired,
      isKiosk: PropTypes.bool.isRequired,
      campLocationId: PropTypes.string,
      userId: PropTypes.string,
      // router
      history: PropTypes.object.isRequired
    }

    state = {
      selectedLocation: null,
      selectedStudioId: null,
      showHelp: false,
      showEmailPrompt: false,
      showVideo: true
    }

    handleStudioChange = (value) => {
      this.setState({selectedStudioId: value})
    }

    handleLocationChange = (value) => {
      const { locations } = this.props
      const selectedLocation = locations.find(location => location.id === value)
      this.setState({selectedLocation})
    }

    // PreseasonStudioSetup only
    handleVideoEnd = (e) => {
      this.setState({showVideo: false})
    }

    // PreseasonStudioSetup only
    handleEmailClose = () => {
      this.setState({showVideo: true})
    }

    _showHelp = () => {
      this.setState({showHelp: true})
    }

    // save selected studio and location and do redirect
    _saveSelection = (selectedStudioId, selectedLocation) => {
      const { history } = this.props
      this.setState({showHelp: false})

      // redirect
      history.push(`/studios/${selectedStudioId}`)
    }

    handleSubmit = () => {
      const { isKiosk, isPreseason, history, userId } = this.props
      const { selectedStudioId, selectedLocation } = this.state

      if (isKiosk) {
        if (selectedStudioId) {
          if (userId) {
            history.push(`/studios/${selectedStudioId}/campers/${userId}`)
          } else {
            history.push(`/studios/${selectedStudioId}`)
          }
        } else {
          this._showHelp()
        }
        return
      }

      if (isPreseason) {
        if (selectedStudioId) {
          // get first studio page
          const { studios } = this.props
          const studio = studios.find(studio => studio.id === selectedStudioId)
          const studioPage = studio.studioPages[0]

          if (userId) {
            history.push(`/studios/${selectedStudioId}/${studioPage.id}/campers/${userId}`)
          } else {
            history.push(`/studios/${selectedStudioId}/${studioPage.id}`)
          }
        } else {
          this._showHelp()
        }
        return
      }

      if (selectedStudioId && selectedLocation) {
        this._saveSelection(selectedStudioId, selectedLocation)
      } else {
        this._showHelp()
      }
    }

    render () {
      const { studios, campLocationId, locations } = this.props
      const { selectedLocation, selectedStudioId, showHelp, showVideo } = this.state
      const campLocation = locations.find(location => location.id === campLocationId)
      const campLocationName = campLocation ? campLocation.name : '...'

      return <WrappedComponent
        {...this.props}
        campLocation={campLocation}
        campLocationName={campLocationName}
        showHelp={showHelp}
        showVideo={showVideo}
        onLocationChange={this.handleLocationChange}
        onStudioChange={this.handleStudioChange}
        onSubmit={this.handleSubmit}
        onVideoEnd={this.handleVideoEnd}
        onEmailClose={this.handleEmailClose}
        selectedLocation={selectedLocation}
        selectedStudioId={selectedStudioId}
        studios={studios}
      />
    }
  }
}

export default withSetupLogic
