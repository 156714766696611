import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// higher-order components
import withVideoJs from './withVideoJs'

// presentational component
import VideoPlayer from 'components/VideoPlayer'

const withSkillVideoPlayer = (WrappedComponent) => {
  /**
  Given a Skill, pass relevant props to the `VideoJsPlayer` component
  */
  const SkillVideoPlayer = (props) => {
    const { skill, autoplay, onPlay } = props
    const { fullURL: url, url: transcodedUrl, thumbnailLarge: poster } = skill

    return (
      <WrappedComponent
        url={url}
        transcodedUrl={transcodedUrl}
        poster={poster}
        autoplay={autoplay}
        loop
        onPlay={onPlay}
      />
    )
  }

  SkillVideoPlayer.propTypes = {
    skill: PropTypes.object.isRequired,
    autoplay: PropTypes.bool.isRequired,
    onPlay: PropTypes.func.isRequired
  }

  return SkillVideoPlayer
}

export default compose(
  withSkillVideoPlayer,
  withVideoJs
)(VideoPlayer)
