import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/shared/Modal'

const withClickOutsideModalLogic = (WrappedComponent) => {
  class WithClickOutsideModalLogic extends React.Component {
    constructor(props) {
      super(props)

      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
      this.wrapperRef = node
    }

    handleClickOutside(event) {
      const { onClickOutside } = this.props
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        if (onClickOutside) {
          onClickOutside()
        }
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          setWrapperRef={this.setWrapperRef}
        />
      )
    }
  }

  WithClickOutsideModalLogic.propTypes = {
    onClickOutside: PropTypes.func,
  }

  return WithClickOutsideModalLogic
}


export default withClickOutsideModalLogic(Modal)
