import React from 'react'
import PropTypes from 'prop-types'
import classes from './classes.scss'

const OutreachInfo = (props) => {
  const { kitId } = props

  const getImageSrc = () => {
    switch (kitId) {
      case 'diygames':
      case 'maker':
      case 'diy':
        return '/assets/uno/images/maker-kit.jpg'
      case 'pouch':
      case 'notebook':
      case 'pin':
        return '/assets/uno/images/taste-of-fashion.jpg'
      default:
        return `/assets/uno/images/taste-of-${kitId}.jpg`
    }
  }

  return (
    <section className={classes.OutreachInfo}>
      <h1 className={classes.OutreachInfo__title}>Activities for every interest</h1>
      <img className={classes.OutreachInfo__image} src={getImageSrc()} />
    </section>
  )
}

OutreachInfo.propTypes = {
  kitId: PropTypes.string.isRequired
}

export default OutreachInfo
