exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---mediaThumbnail---1P_J9 {\n  max-width: 100%;\n  width: auto;\n  height: auto;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto; }\n  .classes---mediaThumbnail---1P_J9.classes---appThumbnail---39rTh {\n    max-width: 140px; }\n", ""]);

// exports
exports.locals = {
	"mediaThumbnail": "classes---mediaThumbnail---1P_J9",
	"appThumbnail": "classes---appThumbnail---39rTh"
};