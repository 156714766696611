import React from 'react'

import {
  MODERATION_STATUS_REQUIRED,
  MODERATION_STATUS_PENDING,
  MODERATION_STATUS_ESCALATED,
  MODERATION_STATUS_APPROVED,
  MODERATION_STATUS_FLAGGED,
  MODERATION_STATUS_ERROR
} from 'lib/moderation/status'

/**
Given the moderation status of a file, return the text to display on the moderation badge,
as well as a semantically generalized status for styling/display purposes
*/
const getStatusDisplay = (status) => {
  switch (status) {
    case MODERATION_STATUS_ERROR:
    case MODERATION_STATUS_REQUIRED:
    case MODERATION_STATUS_PENDING:
      return {
        text: 'Pending Review',
        status: MODERATION_STATUS_PENDING
      }
    case MODERATION_STATUS_ESCALATED:
    case MODERATION_STATUS_FLAGGED: // this should not happen!
      return {
        text: 'Escalated: Alert SD',
        status: MODERATION_STATUS_ESCALATED
      }
    case MODERATION_STATUS_APPROVED:
      return {
        text: 'Approved',
        status: MODERATION_STATUS_APPROVED
      }
    default:
      return {
        text: null,
        status: null
      }
  }
}

const withModerationStatusDetails = (WrappedComponent) => {
  const FileModerationStatusDetails = (props) => {
    const {status} = props
    const {text, status: generalizedStatus} = getStatusDisplay(status)

    return (
      <WrappedComponent
        {...props}
        text={text}
        status={generalizedStatus}
      />
    )
  }

  return FileModerationStatusDetails
}

export default withModerationStatusDetails
