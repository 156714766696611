import React from 'react'
import classes from './classes.scss'

const AdminModerationFooter = props => {
  return (
    <div className={classes.adminModerationFooter}>
      <h3 className={classes.footerHead}>About Project and Photo Reviews</h3>
      <p className='caption'>
        We do cross-location review of all film and photo projects submitted to
        the Pool. Individual locations can always review files on site,
        regardless of status.
      </p>
      <p className='caption'>
        <a href='https://sites.google.com/steveandkate.com/staff-library/policies/content-moderation'>
          Go to the Library to learn more about moderation »
        </a>
      </p>
    </div>
  )
}

export default AdminModerationFooter
