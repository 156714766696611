import React from 'react'
import PropTypes from 'prop-types'
import UserHeader from 'components/UserHeader'
import LinkToUser from 'components/LinkToUser'
import UserProfileImage from 'components/UserProfileImage'

/**
 * Displays a user profile header for a file index view, in the context of a
 * logged-in account (parent or admin). Profile image links to the file index
 * for this user.
 */
const AccountUserHeader = ({user, subheader}) => {
  return (
    <UserHeader user={user} subheader={subheader}>
      <LinkToUser user={user}>
        <UserProfileImage user={user} />
      </LinkToUser>
    </UserHeader>
  )
}

AccountUserHeader.propTypes = {
  user: PropTypes.object.isRequired,
  subheader: PropTypes.string.isRequired
}

export default AccountUserHeader
