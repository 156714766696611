import React from 'react'

// components
import DownloadButton from 'components/FileActionButtons/DownloadButton'

const withAdminShowcaseDownloadButtonAction = (WrappedComponent) => {
  const AdminShowcaseDownloadButtonAction = (props) => {
    const {
      activeAction,
      setActiveAction
    } = props

    const handleClick = (e) => {
      e.preventDefault()
      setActiveAction('showcaseDownload')
    }

    const active = activeAction === 'showcaseDownload'
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        // label='Download'
        active={active}
        disabled={disabled}
        onClick={handleClick}
      />
    )
  }

  return AdminShowcaseDownloadButtonAction
}

export default withAdminShowcaseDownloadButtonAction(DownloadButton)
