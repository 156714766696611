import React from 'react'

// components
import ActionButton from 'components/FileActionButtons/ActionButton'
import { IconMore } from 'components/shared/icons'

const withAdminMoreActionsButtonAction = (WrappedComponent) => {
  const AdminMoreActionsButtonAction = (props) => {
    const {
      activeAction,
      setActiveAction
    } = props

    const handleClick = (e) => {
      e.preventDefault()
      if (activeAction === 'more') {
        setActiveAction(null)
      } else {
        setActiveAction('more')
      }
    }

    const active = activeAction === 'more'
    const disabled = !!(activeAction && !active)

    return (
      <WrappedComponent
        {...props}
        label='Review'
        active={active}
        disabled={disabled}
        icon={IconMore}
        onClick={handleClick}
      />
    )
  }

  return AdminMoreActionsButtonAction
}

export default withAdminMoreActionsButtonAction(ActionButton)
