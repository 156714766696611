import React from 'react'
import Input from 'muicss/lib/react/input'
import Checkbox from 'muicss/lib/react/checkbox'
import OutreachPromptWrapper from 'components/OutreachPromptWrapper'

import classes from './classes.scss'

// components
import StudioLocationsList from 'components/StudioLocationsList'

const OutreachEmailPrompt = (props) => {
  const {
    // presentation
    headerText, // 'Make a light-up pin.'
    subheaderText, // 'Enter an email to get started.'
    buttonText, // 'Make a light-up pin.'
    // data
    locations,
    selectedLocation,
    // state
    email, // 'user@example.com'
    updates, // true
    showUpdates, // true
    showLogin, // true
    // actions
    onChangeEmail,
    onChangeUpdates,
    onChangeLocation,
    onClickLogin,
    onSubmit
  } = props

  const inputWrapperClass = 'mui-textfield'
  const summerLocations = locations.filter(location => location.session.break_type === 'summer')

  return (
    <OutreachPromptWrapper
      headerText={headerText}
      subheaderText={subheaderText}
      buttonText={buttonText}
      showLogin={showLogin}
      onClickLogin={onClickLogin}
      onSubmit={onSubmit}
    >
      <legend>Keep me in the loop</legend>
      <div className={inputWrapperClass}>
        <Input
          required={updates}
          label='Email:'
          name='email'
          type='email'
          placeholder='example@gmail.com'
          value={email}
          onChange={onChangeEmail}
        />
      </div>
      <StudioLocationsList
        label='Preferred location'
        required={updates}
        locations={summerLocations}
        onChange={onChangeLocation}
        selectedLocation={selectedLocation}
      />
      {showUpdates && (
        <div>
          <p className={classes.OutreachEmail__auxLabel}>Want to hear about more free events? We promise not to flood your inbox.</p>
          <Checkbox
            label='Keep me in the loop'
            name='updates'
            checked={updates}
            onChange={onChangeUpdates}
          />
        </div>
      )}
    </OutreachPromptWrapper>
  )
}

export default OutreachEmailPrompt
