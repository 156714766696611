exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".classes---camperList---1j2Kz {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 40px; }\n  @media (min-width: 568px) {\n    .classes---camperList---1j2Kz {\n      max-width: 75%; } }\n", ""]);

// exports
exports.locals = {
	"camperList": "classes---camperList---1j2Kz"
};