import React from 'react'
import PropTypes from 'prop-types'

// components
import {
  Confirmation,
  ConfirmationButton
} from 'components/Confirmation'

import {
  TransitionGroup,
  MaxHeightTransition
} from 'components/shared'

const AdminDequeueConfirm = (props) => {
  const {
    visible,
    isDropList,
    onClickDequeue,
    onClickCancel
  } = props

  return (
    <TransitionGroup>
      {visible &&
        <MaxHeightTransition>
          <Confirmation
            visible
            isDropList={isDropList}
            header='Are you sure you want to remove from showcase queue? This action cannot be undone.'
            subheader='Film will still be accessible from camper account.'
          >
            <ConfirmationButton
              type='destructive'
              text='Remove from Queue'
              action={onClickDequeue}
            />
            <ConfirmationButton
              text='Cancel'
              action={onClickCancel}
            />
          </Confirmation>
        </MaxHeightTransition>
      }
    </TransitionGroup>
  )
}

AdminDequeueConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  isDropList: PropTypes.bool,
  onClickDequeue: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
}

export default AdminDequeueConfirm
