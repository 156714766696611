import React from 'react'
import { compose } from 'redux'

// moderation
import { MODERATION_STATUS_APPROVED } from 'lib/moderation/status'

// mutations
import withModerationStatusMutation from 'mutations/withModerationStatusMutation'
import withShowcaseFlagsMutation from 'mutations/withShowcaseFlagsMutation'

// result handling
import withActionResult from './withActionResult'

// presentational component
import AdminRecommendConfirm from 'components/FileActionConfirm/AdminRecommendConfirm'

const withAdminRecommendAction = (WrappedComponent) => {
  const AdminRecommendAction = (props) => {
    const {
      file,
      activeAction, // from withActiveFileAction
      onClickCancel, // from withActionResult
      onSubmitSuccess, // from withActionResult
      onSubmitFailure, // from withActionResult
      submitModerationStatus, // from withModerationStatusMutation
      submitShowcaseFlags // from withShowcaseFlagsMutation
    } = props

    const handleClickRecommend = () => {
      const showcaseInput = {
        id: file.id,
        starred: true
      }

      submitShowcaseFlags(showcaseInput)
        .then(onSubmitSuccess)
        .catch(e => onSubmitFailure(e, 'Error recommending file'))
    }

    const handleClickRecommendApprove = () => {
      const moderationInput = {
        moderation_status: MODERATION_STATUS_APPROVED
      }

      const showcaseInput = {
        id: file.id,
        starred: true
      }

      submitModerationStatus(file.id, moderationInput)
        .then(() => submitShowcaseFlags(showcaseInput))
        .then(onSubmitSuccess)
        .catch(error => onSubmitFailure(error, 'Error recommending/approving file'))
    }

    return (
      <WrappedComponent
        {...props}
        visible={activeAction === 'recommend'}
        onClickRecommend={handleClickRecommend}
        onClickRecommendApprove={handleClickRecommendApprove}
        onClickCancel={onClickCancel}
      />
    )
  }

  return AdminRecommendAction
}

export default compose(
  withModerationStatusMutation,
  withShowcaseFlagsMutation,
  withActionResult,
  withAdminRecommendAction
)(AdminRecommendConfirm)
