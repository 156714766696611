import { graphql } from 'react-apollo'
import query from './mutation.graphql'

const withClearCacheMutation = graphql(query, {
  props: ({ mutate }) => ({
    clearCache: (id) => mutate({
      variables: { id }
    })
  })
})

export default withClearCacheMutation
