import React from 'react'
import PropTypes from 'prop-types'
import SkillGroupList from 'components/SkillGroupList'

const SkillGroupCategory = ({ title, skillGroups, studioId }) => {
  return (
    <SkillGroupList
      title={title}
      skillGroups={skillGroups}
      studioId={studioId}
    />
  )
}

SkillGroupCategory.propTypes = {
  title: PropTypes.string.isRequired,
  skillGroups: PropTypes.array.isRequired,
  studioId: PropTypes.string.isRequired
}

export default SkillGroupCategory
