import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

// higher-order components
import withVideoJs from './withVideoJs'

// presentational component
import VideoPlayer from 'components/VideoPlayer'

/**
@private
Return a promo video to be shown at a camp location based on its time zone.
@param {object} campLocation - a location object which includes a time_zone property
@returns {object} a configuration object with URLs for the appropriate video
*/
const chooseVideo = (campLocation) => {
  const baseUrl = 'https://skc-mancala.s3.amazonaws.com/production'
  const videos = {
    eastCoast: {
      url: `${baseUrl}/resources/preseason/east_coast.mp4`,
      transcodedUrl: `${baseUrl}/resources-hls/preseason/east_coast/east_coast_index.m3u8`,
      poster: `${baseUrl}/resources/preseason/thumbs/east_coast_thumb.jpg`
    },
    westCoast: {
      url: `${baseUrl}/resources/preseason/west_coast.mp4`,
      transcodedUrl: `${baseUrl}/resources-hls/preseason/west_coast/west_coast_index.m3u8`,
      poster: `${baseUrl}/resources/preseason/thumbs/west_coast_thumb.jpg`
    }
  }

  return campLocation.time_zone === 'Eastern Time (US & Canada)'
    ? videos.eastCoast
    : videos.westCoast
}

const withPreseasonVideoPlayer = (WrappedComponent) => {
  /**
  Given a camp location, pass relevant props to the `VideoJsPlayer` component
  */
  const PreseasonVideoPlayer = (props) => {
    const { campLocation, onEnd } = props

    const {
      url,
      transcodedUrl,
      poster
    } = chooseVideo(campLocation)

    return (
      <WrappedComponent
        url={url}
        transcodedUrl={transcodedUrl}
        poster={poster}
        autoplay={false}
        loop={false}
        onEnd={onEnd}
      />
    )
  }

  PreseasonVideoPlayer.propTypes = {
    campLocation: PropTypes.object.isRequired,
    onEnd: PropTypes.func.isRequired
  }

  return PreseasonVideoPlayer
}

export default compose(
  withPreseasonVideoPlayer,
  withVideoJs
)(VideoPlayer)
